import { response_get_mock, response_mock_po_detail } from "./responses_mock";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import {
  faSearch,
  faInfoCircle,
  faTrash,
  faExclamationTriangle,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import ModalPoData from "./poDataModal";
import { PoPdf, ViewPoPdf } from "./poPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ModalEditPoQuantities  from "./ModalEditPoQuantities"
import Select from "react-select";
import {
  styles_react_select,
} from "../../../App/components/shared/Select";


function SupplierOrderDetail() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  
  const { poId } = useLocation().state
  const history = useHistory();
  const [sortAscen, setSortAscen] = useState(true);
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [buttonState, setButtonState] = useState("Guardar");
  const [openModalEditPoQuantities, setOpenModalEditPoQuantities] = useState(false);
  const [refToEDit, setRefToEdit] = useState({});
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalPoData, setOpenModalPoData] = useState(false);
  const [optionsToShow, setOptionsToShow] = useState({});
  const [contact, setContact] = useState({});
  const [file, setfile] = useState();
  const [openQuestionModal, setOpenQuestionModal] = useState()
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request()
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    url = `purchase-order/${poId}/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // response = response_mock_po_detail;

    const formattedItems = setInitDefaults(response.items)
    response.items = formattedItems
    setJson_response(response);

    buildOptions(response)
    setIsLoading(false);
  }

  const buildOptions = (response) => {
    let options = {};
    let contacts = []
    
    if (response?.supplier_contacts !== undefined && response?.supplier_contacts.length > 0){
      for (const contact of response?.supplier_contacts) {
        contacts.push({ value: contact.id, label: contact.name, email: contact.email });
      }
    }

    if (response?.purchase_order_info?.contact !== null && response?.purchase_order_info?.contact !== undefined){
      setContact({ value: response?.purchase_order_info?.contact?.id, label: response?.purchase_order_info?.contact?.name, email: response?.purchase_order_info?.contact?.email })
    }

    options.contacts = contacts
    setOptionsToShow(options)
} 

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  const setInitDefaults = (items) => {
    const itemsCopy = [...items];
    itemsCopy.forEach((item) => {
      item.extra_description = item.extra_description || "";
    });

    return itemsCopy;
  }


  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.purchase_orders];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "expected_date" && sub !== "date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });
    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    if (!sortAscen) {
      sorted.reverse();
    }
    setJson_response({ ...json_response, purchase_orders: sorted });
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Set the desired z-index value
    }),
  };


  const savePO = async () =>{
    setIsLoading(true)
    console.log(json_response)
    var response = await fetch_backend(
        `save-po/${json_response.purchase_order_info.id}`, 
        "PUT", 
        token, 
        dispatch,
        {...json_response}
        );

    if (response.status === 200){
        setSuccessModalForm(true);
        setMessageModalForm([
            "Operación Exitosa",
        ]);
        setOpenModalForm(true);
    }else{
        setIsLoading(false)
        return
    }
    if (buttonState==="Generar O.C"){
       setOpenModalPoData(true)
    }
    setIsLoading(false)
  }

  async function buildPoPdf(props_pdf) {
    const doc = <PoPdf dataOp={props_pdf} />;
    const asPdf = pdf(); 
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    setfile(blob);
    saveAs(
      blob,
      `OC ${props_pdf.poId} - ${props_pdf.supplierName.toUpperCase()}.pdf`
    );
  }

  const inputChange = (target, index) => {
    const jsonCopy = { ...json_response };
    jsonCopy.items[index][target.name] = target.value;
    setJson_response(jsonCopy);
  };

  async function onCloseAcceptModalPoData (extraPoData) {
    setOpenModalPoData(false)
    console.log({...json_response, ...extraPoData})
    var response = await fetch_backend(
      `save-po/${json_response.purchase_order_info.id}`, 
      "PUT", 
      token, 
      dispatch,
      {...json_response, ...extraPoData}
      );

  if (response.status != 200){
      return
  }
    const itemsWithQuantity = []
    json_response.items.forEach((item) => {
      if (item.quantity > 0){
      itemsWithQuantity.push({
        ...item,
      })
    }
    })

    buildPoPdf({
          supplierName: json_response?.purchase_order_info.supplier_name,
          governmentId: json_response?.purchase_order_info.government_id,
          phone: json_response?.purchase_order_info.phone,
          email: json_response?.purchase_order_info.email,
          address: json_response?.purchase_order_info.address,
          paymentMethod: extraPoData.payment_method,
          paymentTerm: extraPoData.payment_term,
          autoRetainer: json_response?.purchase_order_info.auto_retainer,
          deliveryAdress: extraPoData.delivery_address,
          deliveryDate: extraPoData.delivery_date,
          buyerName: json_response?.purchase_order_info.buyer_name,
          poId: json_response?.purchase_order_info.id,
          buyerPhone: json_response?.purchase_order_info.buyer_phone,
          items: itemsWithQuantity,
          discount: extraPoData.discount,
          retention: extraPoData.retention,
          observations: extraPoData.observations,
          iva: extraPoData.iva,
    })
}

  const getTomorrowDate = () => {
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return tomorrow.toISOString().split('T')[0]
  }

  const ChangeContact = (value) => {
    setContact(value)
    let json_response_copy = {...json_response}
    json_response_copy.purchase_order_info.contact = {
      id: value.value,
      name: value.label,
      email: value.email
    }
    setJson_response(json_response_copy)
  }

  const onCloseModalEditQuantities = (reload=false) => {
    setOpenModalEditPoQuantities(false)
    if (reload){
      history.go(0)
    }
  }


  return (
    <div className="main_container_orders_detail">
      <Loading isLoading={isLoading} />
      {openModalPoData &&
      <ModalPoData 
        open={openModalPoData}
        onClose={()=>setOpenModalPoData(false)}
        onCloseAccept={onCloseAcceptModalPoData}
        baseExtraPoData={{
          payment_method: json_response?.purchase_order_info?.payment_method,
          payment_term: json_response?.purchase_order_info?.payment_term,
          delivery_date: getTomorrowDate(),
          delivery_address: json_response?.purchase_order_info?.delivery_address,
          name: json_response?.purchase_order_info?.supplier_name,
        }}
      />
    }

    {openModalEditPoQuantities &&
      <ModalEditPoQuantities
        open={openModalEditPoQuantities}
        callback=""
        onClose={onCloseModalEditQuantities}
        params={{reference: refToEDit, poId: poId}}
        />
      }

      <ModalQuestion
        open={openQuestionModal}
        onClose={() => setOpenQuestionModal(false)}
        // question={modalQuestionData?.question}
        // callback={modalQuestionData?.callback}
        // params={modalQuestionData}
      />
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          marginTop: "-20px",
          height: "180px",
        }}
      >
        <h3 style={{ paddingTop: "20px" }}>Detalle O.C</h3>
        <div className="simple_flex_" style={{marginTop: "20px", maxWidth: "80%", justifyContent: "space-between", marginBottom: "40px"}}> 
          <div className="simple_flex_column" style={{flex: 0.4}}>
            <div className="simple_flex_" style={{marginBottom:"15px"}}>
                    <p className="p_bold">
                        Proveedor
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.purchase_order_info?.supplier_name}
                    </p>
                </div>
                <div className="simple_flex_" style={{marginBottom:"15px"}}>
                    <p className="p_bold">
                        O.C
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.purchase_order_info?.id}
                    </p>
                </div>
                <div className="simple_flex_">
                    <p className="p_bold">
                        Fecha O.C
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.purchase_order_info?.date}
                    </p>
            </div>
          </div>
          <div className="simple_flex_column" style={{flex: 0.4}}>
              <div className="simple_flex_" style={{marginBottom:"15px"}}>
                <p className="p_bold">
                    Contacto
                </p>
                <div style={{width: "100%", marginLeft: "10px"}}>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    // isClearable={true}
                    // isLoading={isLoading}
                    options={optionsToShow.contacts}
                    styles={styles_react_select}
                    onChange={(value) =>
                        ChangeContact(value)
                      }
                      value={contact}
                      />
                      </div>
              </div>
              <div className="simple_flex_" style={{marginBottom:"15px"}}>
                  <p className="p_bold">
                      Email
                  </p>
                  <p style={{marginLeft: "10px"}}>
                   {json_response?.purchase_order_info?.contact?.email}
                  </p>
              </div>
              <div className="simple_flex_" style={{marginBottom:"15px"}}>
                  {/* <p className="p_bold">
                      Cotizaciones Asociadas
                  </p>
                  <p style={{marginLeft: "10px"}}>
                  {json_response?.purchase_order_info?.linked_quote_ids.join(", ")}
                  </p> */}
              </div>
              <div className="simple_flex_">
                  
              </div>
          </div>
          <div className="simple_flex_" style={{flex: 0.1}}>
            <div className="input_tag_addclient">
              <div className={`div_button_list ${json_response?.purchase_order_info?.status === "managed" ? 'blocked_button_color': null}`}>
                <div className="button-text" onClick={() =>json_response?.purchase_order_info?.status === "managed" ? savePO() : savePO()}>
                  <p style={{ fontSize: "18px" }}>{buttonState}</p>
                </div>
                <div className="button-arrow">
                  <select
                    onChange={(event) => setButtonState(event.target.value)}
                  >
                    <option value="Guardar">Guardar</option>
                    <option value="Generar O.C">Generar O.C</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <table className="table_tasks">
          <tr className="rowHeaders th_detail_oc">
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Código"
              txt_filter="product_code"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Descripción"
              txt_filter="description"
              width="25%"
            />
            
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Unidad"
              txt_filter="unit"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Cant. A Pedir"
              txt_filter="quantity"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Valor"
              txt_filter="price"
              width="8%"
            />
            <TableHeader col_name="Editar" width="4%" />
          </tr>
          {json_response?.items?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>{row.product_code}</td>
                <td>{row.description}</td>
                <td>{row.unit}</td>
                <td>{row.quantity}</td>
                <td>{row.price.toLocaleString('de-DE')}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color={json_response?.purchase_order_info?.status === "managed" ? "#e6e6e6" : "#383838"}
                    className="iconRow"
                    onClick={() => {
                      setRefToEdit(row)
                      setOpenModalEditPoQuantities(true)
                    }}
                  />
                </td>
              </tr>
              <tr className="rowTasks">
                <td>
                  Descripción Proveedor:
                </td>
                <td colSpan={10}>
                  <div style={{height: "35px"}}>
                    <textarea style={{minHeight: "35px"}}
                    type="text"
                    name="extra_description"
                    value={row.extra_description}
                    onChange={(event) => inputChange(event.target, index)}
                    ></textarea>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
      
    </div>
  );
}

export { SupplierOrderDetail };
