import React, { useState, useEffect } from "react";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  ModalQuestion,
  ModalForm,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import { init_modal_products_mock } from "./responses_mock";
import { FormatDotThousands } from "../../../App/services/services";

// import "./Modals.css";

function ModalProducts({ open, onClose, initValuesModalProductos, isEdit }) {
  const init_form_values = {
    marca: "",
    descripcion_adicional: "",
    minimo: "",
    maximo: "",
    valor: "",
    proveedor: "",
    eliminar_ref: false,
    justificacion: "",
    observaciones: "",
    unit: "Und"
  };

  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [cookies] = useCookies();
  const token = cookies.token_inventario || cookies.token_purchase;
  const [initIsLoading, setInitIsLoading] = useState(false);
  const [info_lists_response, setInfo_lists_response] = useState();
  const [info_tool_response, setInfo_tool_response] = useState();
  const [formValues, setFormValues] = useState({
    ...initValuesModalProductos,
    ...init_form_values,
  });
  const [opeModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [opeDeleteConfirmation, setOpeDeleteConfirmation] = useState(false);
  const [deleteRef, setDeleteRef] = useState(false);
  const [familyOptions, setFamilyOptions] = useState();
  const [subFamilyOptions, setSubFamilyOptions] = useState([]);
  const [variableFieldsOptions, setVariableFieldsOptions] = useState();
  const [selectedOptions, setSelectedOptions] = useState();
  const [customDescription, setCustomDescription] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [isLoading, setIsLoading] = useState();
  const [postResponse, setPostResponse] = useState();
  const [valueToShow, setValueToShow] = useState("");

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderWidth: 1,
      height: "27px",
      minHeight: "27px",
      // maxWidth: "150px",
      borderRadius: "5px",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "13px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "27px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      marginTop: "-2.5px",

      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "27px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: "0px",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),
  };

  useEffect(() => {
    async function send_request_init() {
      setInitIsLoading(true);
      var url;
      if (isEdit) {
        url = `product/add/${initValuesModalProductos.id}/`;
      } else {
        url = "product/add/";
      }
      const response = await fetch_backend(url, "GET", token, dispatch);
      setInfo_lists_response(response.info_lists);
      setInfo_tool_response(response.info_product);
      
      if (isEdit) {
        formatDotsInNumber({target: {name: "valor", value: response.info_product.valor}})
      }

      setInitIsLoading(false);
    }
    send_request_init();
  }, []);

  //initialize variables to fill selected values on 'react-selec' components
  useEffect(() => {
    if (isEdit && info_tool_response) {
      var editOptions = {};
      editOptions.tipo = info_tool_response.tipo;
      editOptions.familia = info_tool_response.familia;
      editOptions.subfamilia = info_tool_response.subfamilia;
      editOptions.campos_variables = info_tool_response.campos_variables;
      setSelectedOptions(editOptions);

      var editFormValues = { ...formValues };
      editFormValues.marca = info_tool_response.marca;
      editFormValues.descripcion_adicional =
        info_tool_response.descripcion_adicional;
      editFormValues.minimo = info_tool_response.minimo;
      editFormValues.maximo = info_tool_response.maximo;
      editFormValues.proveedor = info_tool_response.proveedor;
      editFormValues.valor = info_tool_response.valor;
      editFormValues.observaciones = info_tool_response.observaciones;
      editFormValues.unit = info_tool_response.unit;
      editFormValues.referencia_asignada =
        info_tool_response.referencia_asignada;
      setFormValues(editFormValues);

      var family_options = info_lists_response.familia.options.filter(
        (familia) => familia.tipo.includes(info_tool_response.tipo.value)
      );
      var sub_family_options = info_lists_response.subfamilia.options.filter(
        (subfamilia) =>
          subfamilia.familia.includes(info_tool_response.subfamilia.value)
      );

      setFamilyOptions(family_options);
      setSubFamilyOptions(sub_family_options);
      update_variable_fields(
        info_tool_response.familia.value,
        info_tool_response.subfamilia.value
      );
    }
  }, [info_tool_response]);

  const validate = (allValues, delete_flag = false) => {
    const errors = {};

    if (!delete_flag) {
      if (!allValues.tipo) {
        errors.tipo = 'Falta Selecionar "Tipo"';
      }
      if (!allValues.familia) {
        errors.familia = 'Falta Selecionar "Familia"';
      }
      if (subFamilyOptions.length > 0 && !allValues.subfamilia) {
        errors.subfamilia = 'Falta Selecionar "Subfamilia"';
      }
      
      if (allValues.referencia_asignada && !allValues.proveedor) {
        errors.proveedor = 'Falta Selecionar "Proveedor"';
      }
      if (!allValues.valor) {
        errors.valor = 'Falta Completar "Valor"';
      }

      allValues.campos_variables = allValues.campos_variables || {};

      for (var i = 0; i < variableFieldsOptions?.length; i++) {
        var field_label = variableFieldsOptions[i]?.label;

        if (allValues.campos_variables[field_label] === undefined) {
          errors[field_label] = `Falta Seleccionar "${field_label}"`;
        }
      }
    } else {
      if (allValues.eliminar_ref && !allValues.justificacion) {
        errors.justificacion = 'Falta Completar "Justificación"';
      }
    }

    return errors;
  };

  const save_herramientas = () => {
    const { allValues, errors } = pre_request(false);
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    if (isEdit) {
      send_request("PUT", allValues);
    } else {
      send_request("POST", allValues);
    }
  };

  const delete_herramientas = (value) => {
    const answer = value.value === "true";
    setOpeDeleteConfirmation(false);

    if (answer) {
      const { allValues, errors } = pre_request(true);
      if (!_.isEmpty(errors)) {
        setSuccessModalForm(false);
        setMessageModalForm(errors);
        setOpenModalForm(true);
        return;
      }
      send_request("DELETE", allValues);
    }
  };

  const pre_request = (delete_flag) => {
    // function that puts all information together in a Json ready to go to the backend
    var allValues = { ...formValues, ...selectedOptions };
    allValues.tipo = allValues.tipo?.value || "";
    allValues.familia = allValues.familia?.value || "";
    allValues.subfamilia = allValues.subfamilia?.value || "";
    allValues.marca = allValues.marca?.value || "";
    allValues.proveedor = allValues.proveedor?.value || "";
    allValues.description_personalizada = customDescription;
    return { allValues: allValues, errors: validate(allValues, delete_flag) };
  };

  const send_request = async (method, allValues) => {
    console.log(allValues);
    setIsLoading(true);
    const response = await fetch_backend(
      "product/add/",
      method,
      token,
      dispatch,
      allValues,
      false
    );

    setIsLoading(false);
    if (response.status === 200) {
      if (method === "POST") {
        setPostResponse(response);
        setOpenModalConfirmation(true);
      } else {
        onClose(200, response);
      }
    } else if (response.status === 409) {
      setSuccessModalForm(false);
      setMessageModalForm("Esta referencia ya ha sido guardada anteriormente");
      setOpenModalForm(true);
    }

    return response
  };

  useEffect(() => {
    var description = "";
    var campos_var = "";
    const familia = selectedOptions?.familia?.label || "";
    const subfamilia = selectedOptions?.subfamilia?.label || "";
    const marca = formValues?.marca?.label || "";
    const desc_ad = formValues?.descripcion_adicional || "";

    // sort the keys to filter the object with variables fields and add the label to
    //description in consistent order
    var ordered_variable_fields = selectedOptions?.campos_variables || {};
    ordered_variable_fields = Object.keys(ordered_variable_fields).sort() || [];

    for (var i = 0; i < ordered_variable_fields.length; i++) {
      var str = "";
      str = selectedOptions?.campos_variables[ordered_variable_fields[i]].label;
      campos_var += " " + str;
    }
    description = `${familia} ${subfamilia} ${campos_var}${
      marca != "" ? " " + marca : ""
    } ${desc_ad}`;
    setCustomDescription(description);
  }, [selectedOptions, formValues]);

  const change_react_select_tipo = (value) => {
    const _label = value.label;
    const _value = value.value;
    setSelectedOptions({ tipo: { value: _value, label: _label } });

    var _Options = info_lists_response.familia.options.filter((familia) =>
      familia.tipo.includes(_value)
    );
    setFamilyOptions(_Options);
    setSubFamilyOptions([]);
    setVariableFieldsOptions([]);
  };

  const change_react_select_familia = (value) => {
    const _label = value.label;
    const _value = value.value;
    setSelectedOptions({
      tipo: selectedOptions.tipo,
      familia: { value: _value, label: _label },
    });

    var _Options = info_lists_response.subfamilia.options.filter((subfamilia) =>
      subfamilia.familia.includes(_value)
    );
    setSubFamilyOptions(_Options);
    update_variable_fields(_value);
  };

  const change_react_select_subfamilia = (value) => {
    const _label = value.label;
    const _value = value.value;
    setSelectedOptions({
      tipo: selectedOptions.tipo,
      familia: selectedOptions.familia,
      subfamilia: { value: _value, label: _label },
    });

    update_variable_fields(selectedOptions.familia.value, _value);
  };

  const change_react_select_variable_field = (
    value,
    label,
    id_campo_variable
  ) => {
    value.id_campo_variable = id_campo_variable;
    value.label_campo_variable = label;
    var _selectedOptions = { ...selectedOptions };
    if (_selectedOptions.campos_variables === undefined) {
      _selectedOptions.campos_variables = {};
    }
    _selectedOptions.campos_variables[label] = value;
    setSelectedOptions(_selectedOptions);
  };

  const update_variable_fields = (family, subfamily = "") => {
    // Filter the variable fields that depends on the already selected "familia" and "subfamilia"
    var _Options = info_lists_response.campos_variables.options.filter(
      (campo_variable) =>
        campo_variable.familia.includes(family) ||
        campo_variable.subfamilia.includes(subfamily)
    );

    //attach the corresponding options for each variable field, fitlering the options by the foreing key
    //from the variable field
    _Options.map(
      (info) =>
        (info.options = info_lists_response.campos_variables_opciones.filter(
          (opc) => opc.id_campo_variable === info.value
        ))
    );

    setVariableFieldsOptions(_Options);
  };

  const change_input = (event) => {
    var { name, value } = event.target;
    if (name === "eliminar_ref" || name === "referencia_asignada") {
      value = event.target.checked;
    }
    let values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  };

  const change_react_select = (value, name) => {
    let values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  };

  const formatDotsInNumber = (e) => {
    const allowedChars = /^[0-9.,]+$/;
    var { name, value } = e.target;
    
    if (!value.match(allowedChars) && value !== "") {
      return;
    }

    if (value.slice(-1) === ","){
        setValueToShow(value)
        return
    }
    
    let normalizedInput = value.replace(/\./g, "").replace(/,/g, ".")
    const formattedVAlue = FormatDotThousands(normalizedInput)
    let values = { ...formValues };
    values[name] = normalizedInput;
    setFormValues(values);
    setValueToShow(formattedVAlue);
  }

  return (
    <>
      <ModalQuestion
        open={opeDeleteConfirmation}
        onClose={() => setOpeDeleteConfirmation(false)}
        question="¿Está seguro que desea eliminar esta referencia?"
        callback={delete_herramientas}
      />
      <ModalForm
        open={openModalForm}
        onClose={() => setOpenModalForm(false)}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <Dialog
        onClose={() => {
          setOpenModalConfirmation(false);
          onClose(200);
        }}
        open={opeModalConfirmation}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle>
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </DialogTitle>
        <DialogContent>
          <div>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Material creado con éxito.
            </p>
            <p style={{ marginTop: "10px" }}>Codigo: {postResponse?.codigo}</p>
            <p style={{ marginTop: "3px" }}>{customDescription}</p>
          </div>
        </DialogContent>
        <DialogActions />
      </Dialog>
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>{isEdit ? "Editar" : "Agregar"}</h3>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="row_modal_herramientas">
              <div>
                <p className="label_input_modal p_modal_herramientas">Tipo</p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  options={info_lists_response?.tipo?.options}
                  styles={styles_react_select}
                  onChange={(value) => change_react_select_tipo(value, "tipo")}
                  value={selectedOptions?.tipo ? selectedOptions?.tipo : null}
                />
              </div>
              <div>
                <p className="label_input_modal p_modal_herramientas">
                  Familia
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  options={familyOptions}
                  styles={styles_react_select}
                  onChange={(value) =>
                    change_react_select_familia(value, "familia")
                  }
                  value={
                    selectedOptions?.familia ? selectedOptions?.familia : null
                  }
                />
              </div>
              <div>
                <p className="label_input_modal p_modal_herramientas">Marca</p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isClearable={true}
                  options={info_lists_response?.marca?.options}
                  styles={styles_react_select}
                  onChange={(value) => change_react_select(value, "marca")}
                  value={formValues.marca}
                />
              </div>
            </div>
            <div className="row_modal_herramientas">
              <div></div>
              <div>
                <p className="label_input_modal p_modal_herramientas">
                  Subfamilia
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder={
                    subFamilyOptions.length > 0 ? "Seleccione..." : ""
                  }
                  isSearchable={true}
                  isDisabled={!(subFamilyOptions.length > 0)}
                  defaultValue={{
                    value: info_lists_response?.familia?.id,
                    label: info_lists_response?.familia?.label,
                  }}
                  options={subFamilyOptions}
                  styles={styles_react_select}
                  value={
                    selectedOptions?.subfamilia
                      ? selectedOptions?.subfamilia
                      : null
                  }
                  onChange={(value) =>
                    change_react_select_subfamilia(value, "subfamilia")
                  }
                />
              </div>
              <div>
              <p className="label_input_modal p_modal_herramientas">
                    Unidad
                  </p>
                  <input
                    type="text"
                    name="unit"
                    className="input_select_style"
                    onChange={(e) => change_input(e)}
                    value={formValues.unit}
                  />
              </div>
            </div>
            {variableFieldsOptions?.map((campo) => (
              <div className="row_modal_herramientas">
                <div></div>
                <div>
                  <p className="label_input_modal p_modal_herramientas">
                    {campo.label}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    options={campo.options}
                    styles={styles_react_select}
                    value={
                      selectedOptions?.campos_variables
                        ? selectedOptions?.campos_variables[campo.label]
                          ? selectedOptions?.campos_variables[campo.label]
                          : null
                        : null
                    }
                    onChange={(value) =>
                      change_react_select_variable_field(
                        value,
                        campo.label,
                        campo.value
                      )
                    }
                  />
                </div>
                <div></div>
              </div>
            ))}
            <div className="row_modal_herramientas">
              <div></div>
              <div>
                <p className="label_input_modal p_modal_herramientas">
                  Descripción Adicional (N° Referencia o Serie)
                </p>
                <input
                  type="text"
                  name="descripcion_adicional"
                  className="input_select_style"
                  onChange={(e) => change_input(e)}
                  value={formValues.descripcion_adicional}
                />
              </div>
              <div></div>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <p className="label_input_modal p_modal_herramientas">Nombre</p>
              <p className="label_input_modal" style={{ fontWeight: "normal" }}>
                {customDescription}
              </p>
            </div>
            <div
              style={{ display: "flex", height: "116px", marginBottom: "20px" }}
            >
              <div className="col1_herramientas_modal">
                <div>
                  <p className="label_input_modal p_modal_herramientas">
                    Mínimo
                  </p>
                  <input
                    type="number"
                    name="minimo"
                    className="input_select_style"
                    onChange={(e) => change_input(e)}
                    value={formValues.minimo}
                  />
                </div>
                <div>
                  <p className="label_input_modal p_modal_herramientas">
                    Máximo
                  </p>
                  <input
                    type="number"
                    name="maximo"
                    className="input_select_style"
                    onChange={(e) => change_input(e)}
                    value={formValues.maximo}
                  />
                </div>
              </div>
              <div className="col2_herramientas_modal">
                <div style={{ marginTop: "24px" }} className="simple_flex_">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="referencia_asignada"
                      checked={formValues.referencia_asignada}
                      onClick={(e) => {
                        change_input(e);
                      }}
                    />
                    <p
                      style={{ fontSize: "14px", marginLeft: "7px" }}
                      className="bold"
                    >
                      Asignar Referencia
                    </p>
                  </div>
                </div>
                {formValues.referencia_asignada && (
                  <div>
                    <p className="label_input_modal p_modal_herramientas">
                      Proveedor
                    </p>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Seleccione..."
                      menuPlacement="top"
                      isSearchable={true}
                      options={info_lists_response?.proveedor.options}
                      styles={styles_react_select}
                      onChange={(value) =>
                        change_react_select(value, "proveedor")
                      }
                      value={formValues.proveedor}
                    />
                  </div>
                )}
              </div>
              <div className="col3_herramientas_modal">
                <div>
                  <p className="label_input_modal p_modal_herramientas">
                    Valor
                  </p>
                  <input
                    type="text"
                    name="valor"
                    className="input_select_style"
                    onChange={(e) => formatDotsInNumber(e)}
                    value={valueToShow}
                  />
                </div>
              </div>
            
            </div>

            <div className="row2_modal_herramientas">
              <div>
                {isEdit && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="eliminar_ref"
                      checked={deleteRef}
                      onClick={(e) => {
                        setDeleteRef(!deleteRef);
                        change_input(e);
                      }}
                    />
                    <p
                      style={{ fontSize: "14px", marginLeft: "7px" }}
                      className="bold"
                    >
                      Eliminar Referencia
                    </p>
                  </div>
                )}
              </div>
              <div>
                {deleteRef && (
                  <>
                    <p className="label_input_modal p_modal_herramientas">
                      Justificación
                    </p>
                    <textarea
                      type="text"
                      name="justificacion"
                      className="input_select_style"
                      onChange={(e) => change_input(e)}
                    />
                  </>
                )}
              </div>
              <div className="button_container_modal_herramientas">
                {deleteRef ? (
                  <button
                    style={{
                      padding: "3px 15px",
                      margin: "0",
                    }}
                    className="button_delete"
                    onClick={() => setOpeDeleteConfirmation(true)}
                  >
                    Eliminar
                  </button>
                ) : (
                  <button
                    style={{
                      padding: "3px 15px",
                      margin: "0",
                    }}
                    onClick={() => save_herramientas()}
                  >
                    Guardar
                  </button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

export default ModalProducts;
