import "./suppliers.css"
import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import {
  faPlus,
  faSearch,
  faIndustry,
  faCodeBranch,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { styles_react_select_branches as styles_react_select } from "../../../App/components/shared/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { Switch, Route, useHistory } from "react-router-dom";
import {mock_request_search_suppliers, mock_request_search_branches, mock_request_get_contacts} from "./mock_request"
import Select from "react-select";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useCookies } from "react-cookie";

function SuppliersManagement() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false)
  const [searchBy, setSearchBy] = useState()
  const [secondsearchBy, setSecondSearchBy] = useState()
  const [idQuerySelected, setIdQuerySelected] = useState()
  const [searchDataOptions, setSearchDataOptions] = useState({
      suppliers: [],
      branches: [],
      contacts: [],
  })
  const [cookies] = useCookies();
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);
      
  const queryRequest = async (type, idToSearch=null) => {
    setIsLoading(true);
    var url;
    if (type==="suppliers" && !idToSearch){
      url = `suppliers/`
    } else if (type==="suppliers"){
      url = `suppliers/?id=${idToSearch}`
    } else if (type==="branches"){
      url = `branches/?supplier_id=${idToSearch}`
    } else if (type==="contacts"  && !idToSearch){
      url = `contacts/`
    } else if (type==="contacts"){
      url = `contacts/?supplier_id=${idToSearch}`
    }

    var response = await fetch_backend(url, "GET", token, dispatch);

    if (type==="suppliers"){
      setSearchDataOptions({...searchDataOptions, suppliers: buildOptions(response?.suppliers), branches: [], contacts: []})
    } else if (type==="branches"){
      setSearchDataOptions({...searchDataOptions, branches: buildOptions(response?.branches), contacts: []})
    } else if (type ==="contacts"){
      setSearchDataOptions({...searchDataOptions, contacts: buildOptions(response?.contacts)})
    }

    setIsLoading(false);
  }


  const resetSearchData = () => {
    setSearchDataOptions({
      suppliers: [],
      branches: [],
      contacts: []
    })
  }

  const buildOptions = (instancesArr) => {
    let options = []
    
    for (const instance of instancesArr) {
      options.push({ value: instance.id, label: instance.name });
    }
    return options
} 

const redirectSearch = () => {
    let redirectModule
    if (searchBy && idQuerySelected) {
    if (searchBy==="suppliers"){
      redirectModule = `supplier/${idQuerySelected}`
    } else if (searchBy==="branches"){
      redirectModule = `branch/${idQuerySelected}`
    } else if (searchBy==="contacts"){
      redirectModule = `contacts/${idQuerySelected}`
    }
    history.push(`${path}/${redirectModule}`);
  }
}

  return (
    <div className="container__clients">
      <div className="img__icon">
        <img
          src={require("../../../App/images/fabrica.svg")}
          className="img__factory"
        />
        <h2>Proveedores y Contactos</h2>
      </div>
      <div
        className="simple_flex_"
        style={{
          width: "80%",
          justifyContent: "space-evenly",
        }}
      >
        <div className="square__button" onClick={() => (history.push(`${path}/supplier-add`))}>
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faIndustry}
            color="white"
          />
          <h4 className="white">Agregar</h4>
        </div>
        <div className="square__button" onClick={() => (history.push(`${path}/branch-add`))}>
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faCodeBranch}
            color="white"
          />
          <h4 className="white">Agregar</h4>
        </div>
        <div className="square__button" onClick={() => (history.push(`${path}/contacts-add`))}>
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faUsers}
            color="white"
          />
          <h4 className="white">Agregar</h4>
        </div>
        <div
          className="square__button"
          onClick={() => setIsSearch(!isSearch)}
        >
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faSearch}
            color="white"
          />
          <h4 className="white">Editar/Consultar</h4>
        </div>
      </div>
      {isSearch && <>
      <div className="simple_flex_"
        style={{
          width: "50%",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          margin: "30px 0 15px 0"
        }}>
        <div name="col1" style={{ flex: 0.3 }}>
          <p className="labeltag"  style={{marginBottom: "15px"}}>Tipo de Busqueda</p>
          <div>
            <input
            className="inputRadio__addclient"
            type="radio"
            name="mainSearch"
            value="suppliers"
            onClick={(e) => {setSearchBy(e.target.value); queryRequest(e.target.value)}}
            />
            <label for="mainSearch" style={{marginRight: "10px"}}>Proveedor</label>
          </div>
          <div style={{marginTop: "10px"}}>
            <input
            className="inputRadio__addclient"
            type="radio"
            name="mainSearch"
            value="branches"
            onClick={(e) => {setSearchBy(e.target.value); queryRequest("suppliers")}}
            />
            <label for="mainSearch" style={{marginRight: "10px"}}>Sucursal</label>
          </div>     
          <div style={{marginTop: "10px"}}>
            <input
            className="inputRadio__addclient"
            type="radio"
            name="mainSearch"
            value="contacts"
            onClick={(e) => setSearchBy(e.target.value)}
            />
            <label for="mainSearch" style={{marginRight: "10px"}}>Contacto</label>
          </div>
              
        </div>
        {searchBy && <>
        <div name="col2" style={{ flex: 0.3}}>
          {searchBy ==="suppliers"? 
          <div>
          <p className="labeltag">Seleccione Proveedor</p>
          <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione..."
              isSearchable={true}
              isLoading={isLoading}
              options={searchDataOptions?.suppliers}
              styles={styles_react_select}
              onChange={(value) => setIdQuerySelected(value.value)}
              />
          </div>
          : searchBy==="branches"? 
          <>
          <div>
          <p className="labeltag">Seleccione Proveedor</p>
          <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione..."
              isSearchable={true}
              isLoading={isLoading}
              options={searchDataOptions?.suppliers}
              styles={styles_react_select}
              onChange={(value) => {queryRequest("branches", value.value)}}
              />
          </div>
          <div style={{marginTop: "15px"}}>
          <p className="labeltag">Seleccione Sucursal</p>
          <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione..."
              isSearchable={true}
              isLoading={isLoading}
              options={searchDataOptions?.branches}
              styles={styles_react_select}
              onChange={(value) => setIdQuerySelected(value.value)}
              />
          </div>
          </>
          : searchBy==="contacts"? 
          <>
          <p className="labeltag"  style={{marginBottom: "15px"}}>Tipo de Busqueda</p>
          <div>
            <input
            className="inputRadio__addclient"
            type="radio"
            name="secondarySearch"
            value="name"
            onClick={(e) => {queryRequest("contacts"); setSecondSearchBy("name")}}
            />
            <label for="secondarySearch" style={{marginRight: "10px"}}>Nombre</label>
          </div>
          <div style={{marginTop: "10px"}}>
            <input
            className="inputRadio__addclient"
            type="radio"
            name="secondarySearch"
            value="business"
            onClick={(e) => {queryRequest("suppliers"); setSecondSearchBy("suppliers")}}
            />
            <label for="secondarySearch" style={{marginRight: "10px"}}>Empresa</label>
          </div>
          </> 
          : null}
        </div>
        {secondsearchBy && <>
        <div name="col3" style={{ flex: 0.3}}>
        {searchBy ==="contacts" && secondsearchBy === "name" ?
          <div>
          <p className="labeltag">Seleccione Contacto</p>
          <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione..."
              isSearchable={true}
              isLoading={isLoading}
              options={searchDataOptions?.contacts}
              styles={styles_react_select}
              onChange={(value) => setIdQuerySelected(value.value)}
              />
          </div>
          : searchBy ==="contacts" && secondsearchBy === "suppliers"?
          <>
          <div>
            <p className="labeltag">Seleccione Proveedor</p>
            <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isSearchable={true}
                isLoading={isLoading}
                options={searchDataOptions?.suppliers}
                styles={styles_react_select}
                onChange={(value) => queryRequest("contacts", value.value)}
                />
          </div>
          <div style={{marginTop: "15px"}}>
            <p className="labeltag">Seleccione Contacto</p>
            <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isSearchable={true}
                isLoading={isLoading}
                options={searchDataOptions?.contacts}
                styles={styles_react_select}
                onChange={(value) => setIdQuerySelected(value.value)}
                />
          </div>
          </>
          : null}

        </div>
        </>}
        </>}
      </div>
      {idQuerySelected &&
      <div className="simple_flex_">
        <button onClick={()=>redirectSearch()}>Buscar</button>
      </div>
      }
      </>}
    </div>
  );
}

export { SuppliersManagement };
