const mock_request_supplier_edit = {
    "supplier": {
        "type_provider": "legal",
        "country": "Colombia",
        "main_branch": true,
        "business_name": "raazon social",
        "tradename": "nombre comercial",
        "government_id": "111111",
        "city": "Abriaquí",
        "address": "direcc",
        "phone_indicator": "4444",
        "phone": "333333",
        "bank_number": "33333333333333",
        "bank": "banco",
        "bank_type_account": "savings",
        "ciiu": "3233",
        "website": "website",
        "auto_retainer": "true",
        "payment_method": "credito",
        "payment_term": "50 dias"
    },
    "contact": {
        "country": "Colombia",
        "name": "nombre conta",
        "last_name": "apellido conta",
        "position": "cargo",
        "email": "email",
        "phone_indicator": "22",
        "phone": "444444",
        "extension": "223",
        "cellphone": "43323454",
        "gender": "M",
        "city": "Amalfi"
    }
}

const mock_request_get_edit = {
    suppliers: [
        {
            name: "cesar",
            id: 3
        }
    ],
    branch: {
        supplier_id: "3",
        supplier_name: "cesar",
        country: "Colombia",
        city: "Medellin",
        address: "Cra50",
        phone_indicator: 40,
        phone: 311789046,
        main_branch: true,
        contact_id: 5
    },
    contacts: [
        {
            name: "Esteban",
            id: "5"
        }
    ]
    
}

const mock_request_get = {
    suppliers: [
        {
            name: "cesar",
            id: 3
        }
    ],
    contacts: [
        {
            name: "Esteban",
            id: "5"
        }
    ]
    
}

const mock_request_get_edit_contact = {
    suppliers: [
        {
            name: "cesar",
            id: 3
        }
    ],
    contact: {
        supplier_id: "3",
        supplier_name: "cesar",
        name: "nombre",
        last_name: "last_name",
        position: "cargo",
        email: "email",
        phone_indicator: "40",
        phone: "311789046",
        extension: "311",
        cellphone: "3223",
        country: "Colombia",
        gender: "M",
        country: "Colombia",
        city: "Medellin",
    }
}

const mock_request_get_contact = {
    suppliers: [
        {
            name: "cesar",
            id: 3
        }
    ]
    
}

/// for search in suppliers management

const mock_request_search_suppliers = {
    suppliers: [
        {
            name: "cesar",
            id: 3
        }
    ]
}

const mock_request_search_branches = {
    branches: [
        {
            name: "branch1",
            id: 4
        }
    ]
}

const mock_request_get_contacts = {
    contacts: [
        {
            name: "david",
            id: 5
        }
    ]
}

export {
     mock_request_get_edit,
     mock_request_get, 
     mock_request_get_edit_contact, 
     mock_request_get_contact, 
     mock_request_supplier_edit, 
     mock_request_search_suppliers, 
     mock_request_search_branches, 
     mock_request_get_contacts
    }