import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./Login.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  faCheckCircle,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { init_crm_location } from "../../../CRM/constants/constants";
import { init_inventario_location } from "../../../Inventario/constants/constants";
import { init_centro_log_location } from "../../../Centro_Logistico/constants/constants";
import { init_purchase_location } from "../../../Compras/constants/constants";
import { init_orders_location } from "../../../Pedidos/constants/constants";
import { useParams } from "react-router-dom";

function Login() {
  const history = useHistory();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies("token_crm");

  const { module } = useParams();
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchSuccessPass, setFetchSuccessPass] = useState("default");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);

  const [email, setEmail] = useState();
  const fetch_login = () => {
    setIsSubmitting(true);
    fetch(`${global.url_back}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: user,
        password: password,
        module: module,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            if (json_response.token_crm) {
              setCookie("token_crm", json_response.token_crm, {
                path: "/",
              });
            }
            if (json_response.token_centro_log) {
              setCookie("token_centro_log", json_response.token_centro_log, {
                path: "/",
              });
            }
            if (json_response.token_inventario) {
              setCookie("token_inventario", json_response.token_inventario, {
                path: "/",
              });
            }
            if (json_response.token_purchase) {
              setCookie("token_purchase", json_response.token_purchase, {
                path: "/",
              });
            }
            if (json_response.token_orders) {
              setCookie("token_orders", json_response.token_orders, {
                path: "/",
              });
            }
            if (json_response.superuser) {
              setCookie("super_user", json_response.superuser, {
                path: "/",
              });
            }
            if (json_response.superuser_inventario) {
              setCookie(
                "super_user_inventario",
                json_response.superuser_inventario || false,
                {
                  path: "/",
                }
              );
            }
            if (json_response.superuser_purchase) {
              setCookie(
                "super_user_purchase",
                json_response.superuser_purchase || false,
                {
                  path: "/",
                }
              );
            }
            if (json_response.codigo_vendedor) {
              setCookie("codigo_vendedor", json_response.codigo_vendedor, {
                path: "/",
              });
            }
            if (module === "crm") {
              setIsSubmitting(false);
              history.push(init_crm_location);
            } else if (module === "centro_log") {
              history.push(init_centro_log_location);
            } else if (module === "inventario") {
              history.push(init_inventario_location);
            } else if (module === "purchase") {
              history.push(init_purchase_location);
            } else if (module === "orders") {
              history.push(init_orders_location);
            }
          });
        } else {
          setOpenDialog(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setOpenDialog(true);
        setIsSubmitting(false);
      });
  };

  const fetch_password = () => {
    setIsSubmitting(true);

    fetch(`${global.url_back}/reset_password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsSubmitting(false);
          setFetchSuccessPass(true);
        } else if (response.status === 406) {
          setFetchSuccessPass(false);
          setIsSubmitting(false);
          setWrongEmail(true);
        } else {
          console.log("error Quote-query");
          setFetchSuccessPass(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccessPass(false);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (
      module != "crm" &&
      module != "centro_log" &&
      module != "inventario" &&
      module != "purchase" &&
      module != "orders"
    ) {
      history.push("/log/crm");
    }
  }, []);

  return (
    <div className="screen-container">
      <Dialog
        onClose={() => {
          setOpenDialogPassword(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialogPassword}
      >
        <DialogTitle id="simple-dialog-title">Recuperar Contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccessPass === "default" ? (
              <>
                <p>Introduce Tu Email:</p>
                <br></br>
                <input
                  type="text"
                  style={{ width: "350px" }}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </>
            ) : fetchSuccessPass ? (
              <>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ fontSize: "25px" }}
                  color="#266e45"
                />
                <p>Verifica la nueva contraseña en tu correo</p>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ fontSize: "25px" }}
                  color="red"
                />
                {wrongEmail
                  ? "  Error: Tu correo no se encuentra en la Base de Datos"
                  : "  Error: Algo ha salido mal, intenta nuevamente"}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {fetchSuccessPass === "default" ? (
            <button className="btn-userconfig" onClick={() => fetch_password()}>
              {isSubmitting ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa-spin"
                  style={{ fontSize: "25px" }}
                />
              ) : (
                "Enviar"
              )}
            </button>
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Credenciales Invalidas</DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="vertical-center">
        <img
          className="login__logo"
          src={require("../../../App/images/cn_logo_verde_letra.svg")}
          alt=""
        />
        <div className="login">
          <div className="login__square">
            {module === "crm" ? (
              <h1 className="login__title">CRM</h1>
            ) : module === "centro_log" ? (
              <h3 className="login__title">Centro Logístico</h3>
            ) : module === "inventario" ? (
              <h3 className="login__title">Inventario</h3>
            ) : module === "purchase" ? (
              <h3 className="login__title">Compras</h3>
            ) : module === "orders" ? (
              <h3 className="login__title">Pedidos</h3>
            ) : (
              <h3 className="login__title">ERROR</h3>
            )}
            <h4 className="login__subtitle">Usuario</h4>
            <input
              className="login__input"
              type="text"
              name=""
              value={user}
              onChange={(event) => setUser(event.target.value)}
            />

            <h4 className="login__subtitle">Constraseña</h4>
            <input
              className="login__input"
              type="password"
              name=""
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />

            <p
              className="login__forgot"
              onClick={() => {
                setFetchSuccessPass("default");
                setOpenDialogPassword(true);
                setWrongEmail(false);
              }}
            >
              Olvidaste Contraseña?
            </p>

            <button onClick={() => fetch_login()}>
              {isSubmitting ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa-spin"
                  style={{ fontSize: "25px" }}
                />
              ) : (
                "Ingresar"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
