import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";

import Folks from "../../../App/fonts/Folks-Normal.ttf";
import Folks_bold from "../../../App/fonts/Folks-Bold.ttf";
import Folks_heavy from "../../../App/fonts/Folks-Heavy.ttf";
import Arial_bold from "../../../App/fonts/arialbd.ttf";
import {
  Table,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import logoVerde from "../../../App/images/cn_logo_verde_letra.png"

function OrderPdf({dataOp}) {

  const [data, setData] = useState(dataOp);

  useEffect(() => {
  }, []);


  Font.register({ family: "Folks", src: Folks });
  Font.register({ family: "Folks_bold", src: Folks_bold });
  Font.register({ family: "Arial_bold", src: Arial_bold });
  Font.register({ family: "Folks_heavy", src: Folks_heavy });

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Folks_bold",
    },

    normal_txt: {
      fontFamily: "Folks",
    },

    page: {
      backgroundColor: "white",
      paddingBottom: 30,
    },

    tabletxt: {
      fontSize: 12,
      height: "20px",
      fontFamily: "Folks",
    },

    pages_content: {
      padding: "0 25px"
    },


    main_header_container: {
      height: "120px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
    },

    logo_container: {
      justifyContent: "center",
      alignItems: "center",
    },

    header_col1: {
      flex: 0.1,
      border: "1pt solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 10px"

    },

    header_col2: {
      flex: 0.6,
      display: "flex",
      flexDirection: "column",
    },

    header_col3: {
      flex: 0.3,
      display: "flex",
      flexDirection: "column",
    },

    square_borders: {
      border: "1pt solid black",
    },

    table_headers_container: {
      display: "flex",
      flexDirection: "row",
      height: "20px",
      backgroundColor: "#dbdbdb",

    },

    header_cell: {
      fontSize: 12,
      fontFamily: "Folks_bold",
    },
    
  });
  

  return (
    <>
      <Document>
        <Page size={[816, 1056]} style={styles.page} wrap={true}>
          <View style={styles.pages_content}> 
            <View style={{...styles.main_header_container, ...styles.square_borders}} fixed>
              <View style={styles.header_col1}>
              <Image
                src={logoVerde}
                style={{height: 42}}
              />
              </View>
              <View style={styles.header_col2}>
                <View style={{...styles.square_borders, flex: 0.3, justifyContent: "center"}}>
                    <Text style={{...styles.normal_txt, textAlign: "center"}}>TIPO: {dataOp?.type}</Text>
                </View>
                <View style={{...styles.square_borders, flex: 0.7, justifyContent: "center"}}>
                    <Text style={{...styles.normal_txt, textAlign: "center"}}>{dataOp?.orderName}</Text>
                </View>
              </View>
              <View style={styles.header_col3}>
                <View style={{...styles.square_borders, flex: 0.14, justifyContent: "center"}}>
                  <Text style={{...styles.normal_txt, textAlign: "center", fontSize: 13}}>{dataOp?.orderDate}</Text>
                </View>
                <View style={{...styles.square_borders, flex: 0.15, justifyContent: "center"}}>
                  <Text style={{...styles.normal_txt, textAlign: "center", fontSize: 14}}>OT {dataOp?.orderNumber}</Text>
                </View>
                <View style={{...styles.square_borders, flex: 0.71, justifyContent: "center"}}>
                  <Image src={data.b64barcode}/>
                </View>
              </View>
            </View>
            <View style={styles.table_headers_container} fixed>
              <View style={{...styles.square_borders, flex: 0.125, justifyContent: "center"}}>
                <Text style={{...styles.header_cell, textAlign: "center"}}>CÓDIGO</Text>
              </View>
              <View style={{...styles.square_borders, flex: 0.715, justifyContent: "center"}}>
                <Text style={{...styles.header_cell, textAlign: "center"}}>DESCRIPCIÓN</Text>
              </View>
              <View style={{...styles.square_borders, flex: 0.07, justifyContent: "center"}}>
                <Text style={{...styles.header_cell, textAlign: "center"}}>UNIDAD</Text>
              </View>
              <View style={{...styles.square_borders, flex: 0.09, justifyContent: "center"}}>
                <Text style={{...styles.header_cell, textAlign: "center"}}>CANTIDAD</Text>
              </View>
              
              
            </View>
            <View>
                <Table data={data.items} >
                  
                  <TableBody textAlign="center">
                 
                    <DataTableCell
                      getContent={(r) => r.code}
                      style={styles.tabletxt}
                      weighting={0.125}
                    />
                    <DataTableCell
                      getContent={(r) => r.description}
                      style={styles.tabletxt}
                      weighting={0.714}
                    />
                    <DataTableCell
                      getContent={(r) => r.unit}
                      style={styles.tabletxt}
                      weighting={0.07}
                    />
                    <DataTableCell
                      getContent={(r) => r.quantity}
                      style={styles.tabletxt}
                      weighting={0.09}
                    />
                    
                  </TableBody>
                </Table>
            </View>
          </View>
          
        </Page>
      </Document>
    </>
  );
}

function ViewOrderPdf() {
  return (
    <div>
      <PDFDownloadLink document={<OrderPdf />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
}

export { OrderPdf, ViewOrderPdf };
