import { response_get_mock } from "./responses_mock";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import {
  faSearch,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalQuestion } from "../../../App/components/shared/Modals";

function Orders_manager() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const history = useHistory();
  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [filterComboValue, setFilterComboValue] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState();
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request()
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    url = `orders_group/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    setJson_response(response);
    setBackupJson_response(response);
    setIsLoading(false);
  }

  const click_detail = (groupId, groupName) => {
    history.push(`${path}/${groupId}`, {
      groupId: groupId,
      groupName: groupName
    });
  };

  const handleSearch = (value) => {
    var List = [...backupJson_response.grouped_orders];
    var newList = [];
    value = value.toString().toLowerCase();
    if (value !== "") {
      for (var i = 0; i < List.length; i++) {
        var group = {};
        group.group_name = List[i].group_name;
        var new_items = List[i].items.filter(
          (item) =>
            item.code.toLowerCase().includes(value) ||
            item.description.toLowerCase().includes(value) ||
            item.work_order.toLowerCase().includes(value)
        );
        group.items = new_items;
        newList.push(group);
      }
      setJson_response({ ...json_response, grouped_orders: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  const onFocusSearch = () => {
    setBackupJson_response(json_response);
  };

  return (
    <div className="wrapped__container_top_bar ">
      <Loading isLoading={isLoading} />
      <div className="top-bar">
        <h3>Gestión de Pedidos</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={() => onFocusSearch()}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Código"
              txt_filter="code"
              width="14%"
            />
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Descripción"
              txt_filter="description"
              width="25%"
            />
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Pedido"
              txt_filter="order_id"
              width="13%"
            />
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Orden de Trabajo"
              txt_filter="work_order"
              width="13%"
            />
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Cantidad"
              txt_filter="quantity"
              width="13%"
            />
            <TableHeader
              // sortFunction={sortFunction}
              sortAscen={sortAscen}
              // click_column={click_column}
              clicked_column={clicked_column}
              col_name="Solicitado por"
              txt_filter="user"
              width="20%"
            />
          </tr>
          {json_response?.grouped_orders?.map((row, index) => (
            <>
              <tr
                className="rowTasks_title"
                style={{ backgroundColor: "#78b994" }}
              >
                <td colSpan="9">
                  <div
                    className="simple_flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{row.group_name}</div>
                    <div style={{ paddingRight: "15px" }}>
                      <div
                        className="button_appereance"
                        style={{ padding: "2px 8px", fontSize: "14px" }}
                        onClick={() => click_detail(row.group_id, row.group_name)}
                      >
                        Gestionar
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {row.items?.map((row, index) => (
                <>
                  <tr className="rowTasks">
                    <td>{row.code}</td>
                    <td>{row.description}</td>
                    <td>{row.id}</td>
                    <td>{row.work_order}</td>
                    <td>{row.quantity}</td>
                    <td>{row.requested_by}</td>
                  </tr>
                </>
              ))}
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export { Orders_manager };
