import "./stock.css";
import ModalProducts from "./Modal_products";
import { response_get_mock, init_get_mock } from "./responses_mock";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatableSelect from "react-select/creatable";
import { useCookies } from "react-cookie";
import Select from "react-select";
import {
  faSearch,
  faInfoCircle,
  faTrash,
  faExclamationTriangle,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import { fade } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import {
  styles_react_select_purchase_stock_codes,
  styles_react_select_purchase_stock_descriptions,
} from "../../../App/components/shared/Select";
import { url_purchase_stock } from "../../constants/constants";

function Stock() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const history = useHistory();
  const [sortAscen, setSortAscen] = useState(true);
  const [clicked_column, setClicked_column] = useState("");
  const [reactSelectOptions, setReactSelectOptions] = useState({});
  const [json_response, setJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState();
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const [initValuesModalProducts, setInitValuesModalProducts] = useState();
  const [inputsData, setInputsData] = useState({})

  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    mainRequest(0)
    // setJson_response(response_get_mock);

    // fillOptionsReactSelect(init_get_mock);
  }, []);

  async function mainRequest(page, description=null, product_code=null) {
    setIsLoading(true);
    url = `purchase-stock/?page=${page+1}`
    var url;
    if (description ){
      url += `&description=${description}`
    } 
    if (product_code) {
      url += `&product_code=${product_code}`
    }
    var response = await fetch_backend(url, "GET", token, dispatch);
    setJson_response(response);
    setIsLoading(false);
  }


  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  // const fillOptionsReactSelect = (initResponse) => {
  //   const productsOptions = [];
  //   const descriptionOptions = [];
  //   for (const product of initResponse.products) {
  //     productsOptions.push({
  //       label: product.product_code,
  //       value: product.product_code,
  //     });

  //     descriptionOptions.push({
  //       label: product.description,
  //       value: product.produc_code,
  //     });
  //   }
  //   console.log(productsOptions);
  //   setReactSelectOptions({
  //     productsOptions: productsOptions,
  //     descriptionOptions: descriptionOptions,
  //   });
  // };

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.purchase_orders];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "expected_date" && sub !== "date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });
    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    if (!sortAscen) {
      sorted.reverse();
    }
    setJson_response({ ...json_response, purchase_orders: sorted });
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Set the desired z-index value
    }),
  };

  const onCloseModalProducts = (status) => {
    if (status === 200) {
      setOpenModalProducts(false);
      setJson_response({});
      mainRequest(0)
    } else {
      setOpenModalProducts(false);
    }
    setIsEdit(false);
  };

  const handlePageClick = (selectedPage) => {
    mainRequest(selectedPage, inputsData.description, inputsData.product_code)
  }

  const inputChange = (target) => {
    const inputDataCopy = { ...inputsData };
    inputDataCopy[target.name] = target.value;
    setInputsData(inputDataCopy);
  };

  const searchProduct = () =>{
    mainRequest(0, inputsData.description, inputsData.product_code)
  }

  return (
    <div className="main_container_orders_detail">
      <Loading isLoading={isLoading} />
      {openModalProducts && (
        <ModalProducts
          open={openModalProducts}
          onClose={onCloseModalProducts}
          initValuesModalProductos={initValuesModalProducts}
          isEdit={isEdit}
        />
      )}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          marginTop: "-20px",
          height: "125px",
        }}
      >
        <h3 style={{ paddingTop: "20px" }}>Inventario</h3>
        <div className="simple_flex_" style={{ marginTop: "20px" }}>
          <div className="simple_flex_" style={{ flex: 0.2 }}>
            <p
              className="p_bold"
              style={{
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              Código
            </p>
            <div style={{ flex: 0.8 }}>
              {/* <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                menuPortalTarget={document.body}
                isSearchable={true}
                // isDisabled={!is_edit && !is_new ? true : false}
                options={reactSelectOptions.productsOptions}
                styles={styles_react_select_purchase_stock_codes}
                // onChange={(value) =>
                //   ChangeReactSelectTable(value, index, "description")
                // }
                // value={
                //   tableRows[index].description?.value
                //     ? tableRows[index].description
                //     : null
                // }
              /> */}
              <input
                type="text"
                name="product_code"
                value={inputsData?.product_code}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
          </div>
          <div className="simple_flex_" style={{ flex: 0.6 }}>
            <p
              className="p_bold"
              style={{
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              Descripción
            </p>
            <div style={{ flex: 0.98 }}>
              {/* <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isSearchable={true}
                menuPortalTarget={document.body}
                // isDisabled={!is_edit && !is_new ? true : false}
                options={reactSelectOptions.descriptionOptions}
                styles={styles_react_select_purchase_stock_descriptions}
                // onChange={(value) =>
                //   ChangeReactSelectTable(value, index, "description")
                // }
                // value={
                //   tableRows[index].description?.value
                //     ? tableRows[index].description
                //     : null
                // }
              /> */}
              <input
                type="text"
                name="description"
                value={inputsData?.description}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
          </div>
          <div className="simple_flex_" style={{ flex: 0.1 }}>
            <FontAwesomeIcon
              icon={faSearch}
              className="iconRow"
              onClick={() => searchProduct()}
            />
          </div>

          <div
            className="simple_flex_"
            style={{ flex: 0.3, justifyContent: "flex-end" }}
          >
            { path === url_purchase_stock &&
            <div
              className="button_appereance"
              onClick={() => {
                setIsEdit(false);
                setOpenModalProducts(true);
              }}
            >
              <p style={{ color: "white", padding: "5px 10px" }}>Agregar +</p>
            </div>
            }
          </div>
        </div>
      </div>

      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Código"
              txt_filter="product_code"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Descripción"
              txt_filter="description"
              width="25%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Unidad"
              txt_filter="measure"
              width="5%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Stock"
              txt_filter="stock"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Mínimo"
              txt_filter="date"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Máximo"
              txt_filter="date"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="$ Prom"
              txt_filter="average_cost"
              width="8%"
            />
            { path === url_purchase_stock &&
            <TableHeader col_name="Editar" width="4%" />
          }
          </tr>
          {json_response?.result?.products?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>{row.product_code}</td>
                <td>{row.description}</td>
                <td>{row.measure}</td>
                <td>{row.stock}</td>
                <td>{row.minimum}</td>
                <td>{row.maximum}</td>
                <td>{Number(row?.average_cost).toLocaleString('de-DE')}</td>
                { path === url_purchase_stock &&
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="iconRow"
                    onClick={() => {
                      setIsEdit(true);
                      setInitValuesModalProducts(row);
                      setOpenModalProducts(true);
                    }}
                  />
                </td>
                }
              </tr>
            </>
          ))}
        </table>
      </div>
      <div
        className="simple_flex_"
        style={{ justifyContent: "flex-end", margin: "25px 0" }}
      >
        <ReactPaginate
          onPageChange={(e)=> handlePageClick(e.selected)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={json_response?.total_pages}
          previousLabel="<"
          nextLabel=">"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active-page"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export { Stock };
