import React, { useState } from "react";
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TableHeader({
  sortFunction,
  sortAscen,
  click_column,
  clicked_column,
  txt_filter,
  col_name,
  width,
  min_width 
}) {

  return (
    <th
      style={width? {width: width}: {minWidth: min_width}}
      onClick={() => {
        if (sortFunction) sortFunction(txt_filter);
        if (click_column) click_column(col_name);
      }}
    >
      {col_name}
      {clicked_column === col_name ? (
        sortAscen ? (
          <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
        ) : (
          <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
        )
      ) : null}
    </th>
  );
}

export default TableHeader;
