import "./inputs_purchase.css";
import _ from "lodash";
import ModalProducts from "../Stock/Modal_products";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import {url_purchase_inputs} from "../../constants/constants"
import {response_mock_purchase_detail} from "./responses_mock"

import Loading from "../../../App/components/Loading/Loading";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import {
    styles_react_select,
  } from "../../../App/components/shared/Select";




function InputsPurchaseDetail() {
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const [json_response, setJson_response] = useState();
    const [isLoading, setIsLoading] = useState();
    const { orderId } = useLocation().state;
    const [optionsToShow, setOptionsToShow] = useState({});
    const [buyer, setBuyer] = useState({});
    const [openModalProducts, setOpenModalProducts] = useState(false);
    const [openModalForm, setOpenModalForm] = useState(false);
    const [successModalForm, setSuccessModalForm] = useState(false);
    const [messageModalForm, setMessageModalForm] = useState();
    const [clickedIndex, setClickedIndex] = useState()
    const [cookies] = useCookies();
    const [redirect, setRedirect] = useState(false)
    const token = cookies.token_purchase;
    const superUserPurchase = cookies.super_user_purchase;
  
    useEffect(() => {
      dispatch({
        type: "SET_URL",
        actual_url: path,
      });
      send_init_request();
    }, []);
  
    async function send_init_request() {
      setIsLoading(true);
      var url;
      url = `orders_buy/${orderId}/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
      // var response = response_mock_purchase_detail;
      setInitDefaultValues(response.items)
      setJson_response(response);
      buildOptions(response)
      setIsLoading(false);
    }


    const buildOptions = (response) => {
        let options = {};
        let groups = []
        let buyers = []
        
        for (const group of response.groups) {
            groups.push({ value: group.id, label: group.name });
        }

        for (const buyer of response.buyers) {
            buyers.push({ value: buyer.id, label: buyer.name });
        }

        if (response?.order_data?.assigned_buyer_id){
            const buyer = buyers?.find(buyer => buyer.value === response?.order_data?.assigned_buyer_id)
            setBuyer(buyer)
        }

        options.groups = groups
        options.buyers = buyers
        setOptionsToShow(options)
    } 

    const setInitDefaultValues = (items) => {
        items.map((item) => {
          if (item.modified_quantity === null || item.modified_quantity === undefined){
            let modified_quantity = (item.stock_quantity - item.reserved_quantity + item.on_way_quantity - item.quantity) || 0
            if (modified_quantity >= 0) {
              modified_quantity = 0
            }else {
              modified_quantity = Math.abs(modified_quantity)
            }
            item.modified_quantity = modified_quantity
          }

          if (!item.group_id && item.default_group_id){
            item.group_id = item.default_group_id
          }
        })
    }


    const validateRequiredFields = (allValues) => {
        const errors = {};
        const ordersErrors = [];
        allValues.items.forEach((item, index) => {
          if (!item.group_id) {
            ordersErrors.push(`Falta seleccionar 'Grupo' en el item codigo: ${item.code}`);
          }
          if (!item.code) {
            ordersErrors.push(`Falta crear un codigo para el item: ${item.description}`);
          }
        });

        if (ordersErrors.length) {
            errors.orders = ordersErrors;
        }

        return errors;
      };
  
    const saveOrder = () => {
        const allValues = {
          items: json_response?.items,
        };
    
        const errors = validateRequiredFields(allValues);
        console.log(allValues)
        if (!_.isEmpty(errors)) {
          setSuccessModalForm(false);
          setMessageModalForm(errors);
          setOpenModalForm(true);
          return;
        }
        send_request("PUT", `orders_buy/${orderId}/` , allValues, true, true);
      };
    
      const ChangeReactSelectTable = (selectedOption, index) => {
        const json_copy = {...json_response}
        const item = json_copy.items[index]
        item.group_id = selectedOption.value

        setJson_response(json_copy)
      }

    const send_request = async (method, url, allValues, showSuccess, redirect_) => {
      setIsLoading(true);
      const response = await fetch_backend(
        url,
        method,
        token,
        dispatch,
        allValues
      );

      setIsLoading(false);
      if (response.status === 200 && showSuccess) {
          setSuccessModalForm(true);
          setMessageModalForm([
            "Guardado Exitoso",
          ]);
          setOpenModalForm(true);
          if (redirect_){
            setRedirect(true)
          }else{
            setRedirect(false)
          }
        } else {
          setRedirect(false)
        }
      
        return response
  }

    const ChangeBuyer = async (selectedOption) => {
      const url = `update_buyer/${orderId}/`
      let response
      if (selectedOption){
          response = await send_request("PUT", url, {assigned_buyer_id: selectedOption.value}, false, false)
      }else{
          response = await send_request("PUT", url, {assigned_buyer_id: null}, false, false)
      }

      if (response.status === 200){
          setBuyer(selectedOption)
      }
    }
  

    const onCloseModalForm = () => {
        setOpenModalForm(false);
        if (redirect){
          history.push(`${url_purchase_inputs}`);
        }
    };

    const onCloseModalProducts = (status, response = null) => {
      console.log("response after closing", response)
      if (response.codigo){
          const copy_init_response = {...json_response}
          const item = copy_init_response.items[clickedIndex]
          item.code = response.codigo
          setJson_response(copy_init_response)
      }
        setOpenModalProducts(false);
    };


    const clickAddProduct = (index) => {
      setClickedIndex(index)
      setOpenModalProducts(true)
    }

    const inputChange = (target, index) => {
      const {name, value} = target
      const json_copy = {...json_response}
      const item = json_copy.items[index]
      item[name] = parseFloat(value)
      setJson_response(json_copy)
    };

    return (
      <>
        <div className="main_container_orders_detail">
        <ModalForm
        open={openModalForm}
        onClose={() => onCloseModalForm()}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      {openModalProducts && (
        <ModalProducts
          open={openModalProducts}
          onClose={onCloseModalProducts}
          initValuesModalProductos={{id: json_response?.items[clickedIndex].id}}
          isEdit={true}
        />
      )}
          <Loading isLoading={isLoading} />
          <h3>Detalle Pedido</h3>
          <div className="simple_flex_" style={{marginTop: "20px", maxWidth: "80%", justifyContent: "space-between", marginBottom: "40px"}}> 
            <div className="simple_flex_column">
                <div className="simple_flex_" style={{marginBottom:"15px"}}>
                    <p className="p_bold">
                        Numero Pedido
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.order_data?.id}
                    </p>
                </div>
                <div className="simple_flex_">
                    <p className="p_bold">
                        Nombre Pedido
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.order_data?.order_name}
                    </p>
                </div>
            </div>
            <div className="simple_flex_column">
                <div className="simple_flex_" style={{marginBottom:"15px"}}>
                    <p className="p_bold">
                        Centro de Costos
                    </p>
                    <p style={{marginLeft: "10px"}}>
                        {json_response?.order_data?.cost_center}
                    </p>
                </div>
                <div className="simple_flex_">
                    <p className="p_bold">
                    Orden de Trabajo
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.order_data?.work_order}
                    </p>
                </div>
            </div>
            <div className="simple_flex_column">
                <div className="simple_flex_" style={{marginBottom:"15px"}}>
                    <p className="p_bold">
                    Fecha de Solicitud
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.order_data?.requested_date}
                    </p>
                </div>
                <div className="simple_flex_">
                    <p className="p_bold">
                        Usuario
                    </p>
                    <p style={{marginLeft: "10px"}}>
                    {json_response?.order_data?.requested_by}
                    </p>
                </div>
            </div>
          </div>

            <div className="simple_flex" style={{maxWidth: "100%"}}>
          <table style={{width: "100%"}}>
            <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
              <th style={{ width: "3%", verticalAlign: "middle"}}>No.</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Código</th>
              <th style={{ width: "40%", verticalAlign: "middle"}}>Descripción</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Unidad</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Cant. Solicitada</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Cant. Inventario</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Cant. Reservada</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Cant. En Camino</th>
              <th style={{ width: "6%", verticalAlign: "middle"}}>Cant. A Pedir</th>
              <th style={{ width: "11%", verticalAlign: "middle"}}>Grupo</th>
              
            </tr>
            {json_response?.items?.map((row, index) => (
              <tr>
                <td>{index + 1}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>
                    {row.code ? row.code: 
                        <div
                            className="simple_square_plus"
                            style={{margin: "auto"}}
                            onClick={() => clickAddProduct(index)}
                        >
                        <h4 className="col_white">+</h4>
                        </div>
                    }    
                </td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row.description}</td>
                <td>{row.unit}</td>
                <td>{row.quantity}</td>
                <td>{row.stock_quantity || 0}</td>
                <td>{row.reserved_quantity || 0}</td>
                <td>{row.on_way_quantity || 0}</td>
                <td>
                    <input
                        type="number"
                        name="modified_quantity"
                        className={`my_input input_center`}
                        style={{maxWidth: "90%"}}
                        value={row.modified_quantity}
                        disabled={json_response?.order_data?.status === "unmanaged"? false: true}
                        onChange={(event) => inputChange(event.target, index)}
                    />
                </td>
                <td>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    isDisabled={json_response?.order_data?.status === "unmanaged"? false: true}
                    options={optionsToShow.groups}
                    styles={styles_react_select}
                    onChange={(value) =>
                      ChangeReactSelectTable(value, index)
                    }
                    value={optionsToShow?.groups?.filter((group) => group.value == row.group_id)}
                  />
                </td>
              </tr>
            ))}
          </table>
          </div>
          
          <div className="flex_space_between" style={{ marginTop: "30px", maxWidth: "100%" }}>
            <div>
            {superUserPurchase && <>
                <p className="p_bold" style={{marginBottom: "10px"}}>Seleccionar Comprador</p>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    isClearable={true}
                    isLoading={isLoading}
                    options={optionsToShow.buyers}
                    styles={styles_react_select}
                    onChange={(value) =>
                      ChangeBuyer(value)
                    }
                    value={buyer}
                  />
                  </>
            }
            </div>
            
              <button
                style={{
                  padding: "3px 15px",
                  margin: "0",
                }}
                className="button"
                onClick={() => saveOrder()}
              >
                Guardar
              </button>
            </div>
                
          
        </div>
      </>
    );
  }

export {InputsPurchaseDetail}