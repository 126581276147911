import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useCookies } from "react-cookie";
import { ModalForm } from "../../../App/components/shared/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {mockUsers, mockOutputOt, mockReferences} from "./mockRequest"
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import _ from "lodash";
import {
  styles_react_select,
} from "../../../App/components/shared/Select";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../App/components/Loading/Loading";


function Outputs() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const [cookies] = useCookies();
  const token = cookies.token_purchase;
  const superUserPurchase = cookies.super_user_purchase;
  const inputRef = useRef(null);
  const buttonRef = useRef(null);


  const [isLoading, setIsLoading] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [json_response, setJson_response] = useState();
  const [outputType, setOutputType] = useState("work_order");
  const [optionsToShow, setOptionsToShow] = useState([])
  const [selectedUser, setSelectedUser] = useState();
  const [workOrderId, setWorkOrderId] = useState()
  const [outputDate, setOutputDate] = useState(new Date().toISOString().split('T')[0])
  const [referencesToShow, setReferencesToShow] = useState()
  const [lossInventoryData, setLossInventoryData] = useState([{product_code: null, output_quantity: null}])


  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request()
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    url = `users/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = mockUsers;
    buildOptions(response)
    setIsLoading(false);
  }

  async function getReferences() {
    setIsLoading(true);
    var url;
    url = `stock-inputs/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = mockReferences
    buildReferencesOptions(response)
    setIsLoading(false);
  }

  const inputChange = (target, index) => {
    const jsonCopy = {...json_response}
    jsonCopy.references[index][target.name] = target.value
    setJson_response(jsonCopy)
  }

  const inputChangeLossInventory = (target, index) => {
    const items = [...lossInventoryData]
    items[index][target.name] = target.value
    setLossInventoryData(items)
  }

  const buildOptions = (response) => {
    let options = {};
    let users = [];

    if(response?.users){
    for (const user of response?.users){
      users.push({
        value: user.id,
        label: user.name
      })
    }
  }

  options.users = users
  setOptionsToShow(options)
}

  const buildReferencesOptions = (response) => {
    let references = [];

    if(response?.references){
    for (const ref of response?.references){
      references.push({
        value: ref.product_code,
        label: ref.product_code,
        description: ref.description,
        unit: ref.unit,
      })
    }
  }

    setReferencesToShow(references)

  }

  const searchWO = async () => {
    setIsLoading(true);
    const response = await fetch_backend(
      `work-order/${workOrderId}/`,
      "GET",
      token,
    );

    setIsLoading(false);
    if (response.status === 404) {
        setSuccessModalForm(false);
        setMessageModalForm([
          "Orden de trabajo no encontrada",
        ]);
        setOpenModalForm(true);
      } else if (response.status !== 200){
        setSuccessModalForm(false);
        setMessageModalForm([
          "Algo salió mal, intenta nuevamente",
        ]);
        setOpenModalForm(true);
      } else {
        setJson_response(response)
      }
    // let copy = {...mockOutputOt}
    // setJson_response(copy)
    
  }

  const validateRequiredFields = (tableItems, save=false) => {
    const errors = {};
    const ordersErrors = [];
    
    if (save===true && !selectedUser) {
      errors.quote = "Falta seleccionar 'Usuario'";
    }

    if (save===true && !outputDate){
      errors.outputDate = "Falta seleccionar 'Fecha Salida'"
    }

    if (!workOrderId && outputType==="work_order"){
      errors.workOrderId = "Falta seleccionar 'No. Orden de Trabajo"
    }

    const errosTable = []
    if (tableItems){
      tableItems.forEach((item, index) => {
        if (!item.product_code) {
          errosTable.push(`Falta seleccionar 'Código'`)
        }
        if (!item.output_quantity) {
          errosTable.push(`Falta diligenciar 'Cant. Salida'`)
        }
    })
    }

    if (save===true && outputType==="inventory_loss"){
      lossInventoryData.forEach((item, index) => {
        if (!item.product_code) {
          errosTable.push(`Falta seleccionar 'Código'. Fila: ${index + 1}`)
        }
        if (!item.output_quantity) {
          errosTable.push(`Falta diligenciar 'Cant. Salida'. Fila: ${index + 1}`)
        }
    })
    }

    if (errosTable.length > 0){
      errors.errosTable = errosTable
    }
    

    return errors;
  };

  const validateQuantities = (references) => {
    const errorsQuantities = []
    references.forEach((item, index) => {
      if (item.output_quantity > item.available_quantity){ 
        errorsQuantities.push(`Ref: ${item.product_code} - La cantidad de salida no puede ser mayor a la cantidad disponible.`)
      }
    })


    return errorsQuantities
  }

  const saveOutput = async () => {
    let url
    let body = buildBodySaveOutput()
    let method

    const errors = validateRequiredFields(null, true);
    if (outputType === "work_order"){
      const errorsQuantities = validateQuantities(body.references)
      if (errorsQuantities.length > 0){
        errors.errorsQuantities = errorsQuantities
      }
    }
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }

    setIsLoading(true);
    if (outputType === "inventory_loss"){
      url = `stock-loss/`
      method =  "POST"
    } else if (outputType === "work_order") {
      url = `work-order/${workOrderId}/`
      method =  "PUT"
    }
   
    const response = await fetch_backend(
      url,
      method,
      token,
      dispatch,
      body
    );
    setIsLoading(false);

    if (response.status === 200) {
      setSuccessModalForm(true);
      setMessageModalForm([
        "Operación Exitosa",
      ]);
      setOpenModalForm(true);
    }

  }

  const buildBodySaveOutput = () => {
    let references
    if (outputType === "inventory_loss"){
      references = lossInventoryData
    } else if ( outputType === "work_order"){
      references = json_response?.references
    }
    for (const ref of references){
      if (ref.output_quantity){
        ref.output_quantity = parseFloat(ref.output_quantity)
      } else{
        ref.output_quantity = 0
      }
    }
    return {
      references: json_response?.references,
      user_id: selectedUser?.value,
      output_date: outputDate,
      output_type: outputType
    }
  }

  const addNewTableRow = () => {
    let tableRows_ = [...lossInventoryData];
    const errors = validateRequiredFields([tableRows_[tableRows_.length - 1]]);
  
      if (!_.isEmpty(errors)) {
        setSuccessModalForm(false);
        setMessageModalForm(errors);
        setOpenModalForm(true);
        return;
      }
    tableRows_.push({
      product_code: null,
      quantity: 0,
    });
    setLossInventoryData(tableRows_);
  }; 
  
  const removeTableRow = (index) => {
    if (lossInventoryData.length > 1){
    let tableRows_ = [...lossInventoryData];
    tableRows_.splice(index, 1);
    setLossInventoryData(tableRows_);
  }
  } 
  
  const ChangeReactSelectInput = (value, index) => {
    const items = [...lossInventoryData]
    items[index].description = value.description
    items[index].product_code = value.label
    items[index].unit = value.unit
    items[index].select_value = {
      value: value.value,
      label: value.label,
    }
    setLossInventoryData(items)
  }

  const outputTypeChange = async(type) => {
    if (type===outputType){
      return
    }

    if (type==="inventory_loss"){
      await getReferences()
    } else {
      setLossInventoryData([{product_code: null, output_quantity: null}])
    }
    setJson_response({})
    setWorkOrderId()
    setOutputDate(new Date().toISOString().split('T')[0])
    setSelectedUser()
    setOutputType(type)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        searchWO()
    }
  };

  const onCloseModalForm = () => {
    if (successModalForm) {
      window.location.reload();
    }
    setOpenModalForm(false);
  }

  return (
    <div className="main_container_orders_detail">
      <Loading isLoading={isLoading} />
      <ModalForm
        open={openModalForm}
        onClose={() => onCloseModalForm()}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <h3 style={{ paddingTop: "20px" }}>Salidas</h3>
      <div className="clienteadd_radiobutton" style={{margin: "20px 0"}}>
        <input
          className="inputRadio__addclient"
          type="radio"
          name="type_output"
          value="work_order"
          checked={outputType === "work_order"}
          onClick={(e) => outputTypeChange(e.target.value)}
        />
        <label for="contactChoice1">Orden de Trabajo</label>
        {superUserPurchase && <>
        <input
          className="inputRadio__addclient"
          type="radio"
          name="type_output"
          value="inventory_loss"
          checked={outputType === "inventory_loss"}
          onClick={(e) => outputTypeChange(e.target.value)}
        />
        <label for="contactChoice1">Perdida de Inventario</label>
        </>}
      </div>
      <div style={{width: "25%", maxWidth: "400px"}}>
        {outputType === "work_order" &&
          <div className="simple_flex_">
              <p className="label_tag bold" style={{color:"#383838", flex: 0.6}}>
                  Orden de Trabajo No.
              </p>
              <input
              ref={inputRef} 
              type="text"
              name="work_order_id"
              className="my_input input_center"
              style={{flex: 0.4, marginLeft: "25px"}}
              onChange={(event) => setWorkOrderId(event.target.value)}
              onKeyPress={handleKeyPress}
            />
            <FontAwesomeIcon icon={faSearch} color="#383838"  style={{fontSize: "20px", marginLeft: "8px"}} onClick={()=>searchWO()} ref={buttonRef}/>
          </div>
        }
        {((outputType === "work_order" && json_response?.references) || outputType === "inventory_loss") &&
          <div className="simple_flex_"  style={{margin: "20px 0"}}>
              <p className="label_tag bold" style={{color:"#383838", flex: 0.5}}>
                  Fecha Salida
              </p>
              <input
              type="date"
              name="output_date"
              className="my_input input_center"
              style={{flex: 0.5}}
              value={outputDate}
              onChange={(event) => setOutputDate(event.target.value)}
            />
          </div>
        }        
      </div>

      {outputType === "work_order" && json_response?.references &&
        <div className="simple_flex" style={{width: "80%"}}>
              <table style={{width: "100%"}}>
                <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
                  <th style={{ width: "10%", verticalAlign: "middle"}}>Código</th>
                  <th style={{ width: "30%", verticalAlign: "middle"}}>Descripción</th>
                  <th style={{ width: "10%", verticalAlign: "middle"}}>Unidad</th>
                  <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Disp. OT</th>
                  <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. En Inventario</th>
                  <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Salida</th>
                </tr>
                {json_response?.references?.map((row, index) => (
                  <tr>
                    <td>{row.product_code}</td>
                    <td>{row.description}</td>
                    <td>{row.unit}</td>
                    <td>{row.available_quantity}</td>
                    <td>{row.stock_quantity}</td>
                    <td style={{padding: "2px 2px"}}>
                      <input
                        type="number"
                        name="output_quantity"
                        className="my_input input_center"
                        disabled={row.available_quantity <= 0}
                        value={json_response?.references[index].output_quantity}
                        onChange={(event) => inputChange(event.target, index)}
                      />
                    </td>
                    
                  </tr>
                ))}
              </table>
        </div>
      }

      {outputType === "inventory_loss" &&
        <div style={{maxWidth: "85%"}}>
        <table style={{width: "100%"}}>
              <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
                <th style={{ width: "15%", verticalAlign: "middle"}}>Código</th>
                <th style={{ width: "50%", verticalAlign: "middle"}}>Descripción</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Unidad</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Salida</th>
                <th style={{ width: "1%", border: 0, backgroundColor: "white" }}></th>
              </tr>
              {lossInventoryData.map((row, index) => (
                <tr>
                  <td>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Seleccione..."
                      isSearchable={true}
                      options={referencesToShow}
                      styles={styles_react_select}
                      onChange={(value) =>
                        ChangeReactSelectInput(value, index, "description")
                      }
                      value={lossInventoryData[index].select_value}
                    />
                  </td>
                  <td>{row.description}</td>
                  <td>{row.unit}</td>
                  <td style={{padding: "2px 2px"}}>
                    <input
                      type="number"
                      name="output_quantity"
                      className="my_input input_center"
                      value={lossInventoryData[index].output_quantity}
                      onChange={(event) => inputChangeLossInventory(event.target, index)}
                    />
                  </td>
                  <td
                    style={{ border: 0, textAlign: "left", paddingLeft: "20px" }}
                    onClick={() => removeTableRow(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="s"
                      color={lossInventoryData.length > 1 ? "red": "grey"}
                    />
                  </td>
                </tr>
              ))}
        </table>
        <div
          className="simple_square_plus"
          onClick={() => addNewTableRow()}
          style={{marginLeft: "auto", marginTop: "10px"}}
        >
          <h4 className="col_white">+</h4>
        </div>
        </div>
      }

      {(json_response?.references || outputType==="inventory_loss") &&
      <>
      <div style={{backgroundColor: "#78BA94", width: "80%", height: "5px", marginTop: "20px"}}></div>
      <div className="simple_flex_" style={{justifyContent: "flex-end", width: "80%", margin: "12px 10px 12px 0"}}>
        <div style={{minWidth: "200px"}}>
          <p className="label_tag bold" style={{marginBottom: "12px"}}>
            Usuario
          </p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleccione..."
            isSearchable={true}
            options={optionsToShow.users}
            styles={styles_react_select}
            onChange={(value) =>
              setSelectedUser(value)
            }
          />

        </div>
      </div>
      
      <div style={{backgroundColor: "#78BA94", width: "80%", height: "5px"}}></div>
      <div className="flex_space_between" style={{ marginTop: "15px", maxWidth: "80%" }}>
      <button
            style={{
              padding: "3px 15px",
              margin: "0 0 0 auto",
            }}
            className="button"
            onClick={() => saveOutput()}
          >
            Guardar
      </button>
      </div>
      </>
      }
  </div>
  );
}

export default Outputs;
