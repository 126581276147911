import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useHistory, useParams, useRouteMatch, useLocation} from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import ModalCloseOp from "./ModalCloseOp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  styles_react_select,
} from "../../../App/components/shared/Select";


import Loading from "../../../App/components/Loading/Loading";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import {mockReturn, mockUsers} from "./mockRequest"
import { faSearch } from "@fortawesome/free-solid-svg-icons";


function InputsReturn() {
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const [cookies] = useCookies();
    const inputRef = useRef(null);
    const token = cookies.token_purchase;

    const [json_response, setJson_response] = useState();
    const [isLoading, setIsLoading] = useState();
    const [openModalForm, setOpenModalForm] = useState(false);
    const [successModalForm, setSuccessModalForm] = useState(false);
    const [messageModalForm, setMessageModalForm] = useState();
    const [optionsToShow, setOptionsToShow] = useState([])
    const [selectedUser, setSelectedUser] = useState();
    const [closeType, setCloseType] = useState()
    const [workOrderId, setWorkOrderId] = useState()
  
    useEffect(() => {
      dispatch({
        type: "SET_URL",
        actual_url: path,
      });
      send_init_request();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
  
    async function send_init_request() {
      setIsLoading(true);
      var url;
      url = `users/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
      // var response = mockUsers;
      buildOptions(response)
      setIsLoading(false);
    }

    const buildOptions = (response) => {
      let options = {};
      let users = [];

      if(response?.users){
      for (const user of response?.users){
        users.push({
          value: user.id,
          label: user.name
        })
      }
    }

      options.users = users
      setOptionsToShow(options)

    }

    const onCloseModalForm = () => {
        setOpenModalForm(false);
        if (successModalForm) {
          history.push("/inventario/inputs");
        }
    };
    
    const inputChange = (value, index) => {
      const references = [...json_response.references]
      references[index][value.name] = value.value
      setJson_response({...json_response, references: references})
    }

    const searchWO = async () => {
      setIsLoading(true);
      const response = await fetch_backend(
        `work-order/${workOrderId}/`,
        "GET",
        token,
      );

      setIsLoading(false);
      if (response.status === 404) {
          setSuccessModalForm(false);
          setMessageModalForm([
            "Orden de trabajo no encontrada",
          ]);
          setOpenModalForm(true);
        } else if (response.status !== 200){
          setSuccessModalForm(false);
          setMessageModalForm([
            "Algo salió mal, intenta nuevamente",
          ]);
          setOpenModalForm(true);
        } else {
          setJson_response(response)
        }
        // setJson_response(mockReturn)
      
    }

    const saveReturn = async () => {

      const errors = {};
      if (!selectedUser){
        errors.user = "Falta seleccionar 'Usuario'"
      }

      if (!_.isEmpty(errors)) {
        setSuccessModalForm(false);
        setMessageModalForm(errors);
        setOpenModalForm(true);
        return;
      }
      setIsLoading(true);
      
      const response = await fetch_backend(
        `work-order-in/${workOrderId}/`,
        "PUT",
        token,
        dispatch,
        {references: json_response?.references, return_user_id: selectedUser?.value}
      );

      setIsLoading(false);
      if (response.status === 200) {
          setSuccessModalForm(true);
          setMessageModalForm([
            "Devolución registrada con éxito",
          ]);
          setOpenModalForm(true);
        }
      
        return response
    }
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          searchWO()
      }
    };

    return (
      <>
        <div className="main_container_orders_detail">
        <ModalForm
          open={openModalForm}
          onClose={() => onCloseModalForm()}
          successModal={successModalForm}
          messageModal={messageModalForm}
        />

        <Loading isLoading={isLoading} />
        <div className="simple_flex_" style={{marginTop: "20px", marginBottom: "15px", maxWidth: "80%", justifyContent: "space-between"}}> 
          <div className="simple_flex_column">
              <div className="simple_flex_">
                  <h4 className="p_bold" style={{color:"#383838", flex: 0.6}}>
                      Orden de Trabajo No.
                  </h4>
                  <input
                  ref={inputRef}
                  type="text"
                  name="work_order_id"
                  className="my_input input_center"
                  style={{flex: 0.3}}
                  onChange={(event) => setWorkOrderId(event.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <FontAwesomeIcon icon={faSearch} color="#383838"  style={{fontSize: "20px", marginLeft: "8px"}} onClick={()=>searchWO()}/>
              </div>
          </div>
            
          </div>
          {json_response && 
          <>
          <div className="simple_flex" style={{maxWidth: "80%"}}>
          <table style={{width: "100%"}}>
            <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Código</th>
              <th style={{ width: "30%", verticalAlign: "middle"}}>Descripción</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Unidad</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Pedida</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Pendiente por Entregar</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. a Devolver</th>
            </tr>
            {json_response?.references?.map((row, index) => (
              <tr>
                <td>{row.product_code}</td>
                <td>{row.description}</td>
                <td>{row.unit}</td>
                <td>{row.quantity}</td>
                <td>{row.missing_quantity}</td>
                <td style={{padding: "2px 2px"}}>
                  <input
                    type="number"
                    name="return_quantity"
                    className="my_input input_center"
                    value={json_response?.references[index].return_quantity}
                    onChange={(event) => inputChange(event.target, index)}
                  />
                </td>
                
              </tr>
            ))}
          </table>
          </div>
          <div style={{backgroundColor: "#78BA94", width: "80%", height: "5px", marginTop: "20px"}}></div>
          <div className="simple_flex_" style={{justifyContent: "flex-end", width: "80%", margin: "12px 10px 12px 0"}}>
            <div>
              <p className="label_tag bold" style={{marginBottom: "12px"}}>
                Usuario Devolucion
              </p>
              <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    options={optionsToShow.users}
                    styles={styles_react_select}
                    onChange={(value) =>
                      setSelectedUser(value)
                    }
                    // value={
                    //   tableRows[index].code?.value
                    //     ? tableRows[index].code
                    //     : null
                    // }
                  />

            </div>
          </div>
          
          <div style={{backgroundColor: "#78BA94", width: "80%", height: "5px"}}></div>
          <div className="flex_space_between" style={{ marginTop: "15px", maxWidth: "80%" }}>
          <button
                style={{
                  padding: "3px 15px",
                  margin: "0 0 0 auto",
                }}
                className="button"
                onClick={() => saveReturn()}
              >
                Guardar
          </button>
          </div>
          </> }
        </div>
      </>
    );
  }


export default InputsReturn