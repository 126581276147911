import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import bodegas from "../../../App/images/quote_2023/140ppi/bodegas.png";
import of_estandar from "../../../App/images/quote_2023/140ppi/of_estandar.png";
import proy_esp from "../../../App/images/quote_2023/140ppi/proy_esp.png";
import serv_log from "../../../App/images/quote_2023/140ppi/serv_log.png";
import mobi from "../../../App/images/quote_2023/140ppi/mobi.png";
import multi_cont from "../../../App/images/quote_2023/180ppi/multi_cont.png";
import fb_image from "../../../App/images/quote_2023/300ppi/fb_image.png";
import www_image from "../../../App/images/quote_2023/300ppi/www_image.png";
import inst_image from "../../../App/images/quote_2023/300ppi/inst_image.png";
import b_image from "../../../App/images/quote_2023/300ppi/b_image.png";
import youtube_image from "../../../App/images/quote_2023/300ppi/youtube_image.png";
import left_corner_triangle from "../../../App/images/quote_2023/300ppi/left_top_corner_triangle.png";
import right_middle_triangle from "../../../App/images/quote_2023/300ppi/right_middle_triangle.png";
import scenery_triangle from "../../../App/images/quote_2023/100ppi/scenery_triangle.png";
import main_green_logo from "../../../App/images/quote_2023/300ppi/main_green_logo.png";
import b_logo_blanco from "../../../App/images/quote_2023/300ppi/b_logo_blanco.png";
import email_icon from "../../../App/images/quote_2023/300ppi/email_icon.png";
import wap_icon from "../../../App/images/quote_2023/300ppi/wap_icon.png";
import footer_green from "../../../App/images/quote_2023/300ppi/footer_green.png";
import Folks from "../../../App/fonts/Folks-Normal.ttf";
import Folks_bold from "../../../App/fonts/Folks-Bold.ttf";
import Folks_heavy from "../../../App/fonts/Folks-Heavy.ttf";
import Arial_bold from "../../../App/fonts/arialbd.ttf";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
  TableRow,
} from "@david.kucsai/react-pdf-table";
import header_contrato from "../../../App/images/header_contrato_v2.jpeg"

function PoPdf({dataOp}) {

  const [data, setData] = useState(dataOp);

  useEffect(() => {
    calculateTotals(dataOp.items)
  }, []);


  Font.register({ family: "Folks", src: Folks });
  Font.register({ family: "Folks_bold", src: Folks_bold });
  Font.register({ family: "Arial_bold", src: Arial_bold });
  Font.register({ family: "Folks_heavy", src: Folks_heavy });

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Folks_bold",
    },


    normal_txt: {
      fontFamily: "Folks",
    },



    page: {
      backgroundColor: "white",
      paddingBottom: 30,
    },


    subtabletxt: {
      fontSize: 13,
      paddingLeft: 2,
      fontFamily: "Folks",
    },

    tabletxt: {
      fontSize: 12,
      height: "40px",
      fontFamily: "Folks",
    },

    table: {
      marginTop: 25,
      marginBottom: 25,
    },


    position: {
      fontFamily: "Folks",
      color: "white",
      letterSpacing: 1,
      marginBottom: 5,
      fontSize: 15,
    },

    green: {
      color: "#006E42",
    },


    central_flex_row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },


    justify_central_flex: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    div_line: {
      marginTop: "15px",
      height: "3px",
      backgroundColor: "green",
      width: "100%",
    },

    pages_content: {
      padding: "0 25px"
    },

    title_cell: {
      backgroundColor: "#dbdbdb",
      height: "20px",
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Folks_bold",
    },

    flex_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    obs_container: {
      border: "1pt solid #000000",
      minHeight: "90px",
      marginTop: "20px"
    }
  });

  const calculateTotals = (items) => {
    // iterate over items
    let total = 0;
    items.forEach((item, index) => {
      item.index = index;
      total += item.quantity * item.price;
    });
    const discountValue = total * (data.discount / 100);
    const subTotal = total - discountValue;
    const retentionValue = subTotal * (data.retention / 100);
    const ivaValue = (subTotal) * (data.iva / 100);
    const greatTotal = subTotal - retentionValue + ivaValue;
    setData({ 
      ...data, 
      items: items, 
      total: total,
      discountValue: discountValue,
      subTotal: subTotal,
      retentionValue: retentionValue,
      ivaValue: ivaValue,
      greatTotal: greatTotal
    });
  }

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  return (
    <>
      <Document>
      <Page size={[816, 1056]} style={styles.page} wrap={true}>
        <View>
          <Image
            src={header_contrato}
            style={{ width: "816px", height: "192px" }}
          />
        </View>

        <View style={styles.pages_content}>
          <View style={styles.justify_central_flex}>
            <Text style={styles.bold}>CONTENEDORES DE ANTIOQUIA S.A.S.</Text>
            <Text style={styles.normal_txt}>NIT 900.167.316 – 4</Text>
            <Text style={styles.normal_txt}>Km 1 entrada a Girardota, Antioquia, Colombia</Text>
            <Text style={styles.normal_txt}>CONTENEDORES DE ANTIOQUIA S.A.S.</Text>
            <Text style={styles.normal_txt}>Cel: +57 {dataOp?.buyerPhone}</Text>
            <View style={{...styles.div_line, marginBottom: "10px"}}></View>
            <Text style={styles.bold}>ORDEN DE COMPRA</Text>
            <Text style={styles.normal_txt}>Purchase Order</Text>
          </View>
          <View style={[styles.central_flex_row, {paddingHorizontal: "30px"}]}>
            <View>
            <Text style={styles.normal_txt}>Fecha Pedido: {new Date().toISOString().split('T')[0]}</Text>
            <Text style={styles.normal_txt}>Comprador: {dataOp?.buyerName}</Text>
            </View>
            <View>
            <Text style={{...styles.normal_txt, ...styles.bold}}>OC: {dataOp?.poId}</Text>
            </View>
          </View>
          <View style={{marginTop: "20px"}}>
          <Table data={[{}]} >
              <TableHeader></TableHeader>
              <TableBody >
                <DataTableCell
                  getContent={(r) => "PROVEEDOR"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.supplierName}
                  weighting={0.4}
                  style={styles.subtabletxt}
                />
                <DataTableCell
                  getContent={(r) => "FORMA DE PAGO"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.paymentMethod}
                  weighting={0.2}
                  style={styles.subtabletxt}
                />
                
                
              </TableBody>
            </Table>
            <Table data={[{}]}>
              <TableBody >
                <DataTableCell
                  getContent={(r) => "NIT"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.governmentId}
                  weighting={0.4}
                  style={styles.subtabletxt}
                />
                <DataTableCell
                  getContent={(r) => "PLAZO"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.paymentTerm}
                  weighting={0.2}
                  style={styles.subtabletxt}
                />
                
                
              </TableBody>
            </Table>
            <Table data={[{}]}>
              <TableBody >
                <DataTableCell
                  getContent={(r) => "TELÉFONO"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.phone}
                  weighting={0.4}
                  style={styles.subtabletxt}
                />
                <DataTableCell
                  getContent={(r) => "AUTORETENEDOR"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => {if (dataOp?.autoRetainer) return "Si"; else return "No";}}
                  weighting={0.2}
                  style={styles.subtabletxt}
                />
                
                
              </TableBody>
            </Table>
            <Table data={[{}]}>
              <TableBody >
                <DataTableCell
                  getContent={(r) => "EMAIL"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.email}
                  weighting={0.8}
                  style={styles.subtabletxt}
                />
                
              </TableBody>
            </Table>
            <Table data={[{}]}>
              <TableBody >
                <DataTableCell
                  getContent={(r) => "DIRERCCIÓN"}
                  weighting={0.2}
                  style={styles.title_cell}
                />
                <DataTableCell
                  getContent={(r) => dataOp?.address}
                  weighting={0.8}
                  style={styles.subtabletxt}
                />
                
              </TableBody>
              
            </Table >
            <View style={{marginTop: "20px"}}>

            <Table data={data.items} >
              <TableHeader>
                <TableCell style={styles.title_cell} weighting={0.02}></TableCell>
                <TableCell style={styles.title_cell} weighting={0.1}>CÓDIGO</TableCell>
                <TableCell style={styles.title_cell} weighting={0.47}>DESCRIPCIÓN</TableCell>
                <TableCell style={styles.title_cell} weighting={0.06}>UNIDAD</TableCell>
                <TableCell style={styles.title_cell} weighting={0.05}>CANT</TableCell>
                <TableCell style={styles.title_cell} weighting={0.15}>VALOR</TableCell>
                <TableCell style={styles.title_cell} weighting={0.15}>TOTAL</TableCell>
              </TableHeader>
              <TableBody textAlign="center">
              <DataTableCell
                  getContent={(r) => r.index +1 }
                  style={styles.tabletxt}
                  weighting={0.02}
                />
                <DataTableCell
                  getContent={(r) => r.product_code}
                  style={styles.tabletxt}
                  weighting={0.1}
                />
                <DataTableCell
                  getContent={(r) => r.extra_description? `**${r.description}**\n${r.extra_description}`: r.description}
                  style={styles.tabletxt}
                  weighting={0.47}
                />
                <DataTableCell
                  getContent={(r) => r.unit}
                  style={styles.tabletxt}
                  weighting={0.06}
                />
                <DataTableCell
                  getContent={(r) => r.quantity}
                  style={styles.tabletxt}
                  weighting={0.05}
                />
                <DataTableCell
                  getContent={(r) => r.price.toLocaleString('de-DE')}
                  style={styles.tabletxt}
                  weighting={0.15}
                />
                <DataTableCell
                  getContent={(r) => (r.quantity * r.price).toLocaleString('de-DE')}
                  style={styles.tabletxt}
                  weighting={0.15}
                />
                
              </TableBody>
            </Table>
            </View>
            <View wrap={false}>
              <View style={{...styles.flex_view, justifyContent: "space-between", marginTop: "20px"}}>
                <View style={{flex: 0.48}}>
                  <Table data={[{}]}>
                  <TableHeader>
                      <TableCell style={styles.title_cell}>
                        DIRECCIÓN DE ENTREGA
                      </TableCell>
                  </TableHeader>
                    <TableBody textAlign="center">
                      <DataTableCell
                        getContent={(r) => data.deliveryAdress}
                        style={styles.tabletxt}
                      />
                      
                    </TableBody>
                    
                  </Table>
                  <Table data={[{}]}>
                  <TableHeader>
                      <TableCell style={styles.title_cell}>
                        FECHA DE ENTREGA
                      </TableCell>
                  </TableHeader>
                  <TableBody textAlign="center">
                    <DataTableCell
                      getContent={(r) => data.deliveryDate}
                      style={styles.tabletxt}
                    />
                  </TableBody>  
                  </Table>
                  <Text style={{fontFamily: "Folks", fontSize: "14", marginTop: "20px"}}>Toda factura debe llevar el numero de la orden de compra asociado. <Text style={styles.bold}>OC {dataOp?.poId}</Text></Text>
                </View>
                <View style={{flex: 0.48}}>
                  <Table data={[0]}>
                    <TableHeader></TableHeader>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "VALOR"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      <DataTableCell
                        getContent={(r) =>  data?.total?.toLocaleString('de-DE') + ""}
                        weighting={0.7}
                        style={styles.subtabletxt}
                      />
                      
                    </TableBody>
                  </Table>
                  <Table data={[0]}>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "DESCUENTO"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      <DataTableCell
                        getContent={(r) => data?.discountValue?.toLocaleString('de-DE') + ""}
                        weighting={0.7}
                        style={styles.subtabletxt}
                      />
                    </TableBody>
                  </Table>
                  <Table data={[0]}>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "SUBTOTAL"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      <DataTableCell
                        getContent={(r) => data?.subTotal?.toLocaleString('de-DE')  + ""}
                        weighting={0.7}
                        style={styles.subtabletxt}
                      />
                    </TableBody>
                  </Table>
                  <Table data={[0]}>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "RETENCIÓN"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      <DataTableCell
                        getContent={(r) => data?.retention?.toLocaleString() + "%"}
                        weighting={0.2}
                        style={styles.subtabletxt}
                      />
                      <DataTableCell
                        getContent={(r) => data?.retentionValue?.toLocaleString('de-DE')  + ""}
                        weighting={0.5}
                        style={styles.subtabletxt}
                      />
                    </TableBody>
                  </Table>
                  <Table data={[0]}>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "IVA"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      <DataTableCell
                        getContent={(r) => data?.iva?.toLocaleString() + "%"}
                        weighting={0.2}
                        style={styles.subtabletxt}
                      />
                      <DataTableCell
                        getContent={(r) => data?.ivaValue?.toLocaleString('de-DE')  + ""}
                        weighting={0.5}
                        style={styles.subtabletxt}
                      />
                    </TableBody>
                  </Table>
                  <View style={{marginTop: "20px"}}>
                  <TableHeader></TableHeader>
                  <Table data={[0]}>
                    <TableBody >
                      <DataTableCell
                        getContent={(r) => "TOTAL"}
                        weighting={0.3}
                        style={styles.title_cell}
                      />
                      
                      <DataTableCell
                        getContent={(r) => data?.greatTotal?.toLocaleString('de-DE')  + ""}
                        weighting={0.7}
                        style={styles.subtabletxt}
                      />
                    </TableBody>
                  </Table>
                  </View>
                </View>
              </View>
              <View style={styles.obs_container}>
                <Text style={{fontFamily: "Folks", fontSize: "14", padding: "10px"}}><Text  style={{padding: "10px", ...styles.bold}}>OBSERVACIONES:</Text> {dataOp?.observations}</Text>

              </View>
            </View>
          </View>
        </View>
        
      </Page>
      </Document>
    </>
  );
}

function ViewPoPdf() {
  return (
    <div>
      <PDFDownloadLink document={<PoPdf />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
}

export { PoPdf, ViewPoPdf };
