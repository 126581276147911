import React from "react";
import "./Quote_pdf.css";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import bodegas from "../../../App/images/quote_2023/140ppi/bodegas.png";
import of_estandar from "../../../App/images/quote_2023/140ppi/of_estandar.png";
import proy_esp from "../../../App/images/quote_2023/140ppi/proy_esp.png";
import serv_log from "../../../App/images/quote_2023/140ppi/serv_log.png";
import mobi from "../../../App/images/quote_2023/140ppi/mobi.png";
import multi_cont from "../../../App/images/quote_2023/180ppi/multi_cont.png";
import fb_image from "../../../App/images/quote_2023/300ppi/fb_image.png";
import www_image from "../../../App/images/quote_2023/300ppi/www_image.png";
import inst_image from "../../../App/images/quote_2023/300ppi/inst_image.png";
import b_image from "../../../App/images/quote_2023/300ppi/b_image.png";
import youtube_image from "../../../App/images/quote_2023/300ppi/youtube_image.png";
import left_corner_triangle from "../../../App/images/quote_2023/300ppi/left_top_corner_triangle.png";
import right_middle_triangle from "../../../App/images/quote_2023/300ppi/right_middle_triangle.png";
import scenery_triangle from "../../../App/images/quote_2023/100ppi/scenery_triangle.png";
import main_green_logo from "../../../App/images/quote_2023/300ppi/main_green_logo.png";
import b_logo_blanco from "../../../App/images/quote_2023/300ppi/b_logo_blanco.png";
import email_icon from "../../../App/images/quote_2023/300ppi/email_icon.png";
import wap_icon from "../../../App/images/quote_2023/300ppi/wap_icon.png";
import footer_green from "../../../App/images/quote_2023/300ppi/footer_green.png";
import Folks from "../../../App/fonts/Folks-Normal.ttf";
import Folks_bold from "../../../App/fonts/Folks-Bold.ttf";
import Folks_heavy from "../../../App/fonts/Folks-Heavy.ttf";
import Arial_bold from "../../../App/fonts/arialbd.ttf";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
  TableRow,
} from "@david.kucsai/react-pdf-table";

function Quote_pdf(props) {
  console.log(props);
  const table_data = props.table_data;
  const subtotals = props.subtotals;
  const nro_cot = props.nro_cot;
  const cliente = props.cliente;
  const nit = props.nit;
  const fecha = props.fecha;
  const nombre_vendedor = props.nombre_vendedor;
  const celular = props.celular;
  const email = props.email;
  const observaciones = props.observaciones;
  const encabezado = props.encabezado;
  const contacto = props.contacto;
  const genero = props.genero;
  const cargo = props.cargo;

  Font.register({ family: "Folks", src: Folks });
  Font.register({ family: "Folks_bold", src: Folks_bold });
  Font.register({ family: "Arial_bold", src: Arial_bold });
  Font.register({ family: "Folks_heavy", src: Folks_heavy });

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Folks_bold",
    },

    bold_arial: {
      fontFamily: "Arial_bold",
    },

    header_texts: {
      fontFamily: "Folks",
      fontSize: 16,
      letterSpacing: 1.3,
    },

    h3: {
      color: "white",
      fontSize: 24,
      fontFamily: "Folks",
    },

    page: {
      backgroundColor: "white",
    },

    socialicons_container: {
      display: "flex",
      flexDirection: "row",
      width: "80%",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: 15,
    },

    txt: {
      fontSize: 15,
      fontFamily: "Helvetica",
    },

    headertxt: {
      fontSize: 12,
      fontFamily: "Arial_bold",
    },

    tabletxt: {
      fontSize: 12,
    },

    table: {
      marginTop: 25,
      marginBottom: 25,
    },

    subtotals_container: {
      marginBottom: 25,
    },

    flex_subtotal_label: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      marginBottom: 15,
    },

    subtotallbl: {
      flex: 0.8,
      fontFamily: "Arial_bold",
      fontSize: 14,
      textAlign: "right",
      display: "flex",
      alignItems: "center",
    },

    subtotalmount: {
      flex: 0.2,
      textAlign: "right",
      fontSize: 14,
    },

    position: {
      fontFamily: "Folks",
      color: "white",
      letterSpacing: 1,
      marginBottom: 5,
      fontSize: 15,
    },

    left_corner_triangle_img: {
      position: "absolute",
      height: 673,
      width: 329,
      top: -335,
      left: -50,
    },
    text_left_corner_cotizacion_container: {
      position: "absolute",
      top: 35,
      left: 25,
    },
    text_left_corner_cotnumber_container: {
      position: "absolute",
      top: 85,
      left: 25,
    },
    main_green_logo_img: {
      position: "absolute",
      height: 97.5,
      width: 166.5,
      top: 40,
      left: 320.4,
    },
    main_logo_text_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 160,
      left: 276,
    },
    main_header_data_container: {
      position: "absolute",
      top: 310,
      left: 30,
    },
    right_middle_triangle_img: {
      position: "absolute",
      height: 720,
      width: 292.3,
      right: -12,
      top: -15,
    },
    text_middle_triangle_container: {
      position: "absolute",
      right: 30,
      top: 275,
    },
    scenery_triangle_img: {
      position: "absolute",
      height: 720,
      width: 292.3,
      left: -10,
      top: 475,
    },
    b_logo_blanco_img: {
      height: 110,
      width: 68,
    },
    text_b_logo_blanco: {
      color: "white",
      fontSize: 24,
      lineHeight: 1.1,
      fontFamily: "Folks_bold",
      left: 20,
      top: 670,
    },
    margin_quote_data_container: {
      width: "95%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    full_width_container: {
      // position: "absolute",
      // top: 918,
      width: "100%",
    },
    greetings_container: {
      paddingTop: "25px",
      paddingBottom: "25px",
      boxSizing: "border-box",
    },
    h1: {
      color: "white",
      fontSize: 40,
      fontFamily: "Folks_bold",
    },
    sub_h1: {
      color: "white",
      fontSize: 26,
      fontFamily: "Folks_bold",
    },
    h3: {
      color: "white",
      fontSize: 30,
      fontFamily: "Folks",
    },
    green: {
      color: "#006E42",
    },
    main_header_title: {
      fontFamily: "Folks_bold",
      fontSize: 19,
      letterSpacing: 2.5,
    },
    header_text: {
      fontFamily: "Folks",
      fontSize: 16,
      letterSpacing: 1.3,
    },
    central_flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    justify_central_flex: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    diagonal_images_text: {
      position: "absolute",
      fontFamily: "Folks",
      fontSize: 23,
    },
    account_data_title: {
      fontFamily: "Folks_bold",
      letterSpacing: 3,
      fontSize: 22,
      marginBottom: 3,
    },
    account_data_text: {
      fontFamily: "Folks",
      letterSpacing: 3,
      fontSize: 15,
      marginBottom: 6,
    },
  });

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  return (
    <>
      <Document>
        <Page size={[816]} style={styles.page} wrap={false}>
          <View style={{ minHeight: 920 }}>
            <Image
              src={left_corner_triangle}
              style={styles.left_corner_triangle_img}
            />
            <View style={styles.text_left_corner_cotizacion_container}>
              <Text style={styles.h1}>Cotización</Text>
            </View>
            <View style={styles.text_left_corner_cotnumber_container}>
              <Text style={styles.h3}>N°{nro_cot}</Text>
            </View>
            <Image src={main_green_logo} style={styles.main_green_logo_img} />
            <View style={styles.main_logo_text_container}>
              <Text
                style={[
                  styles.green,
                  {
                    fontFamily: "Folks_bold",
                    fontSize: 13,
                    letterSpacing: 1.5,
                  },
                ]}
              >
                CONTENEDORES DE ANTIOQUIA S.A.S
              </Text>
              <Text
                style={[
                  styles.header_texts,
                  styles.green,
                  { marginVertical: 6.6 },
                ]}
              >
                NIT 900.167.316-4
              </Text>
              <Text
                style={[styles.header_texts, styles.green, { marginBottom: 3 }]}
              >
                Km 1 entrada a Girardota
              </Text>
              <Text style={[styles.header_texts, styles.green]}>
                Girardota - Colombia
              </Text>
              <View style={styles.socialicons_container}>
                <LinkPDF
                  src={"https://www.facebook.com/ContenedoresdeAntioquia"}
                  style={{ display: "block" }}
                >
                  <Image src={fb_image} style={{ height: 20, width: 10.3 }} />
                </LinkPDF>
                <LinkPDF
                  src={"https://www.instagram.com/contenedores_ant/"}
                  style={{ display: "block" }}
                >
                  <Image
                    src={inst_image}
                    style={{
                      height: 17,
                      width: 16.3,
                    }}
                  />
                </LinkPDF>
                <LinkPDF
                  src={"https://www.contenedoresdeantioquia.com/"}
                  style={{ display: "block" }}
                >
                  <Image
                    src={www_image}
                    style={{
                      height: 18.1,
                      width: 18.1,
                    }}
                  />
                </LinkPDF>
                <LinkPDF
                  src={"https://www.sistemab.org/"}
                  style={{ display: "block" }}
                >
                  <Image
                    src={b_image}
                    style={{
                      height: 21,
                    }}
                  />
                </LinkPDF>
                <LinkPDF
                  src={
                    "https://www.youtube.com/channel/UCIIrJuxjUPfITWhT8HzrZjA"
                  }
                  style={{ display: "block" }}
                >
                  <Image
                    src={youtube_image}
                    style={{
                      height: 14.5,
                      width: 20.9,
                    }}
                  />
                </LinkPDF>
              </View>
            </View>
            <Image
              src={right_middle_triangle}
              style={styles.right_middle_triangle_img}
            />
            <View
              style={[styles.text_middle_triangle_container, { width: 180 }]}
            >
              <Text
                style={[styles.sub_h1, { lineHeight: 1, letterSpacing: 0.01 }]}
              >
                Da click sobre las imágenes y conoce más sobre nuestros
                servicios
              </Text>
            </View>
            <View style={styles.main_header_data_container}>
              <View style={[styles.central_flex, { marginBottom: 7 }]}>
                <Text
                  style={[
                    styles.main_header_title,
                    styles.green,
                    { width: 80 },
                  ]}
                >
                  Para:
                </Text>
                <Text
                  style={[
                    styles.header_text,
                    styles.green,
                    {
                      maxWidth: 430,
                      marginTop: 1.5,
                    },
                  ]}
                >
                  {cliente}
                </Text>
              </View>
              <View style={[styles.central_flex, { marginBottom: 7 }]}>
                <Text
                  style={[
                    styles.main_header_title,
                    styles.green,
                    { width: 80 },
                  ]}
                >
                  NIT:
                </Text>
                <Text
                  style={[
                    styles.header_text,
                    styles.green,
                    {
                      maxWidth: 437,
                      marginTop: 1.5,
                    },
                  ]}
                >
                  {nit}
                </Text>
              </View>
              <View style={[styles.central_flex]}>
                <Text
                  style={[
                    styles.main_header_title,
                    styles.green,
                    { width: 80 },
                  ]}
                >
                  Fecha:
                </Text>
                <Text
                  style={[
                    styles.header_text,
                    styles.green,
                    {
                      maxWidth: 437,
                      marginTop: 1.5,
                    },
                  ]}
                >
                  {fecha}
                </Text>
              </View>
            </View>
            <Image src={scenery_triangle} style={styles.scenery_triangle_img} />
            <LinkPDF
              src={"https://www.sistemab.org/"}
              style={[
                {
                  position: "absolute",
                  left: 50,
                  top: 770,
                },
              ]}
            >
              <Image src={b_logo_blanco} style={styles.b_logo_blanco_img} />
            </LinkPDF>
            <Text style={[styles.text_b_logo_blanco]}>
              Somos una{"\n"}empresa B{"\n"}certificada
            </Text>
            <Text
              style={[
                styles.diagonal_images_text,
                styles.green,
                { top: 490, left: 170 },
              ]}
            >
              Bodegas
            </Text>
            <LinkPDF
              src={
                "https://www.contenedoresdeantioquia.com/contenedor-20-pies-venta-y-alquiler"
              }
              style={[
                {
                  position: "absolute",
                  top: 390,
                  left: 270,
                },
              ]}
            >
              <Image
                src={bodegas}
                style={[
                  {
                    height: 144,
                    width: 199,
                  },
                ]}
              />
            </LinkPDF>
            <Text
              style={[
                styles.diagonal_images_text,
                styles.green,
                { top: 570, left: 230 },
              ]}
            >
              Oficinas{"\n"}Estandar
            </Text>
            <LinkPDF
              src={
                "https://www.contenedoresdeantioquia.com/contenedor-20-pies-venta-y-alquiler"
              }
              style={[
                {
                  position: "absolute",
                  top: 474,
                  left: 340,
                },
              ]}
            >
              <Image
                src={of_estandar}
                style={[
                  {
                    height: 144,
                    width: 199,
                  },
                ]}
              />
            </LinkPDF>
            <Text
              style={[
                styles.diagonal_images_text,
                styles.green,
                { top: 655, left: 300 },
              ]}
            >
              Proyectos{"\n"}especiales
            </Text>
            <LinkPDF
              src={
                "https://www.contenedoresdeantioquia.com/proyectos-con-contenedores-maritimos-casas-restaurante-hoteles-beteria-de-ba%C3%B1os-oficinas-habita"
              }
              style={[
                {
                  position: "absolute",
                  top: 566,
                  left: 400,
                },
              ]}
            >
              <Image
                src={proy_esp}
                style={[
                  {
                    height: 134,
                    width: 201,
                  },
                ]}
              />
            </LinkPDF>
            <Text
              style={[
                styles.diagonal_images_text,
                styles.green,
                { top: 735, left: 390 },
              ]}
            >
              Servicio{"\n"}Lógistico
            </Text>
            <LinkPDF
              src={
                "https://www.contenedoresdeantioquia.com/cami%C3%B3n-gr%C3%BAa-servicio-log%C3%ADstico-a-nivel-nacional"
              }
              style={[
                {
                  position: "absolute",
                  top: 660,
                  left: 500,
                },
              ]}
            >
              <Image
                src={serv_log}
                style={[
                  {
                    height: 130,
                    width: 182,
                  },
                ]}
              />
            </LinkPDF>
            <Text
              style={[
                styles.diagonal_images_text,
                styles.green,
                { top: 835, left: 465 },
              ]}
            >
              Mobiliario
            </Text>
            <LinkPDF
              src={
                "https://www.contenedoresdeantioquia.com/mobiliario-venta-y-alquiler-escritorio-repisa-puesto-de-trabajo-mesa-de-reuniones-comedor"
              }
              style={[
                {
                  position: "absolute",
                  top: 735,
                  left: 570,
                },
              ]}
            >
              <Image
                src={mobi}
                style={[
                  {
                    height: 182,
                    width: 200,
                  },
                ]}
              />
            </LinkPDF>
          </View>
          <View
            style={[
              styles.full_width_container,
              { minHeight: 275, backgroundColor: "white" },
            ]}
          >
            <View style={{ backgroundColor: "#EFEFEF" }}>
              <View style={styles.margin_quote_data_container}>
                <View style={styles.greetings_container}>
                  <View>
                    <Text style={[styles.txt, { fontWeight: "bold" }]}>
                      Buen día {genero === "Femenino" ? "Sra." : "Sr."}{" "}
                      {contacto}
                      {","}
                    </Text>
                    <Text style={styles.txt}> </Text>
                    <Text style={styles.txt}>{encabezado}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ backgroundColor: "white" }}>
              <View style={styles.margin_quote_data_container}>
                <View style={styles.table}>
                  <Table data={table_data}>
                    <TableHeader textAlign={"center"}>
                      <TableCell weighting={0.05} style={styles.headertxt}>
                        Ítem
                      </TableCell>
                      <TableCell
                        weighting={0.3}
                        style={[{ padding: 5 }, styles.headertxt]}
                      >
                        Descripción
                      </TableCell>
                      <TableCell weighting={0.08} style={styles.headertxt}>
                        Cantidad
                      </TableCell>
                      <TableCell weighting={0.15} style={styles.headertxt}>
                        Valor Unitario
                      </TableCell>
                      <TableCell weighting={0.2} style={styles.headertxt}>
                        Valor Bruto
                      </TableCell>
                      <TableCell weighting={0.15} style={styles.headertxt}>
                        Valor Descuento
                      </TableCell>
                      <TableCell weighting={0.07} style={styles.headertxt}>
                        IVA
                      </TableCell>
                    </TableHeader>
                    <TableBody textAlign={"center"}>
                      <DataTableCell
                        getContent={(row) => row.no}
                        weighting={0.05}
                        style={styles.tabletxt}
                      />
                      <DataTableCell
                        getContent={(row) => row.descripcion}
                        weighting={0.3}
                        style={[
                          styles.tabletxt,
                          { textAlign: "left", padding: 5 },
                        ]}
                      />
                      <DataTableCell
                        getContent={(row) => row.cant}
                        weighting={0.08}
                        style={styles.tabletxt}
                      />
                      <DataTableCell
                        getContent={(row) => toCurrency(row.valorUnit)}
                        weighting={0.15}
                        style={styles.tabletxt}
                      />
                      <DataTableCell
                        getContent={(row) => toCurrency(row.valorBruto)}
                        weighting={0.2}
                        style={styles.tabletxt}
                      />
                      <DataTableCell
                        getContent={(row) =>
                          toCurrency(row.valorBruto * (row.dcto / 100))
                        }
                        weighting={0.15}
                        style={styles.tabletxt}
                      />
                      <DataTableCell
                        getContent={(row) => row.iva.toString() + " %"}
                        weighting={0.07}
                        style={styles.tabletxt}
                      />
                    </TableBody>
                  </Table>
                </View>
                <View style={styles.subtotals_container}>
                  <View style={styles.flex_subtotal_label}>
                    <Text style={styles.subtotallbl}>Total Bruto</Text>
                    <Text style={styles.subtotalmount}>
                      {toCurrency(subtotals.superBruto)}
                    </Text>
                  </View>
                  {subtotals.superDescuento === 0 ? null : (
                    <View style={styles.flex_subtotal_label}>
                      <Text style={styles.subtotallbl}>Total Descuento</Text>
                      <Text style={styles.subtotalmount}>
                        {toCurrency(subtotals.superDescuento)}
                      </Text>
                    </View>
                  )}
                  <View style={styles.flex_subtotal_label}>
                    <Text style={styles.subtotallbl}>Subtotal</Text>
                    <Text style={styles.subtotalmount}>
                      {toCurrency(subtotals.superSubtotal)}
                    </Text>
                  </View>
                  <View style={styles.flex_subtotal_label}>
                    <Text style={styles.subtotallbl}>IVA</Text>
                    <Text style={styles.subtotalmount}>
                      {toCurrency(subtotals.superIva)}
                    </Text>
                  </View>
                  <View style={styles.flex_subtotal_label}>
                    <Text style={styles.subtotallbl}>Total a Pagar</Text>
                    <Text style={[styles.subtotalmount, styles.bold_arial]}>
                      {toCurrency(subtotals.superTotal)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <Image
              src={multi_cont}
              style={[
                {
                  height: 552,
                  width: 816,
                },
              ]}
            />
            <View style={[styles.justify_central_flex, { marginTop: 60 }]}>
              <Text style={[styles.account_data_title, styles.green]}>
                {nombre_vendedor.toUpperCase()}
              </Text>
              <Text style={[styles.account_data_text, styles.green]}>
                {cargo}
              </Text>
              <View style={[styles.central_flex, { alignItems: "flex-start" }]}>
                <Image
                  src={wap_icon}
                  style={[
                    {
                      height: 15.9,
                      width: 15.8,
                      marginRight: 8,
                    },
                  ]}
                />
                <Text
                  style={[
                    styles.account_data_text,
                    styles.green,
                    { letterSpacing: 6.5 },
                  ]}
                >
                  {celular}
                </Text>
              </View>
              <View style={[styles.central_flex, { alignItems: "flex-start" }]}>
                <Image
                  src={email_icon}
                  style={[
                    {
                      height: 14.8,
                      width: 21.2,
                      marginRight: 8,
                    },
                  ]}
                />
                <Text
                  style={[
                    styles.account_data_text,
                    styles.green,
                    { letterSpacing: 1.5 },
                  ]}
                >
                  {email}
                </Text>
              </View>
            </View>
            <View style={{ marginTop: -10 }}>
              <Image
                src={footer_green}
                style={[
                  {
                    height: 482,
                    width: 821,
                  },
                ]}
              />
              <Text
                style={{
                  marginTop: "20px",
                  fontFamily: "Folks",
                  color: "white",
                  fontSize: 14.5,
                  textAlign: "justify",
                  position: "absolute",
                  width: "95%",
                  left: 20.5,
                  top: 160,
                  lineHeight: 1.45,
                }}
              >
                {/* Nosotros prestamos un servicio logístico. Cotización valida por 15 días. 
                <Text style={styles.bold}> ALQUILER: </Text>La forma de pago es mes anticipado, al inicio se cobra un canon adicional correspondiente al 70% del valor del alquiler (Deposito de Garantías), monto que será devuelto en el momento que se devuelvan los ítems alquilados en las mismas condiciones en las que fueron entregados. 
                <Text style={styles.bold}> VENTA: </Text> Se debe hacer un anticipo del 60% para dar inicio a las adecuaciones que sean requeridas, y se debe pagar el monto restante previo a la entrega, de lo contrario el contenedor no podrá ser despachado. 
                <Text style={styles.bold}> TRANSPORTE: </Text>El valor estipulado en esta cotización se mantendrá si y solo si se recibe el contenedor dentro del plazo de tiempo establecido y acordado, en caso de haber retrasos en la recepción de éste, y se generen sobre costos, se deberá hacer un ajuste en el cobro del transporte desde $70.000 hasta $180.000 (dependiendo del tipo de transporte) por cada hora adicional. 
                <Text style={styles.bold}> ALMACENAMIENTO: </Text>En caso de que el cliente no pueda recibir el producto o proyecto en los tiempos establecidos, se le darán 5 días corridos para recibirlo como gabela. Cumplido este periodo de tiempo se le cobrará el espació en patios correspondiente a una tarifa que va desde los $15.000 hasta los $30.000 por día de almacenamiento, dependiendo del tamaño. 
                <Text style={styles.bold}> CONDICIONES: </Text>En caso de retractarse de algún contrato o pago generado, y de solicitar una devolución, se descontará el 5% sobre el pago hecho, por gastos administrativos. Esta cotización<Text style={styles.bold}> NO</Text> incluye póliza, en caso de ser requerida se le sumará a la cotización. Sujetos a disponibilidad. */}
                {observaciones}
              </Text>
              <Image
                src={main_green_logo}
                style={[
                  {
                    height: 48.3,
                    width: 81.6,
                    position: "absolute",
                    top: 35,
                    left: 369.7,
                  },
                ]}
              />
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

function View_pdf() {
  return (
    <div>
      <PDFDownloadLink document={<Quote_pdf />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
}

export { Quote_pdf, View_pdf };
