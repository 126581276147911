import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { Switch, Route, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faDollyFlatbed, faFileImport, faTruckLoading } from "@fortawesome/free-solid-svg-icons";


function Inputs() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  return (
    <div className="container__clients">
      <div className="img__icon">
        <FontAwesomeIcon
            icon={faTruckLoading}
            color="#266e45"
            style={{fontSize: "100px", marginBottom: "15px"}}
          />
        <h2>Ingresos</h2>
      </div>
      <div
        className="simple_flex_"
        style={{
          width: "80%",
          marginTop: "15px",
          justifyContent: "center"
        }}
      >
        <div className="square__button"  style={{marginRight: "100px"}} onClick={() => (history.push(`${path}/po`))}>
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faDollyFlatbed}
            color="white"
          />
          <h4 className="white" style={{textAlign: "center", marginTop: "4px"}}>Ordenes de Compra</h4>
        </div>
        <div className="square__button" onClick={() => (history.push(`${path}/return`))}>
          <FontAwesomeIcon
            className="icon__clientshome"
            icon={faArrowCircleLeft}
            color="white"
          />
          <h4 className="white">Devolución</h4>
        </div>
      </div>
    </div>
  );
}

export default Inputs;
