import React, { useState, useEffect } from "react";
import { response_get_mock, response_mock_get_suppliers } from "./responses_mock";
import fetch_backend from "../../../App/components/shared/Fetch";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
    styles_react_select, styles_react_select_cost_centers
  } from "../../../App/components/shared/Select";
import { useCookies } from "react-cookie";

function ModalAddSupplier({ open, onClose, onCloseSuccess, existingSuppliers, groupId }) {
    const [json_response, setJson_response] = useState();
    const [isLoading, setIsLoading] = useState();
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const [optionsToShow, setOptionsToShow] = useState();
    const [selectedSupplier, setSelectedSupplier] = useState();
    const [cookies] = useCookies();
    const token = cookies.token_purchase;

    useEffect(() => {
        send_init_request();
        }, []);
    
    async function send_init_request() {
        setIsLoading(true);
        var url;
        url = `suppliers/`;
        var response = await fetch_backend(url, "GET", token, dispatch);
        // TODO: DELETE
        // var response = response_mock_get_suppliers;
        setJson_response(response);
        buildOptions(response)
        setIsLoading(false);
        }

    const buildOptions = (response) => {
        let options = {};
        let suppliers = []
    
        for (const supplier of response?.suppliers) {
            const existingSupplier = existingSuppliers?.find(suppl => suppl.id === supplier.id)
            if (!existingSupplier){
                suppliers.push({ value: supplier.id, label: supplier.name });
            }
        }

        options.suppliers = suppliers
        setOptionsToShow(options)
    }
    
    const sendAddSuplRequest = async () => {
        if (!selectedSupplier){
            return
        }
        setIsLoading(true);
        const response = await fetch_backend(
            `order_manager/`,
            "POST",
            token,
            dispatch,
            {supplier_id: selectedSupplier.value, group_id: groupId}
        );

        setIsLoading(false);
        if (response.status === 200) {
            onCloseSuccess({
                id: selectedSupplier.value,
                name: selectedSupplier.label
            })
        }
    }


  return (
    <div>
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "20px" }}>Seleccione el proveedor que desea agregar</p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleccione..."
            isSearchable={true}
            menuPosition="fixed"
            options={optionsToShow?.suppliers}
            styles={styles_react_select}
            onChange={(value) => setSelectedSupplier(value)}
          />

          <button
            style={{
              padding: "3px 15px",
              margin: "20px auto 0 auto",
            }}
            onClick={() => sendAddSuplRequest()}
          >
            Aceptar
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalAddSupplier;
