import { mockRequestInputs } from "./mockRequest";
import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { Switch, Route, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faArrowCircleLeft, faFileImport, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";
import ModalAddInput from "./ModalAddInput"

function InputsPo() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [cookies] = useCookies();
  const token = cookies.token_purchase;

  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filterComboValue, setFilterComboValue] = useState("");
  const [openModalAddInput, setOpenModalAddInput] = useState(false);


  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    sendInitRequest()
  }, []);

  async function sendInitRequest() {
    setIsLoading(true);
    var url;
    url = `stock-inputs/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = mockRequestInputs
    setJson_response(response);
    setBackupJson_response(response);
    setIsLoading(false);
  }

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.inputs];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "expected_date" && sub !== "date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });
    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    if (!sortAscen) {
      sorted.reverse();
    }
    setJson_response({ ...json_response, inputs: sorted });
  };

  const handleSearch = (value) => {
    let newList = [...backupJson_response.inputs];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.supplier.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response({ ...json_response, inputs: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const onFocusSearch = () => {
    setBackupJson_response(json_response);
  };

  const comboFilter = (e) => {
    const value = e.target.value;
    if (filterComboValue === value) {
      setFilterComboValue("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterComboValue(value);
    var newList = [...backupJson_response.inputs];
    newList = newList.filter((row) => row.status === value);
    setJson_response({ ...json_response, inputs: newList });
  };

  const callBackInputAddModal = (e) => {
    setOpenModalAddInput(false)
  }



  return (
    <div className="wrapped__container_top_bar ">
      <Loading isLoading={isLoading} />
      <ModalAddInput
        open={openModalAddInput}
        onClose={()=>setOpenModalAddInput(false)}
        callback={callBackInputAddModal}
      />
      <div className="top-bar">
        <h3>Ingresos</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={() => onFocusSearch()}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides_tools">
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="unmanaged"
              className="inputselect unmanaged"
              checked={filterComboValue === "unmanaged"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />
            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              Sin gestionar
            </p>
          </div>
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="in_process"
              className="inputselect in_process"
              checked={filterComboValue === "in_process"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />
            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              En proceso
            </p>
          </div>
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="managed"
              className="inputselect managed"
              checked={filterComboValue === "managed"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />

            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              &nbsp;Gestionado
            </p>
          </div>
          <div className="sub_guide_tools">
              <div className="square_plus" onClick={() => setOpenModalAddInput(true)}>
                <h3 className="col_white">+</h3>
              </div>
            </div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th style={{ width: "3%" }} />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="No. OC"
              txt_filter="po_id"
              width="14%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Proveedor"
              txt_filter="supplier"
              width="25%"
            />
            {/* <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Orden de Trabajo"
              txt_filter="work_order"
              width="13%"
            /> */}
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha de Creación"
              txt_filter="date"
              width="13%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha Llegada"
              txt_filter="expected_date"
              width="20%"
            />
            <TableHeader col_name="Ingresar" width="8%" />
          </tr>
          {json_response?.inputs?.map((row, index) => (
            <>
              <tr className="rowTasks">
              <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{
                        backgroundColor: `${
                          row.status === "unmanaged"
                            ? "red"
                            : row.status === "managed"
                            ? "green"
                            : "blue"
                        }`,
                      }}
                    />
                  </div>
                </td>
                <td>{row.po_id}</td>
                <td>{row.supplier}</td>
                {/* <td>{row.work_order_id}</td> */}
                <td>{row.date}</td>
                <td>{row.expected_date}</td>
                <td>
                <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    color="#383838"
                    className="iconRow"
                    onClick={() => (history.push(`${path}/${row.po_id}`))}
                  />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </div>
  )
}

export default InputsPo