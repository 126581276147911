import _ from "lodash";
import {mock_request_get, mock_request_get_edit} from "./mock_request"
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import { CountryDropdown } from "react-country-region-selector";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import { useParams } from "react-router-dom";
import { Cities } from "../../../App/services/services";
import { styles_react_select_branches as styles_react_select } from "../../../App/components/shared/Select";
import Select from "react-select";

function Branches() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [sortAscen, setSortAscen] = useState();
  const [cookies] = useCookies();

  const { editBranchId } = useParams();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [optionsToShow, setOptionsToShow] = useState();
  const [jsonResponse, setJsonResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request();
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    if (editBranchId) {
      url = `branches/${editBranchId}/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
    //   var response = mock_request_get_edit
      setJsonResponse(response.branch);
    } else {
        url = `suppliers/`;
        var response = await fetch_backend(url, "GET", token, dispatch);
        setJsonResponse({country: "Colombia", main_branch: false});
    }
    buildOptions(response)
    setIsLoading(false);
  }

  const inputChange = (target) => {
    const jsonCopy = { ...jsonResponse };
    jsonCopy[target.name] = target.value;
    setJsonResponse(jsonCopy);
  };

  const buildOptions = (response) => {
    let options = {};
    let suppliers = []
    let contacts = []
    
    for (const supplier of response.suppliers) {
        suppliers.push({ value: supplier.id, label: supplier.name });
    }

    if (response.contacts){

      for (const contact of response.contacts) {
          contacts.push({ value: contact.id, label: contact.name });
      }
    }

    options.suppliers = suppliers
    options.contacts = contacts
    setOptionsToShow(options)
} 


  const validateRequiredFields = (allValues) => {
    const errors = {};
    
    if (!allValues.branch.country) {
      errors.country = `Falta seleccionar el campo 'Pais'`;
    }
    if (!allValues.branch.city) {
      errors.city = `Falta diligenciar el campo 'Ciudad'`;
    }
    if (!allValues.branch.address) {
      errors.address = `Falta diligenciar el campo 'Dirección'`;
    }
    if (!allValues.branch.phone_indicator) {
      errors.phone_indicator = `Falta diligenciar el campo 'Indicativo Teléfono'`;
    }
    if (!allValues.branch.phone) {
      errors.phone = `Falta diligenciar el campo 'Teléfono'`;
    }
       

    return errors;
  };

  const saveBranch = async () => {
    const body = {
      branch: jsonResponse,
    };
    console.log(body);
     const errors = validateRequiredFields(body)
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }

    let url;
    let method;

    if (editBranchId) {
      url = `branches/${editBranchId}/`;
      method = "PUT";
    } else {
      url = `branches/`;
      method = "POST";
    }

    const response = await fetch_backend(url, method, token, dispatch, body);

    setIsLoading(false);
    if (response.status === 200) {
      setSuccessModalForm(true);
      setMessageModalForm(["Proveedor creado con éxito"]);
      setOpenModalForm(true);
    }
  };

  const getReactSelectValue = (key, search_by) => {
    var option
    if(optionsToShow){
        option = optionsToShow[key].find(option => option.value == jsonResponse[search_by])
    }
    return option
  }


  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={() => {
          setOpenModalForm(false);
          if (successModalForm === true){
              history.goBack();
          }
        }}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />

      <div style={{ margin: "15px", width: "100%", maxWidth: "1300px" }}>
        <img
          src={require("../../../App/images/branch.svg")}
          alt=""
          className="img__factory__addclient"
          style={{ marginBottom: "10px" }}
        />
        <h4>Agregar Sucursal</h4>

        
        <div
          className="simple_flex_"
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <div name="col1" style={{ flex: 0.3 }}>
          <div name="supplier" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Razón Social</p>
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  options={optionsToShow?.suppliers}
                  styles={styles_react_select}
                  onChange={(value) => inputChange({value: value.value, name: "supplier_id"})}
                  value={getReactSelectValue("suppliers", "supplier_id")}
                />
            </div>
          
            <div name="country" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Pais</p>
              <CountryDropdown
                value={jsonResponse?.country}
                onChange={(value) =>
                  inputChange({ name: "country", value: value })
                }
                classes="countryDropdown"
                defaultOptionLabel="Seleccione..."
              />
            </div>
            <div name="city" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Ciudad</p>
              <input
                type="text"
                name="city"
                list="ciudades"
                value={jsonResponse?.city}
                onChange={(e) => inputChange(e.target)}
              />
              {jsonResponse?.country === "Colombia" ? (
                <Cities id="ciudades" />
              ) : null}
            </div>
            <div name="address" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Dirección</p>
              <input
                type="text"
                name="address"
                value={jsonResponse?.address}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
            <div
              name="telefono"
              className="simple_flex_"
              style={{ justifyContent: "space-between", marginBottom: "10px" }}
            >
              <div style={{ flex: 0.2 }}>
                <p className="labeltag">Indic.</p>
                <input
                  className="my_input"
                  type="number"
                  name="phone_indicator"
                  value={jsonResponse?.phone_indicator}
                  onChange={(e) => inputChange(e.target)}
                />
              </div>
              <div style={{ flex: 0.7 }}>
                <p className="labeltag">Teléfono</p>
                <input
                  className="my_input"
                  type="number"
                  name="phone"
                  value={jsonResponse?.phone}
                  onChange={(e) => inputChange(e.target)}
                />
              </div>
            </div>
            <div name="contact" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Contacto Asociado</p>
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  options={optionsToShow?.contacts}
                  styles={styles_react_select}
                  onChange={(value) => inputChange({value: value.value, name: "contact_id"})}
                  value={getReactSelectValue("contacts", "contact_id")}
                />
            </div>
            <div name="main_branch" style={{marginBottom: "10px"}}>
                    <div className="simple_flex_" style={{height: "27px"}}>
                            <input
                            className="inputRadio__addclient"
                            type="checkbox"
                            name="main_branch"
                            checked={jsonResponse?.main_branch}
                            onChange={(e) => inputChange({value: e.target.checked, name: e.target.name})}
                            />
                            <label for="main_branch" style={{marginRight: "10px"}}>Establecer como sede principal</label>
                    </div>     
                </div>
          </div>
          
          <div name="col2" style={{ flex: 0.3 }}>
            
            
          </div>
          <div name="col3" style={{ flex: 0.3 }}>
            
            
          </div>
        </div>

        <button
          style={{
            padding: "3px 15px",
            margin: 0,
          }}
          className="button"
          onClick={() => saveBranch()}
        >
          Guardar
        </button>
      </div>
    </>
  );
}

export { Branches };
