import React, { useState, useEffect } from "react";
import {
  response_get_mock,
  response_mock_get_suppliers,
} from "./responses_mock";
import _ from "lodash";
import fetch_backend from "../../../App/components/shared/Fetch";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { ModalForm } from "../../../App/components/shared/Modals";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  styles_react_select,
  styles_react_select_cost_centers,
} from "../../../App/components/shared/Select";
import { useCookies } from "react-cookie";

function ModalPoData({ open, onClose, onCloseAccept, baseExtraPoData }) {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [optionsToShow, setOptionsToShow] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [extraPoData, setExtraPoData] = useState({...baseExtraPoData, discount: "0", retention: "2.5", iva: "19", delivery_address: "Km 1 Entrada Girardota EDS Zeuss Coralinas"});
  const [cookies] = useCookies();
  const token = cookies.token_purchase;

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setExtraPoData({ ...extraPoData, [name]: value });
  };

  const validateRequiredFields = (allValues) => {
    const errors = {};
   
    
    if (!allValues.payment_method) {
        errors.payment_method = `Falta diligenciar el campo 'Forma de Pago'`;
    }
    if (!allValues.payment_term) {
        errors.payment_term = `Falta diligenciar el campo 'Plazo'`;
    }
    if (!allValues.delivery_address) {
        errors.delivery_address = `Falta diligenciar el campo 'Dirección de Entrega'`;
    }
    if (!allValues.delivery_date) {
        errors.delivery_date = `Falta diligenciar el campo 'Fecha de Entrega'`;
    }
    if (!allValues.discount) {
      errors.discount = `Falta diligenciar el campo 'Descuento'`;
    }
    if (!allValues.retention) {
      errors.retention = `Falta diligenciar el campo 'Retención'`;
    }
    if (!allValues.iva) {
      errors.iva = `Falta diligenciar el campo 'Iva'`;
    }

  

    return errors;
  };

  const generateOrder = () => {
    const errors = validateRequiredFields(extraPoData)
    if (!_.isEmpty(errors)) {
        setSuccessModalForm(false);
        setMessageModalForm(errors);
        setOpenModalForm(true);
        return;
      }
    
    onCloseAccept(extraPoData)
      
  }

  return (
    <div>
      <ModalForm
        open={openModalForm}
        onClose={() => 
            setOpenModalForm(false)
         }
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
      >
        <DialogContent style={{ textAlign: "center" }}>
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h4>Orden de Compra</h4>
        </DialogTitle>
          <div className="simple_flex_" style={{marginTop: "10px"}}>
            <p className="label_input_modal" style={{maxWidth: "80px", textAlign: "left"}}> Proveedor: </p>
            <p className="label_input_modal" style={{ fontWeight: "bold", textAlign: "left" }}>{extraPoData?.name}</p>
          </div>
          <div className="simple_flex_" style={{justifyContent: "space-between"}}>

            <div style={{flex: 0.47}}>
              <div className="simple_flex_">
                <p className="label_input_modal" style={{minWidth: "100px", textAlign: "left"}}>Forma de pago </p>
                <input
                  type="text"
                  style={{ margin: "10px 0 15px 3px" }}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="payment_method"
                  value={extraPoData?.payment_method}
                />
              </div>
              <div className="simple_flex_">
            <p className="label_input_modal" style={{minWidth: "100px"}}>Plazo</p>
            <input
              type="text"
              style={{ margin: "10px 0 15px 3px" }}
              className="input_modal"
              required
              onChange={(e) => ChangeInput(e)}
              name="payment_term"
              value={extraPoData?.payment_term}
            />
              </div>
              <div className="simple_flex_">
                <p className="label_input_modal" style={{minWidth: "100px"}}>Fecha de Entrega</p>
                <input
                  type="date"
                  style={{ margin: "10px 0 15px 0" }}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="delivery_date"
                  value={extraPoData?.delivery_date}
                />
              </div>
              <div className="simple_flex_">
                <p className="label_input_modal" style={{minWidth: "100px"}}>Direccion de Entrega</p>
                <input
                  type="text"
                  style={{ margin: "10px 0 15px 3px " }}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="delivery_address"
                  value={extraPoData?.delivery_address}
                />
              </div>
             
            </div>
           
            <div style={{flex: 0.47, display: "flex", flexDirection: "column", justifyContent: "flex-start", minHeight: "200px"}}>
              <div className="simple_flex_" >
                <p className="label_input_modal" style={{minWidth: "100px"}}>Descuento (%)</p>
                <input
                  type="number"
                  style={{ margin: "10px 0 15px 3px", maxWidth: "45px"}}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="discount"
                  value={extraPoData?.discount}
                />
              </div>
              <div className="simple_flex_" >
                <p className="label_input_modal" style={{minWidth: "100px"}}>Retención (%)</p>
                <input
                  type="number"
                  style={{ margin: "10px 0 15px 3px", maxWidth: "45px" }}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="retention"
                  value={extraPoData?.retention}
                />
              </div>
              <div className="simple_flex_" >
                <p className="label_input_modal" style={{minWidth: "100px"}}>Iva (%)</p>
                <input
                  type="number"
                  style={{ margin: "10px 0 15px 3px" , maxWidth: "45px"}}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="iva"
                  value={extraPoData?.iva}
                />
              </div>
              
            </div>
            
          </div>
          <div className="simple_flex_">
                <p className="label_input_modal"  style={{maxWidth: "100px"}}>Observaciones</p>
                <textarea
                  type="text"
                  style={{ margin: "10px 0 15px 3px", maxHeight: "100px"}}
                  className="input_modal"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="observations"
                  value={extraPoData?.observations}
                />
              </div>
          <button
            style={{
              padding: "3px 15px",
              margin: "20px auto 0 auto",
            }}
            onClick={() => generateOrder()}
          >
            Generar
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalPoData;
