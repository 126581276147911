import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory, useParams, useRouteMatch, useLocation} from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import ModalCloseOp from "./ModalCloseOp"


import Loading from "../../../App/components/Loading/Loading";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import {
    styles_react_select,
  } from "../../../App/components/shared/Select";
import {mockInputsDetail} from "./mockRequest"
import { url_inventario_inputs } from "../../constants/constants";



function InputsPoDetail() {
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const {poId} = useParams()
    const [json_response, setJson_response] = useState();
    const [isLoading, setIsLoading] = useState();
    const [openModalForm, setOpenModalForm] = useState(false);
    const [successModalForm, setSuccessModalForm] = useState(false);
    const [messageModalForm, setMessageModalForm] = useState();
    const [openModalCloseOp, setOpenModalCloseOp] = useState(false);
    const [closeType, setCloseType] = useState()
    const [cookies] = useCookies();
    const token = cookies.token_purchase;
  
    useEffect(() => {
      dispatch({
        type: "SET_URL",
        actual_url: path,
      });
      send_init_request();
    }, []);
  
    async function send_init_request() {
      setIsLoading(true);
      var url;
      url = `po/${poId}/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
      // var response = mockInputsDetail;
      setJson_response(response);
      setIsLoading(false);
    }

    const validateQuantities = (references) => {
      let error = false
      let matchQuantities = true
      for (const ref of references){
        if (parseFloat(ref.received_quantity) > parseFloat(ref.missing_quantity)){
          error = `${ref.product_code} - La cantidad recibida no puede ser mayor a la cantidad faltante`
          break
        }

        if (ref.missing_quantity-ref.received_quantity !== 0){
          matchQuantities = false
          break
        }
      }
      return {matchQuantities: matchQuantities, error: error }
    }

  
    const savePoInputsQuantity = (savingType) => {
        for (const ref of json_response?.references){
          ref.received_quantity = parseFloat(ref.received_quantity)
          if (!ref.received_quantity){
            ref.received_quantity = 0
          }
        }

        const {matchQuantities, error} = validateQuantities(json_response?.references)
        if (error){
          setSuccessModalForm(false);
          setMessageModalForm(error);
          setOpenModalForm(true);
          return;
        }



        if (savingType === "save" && matchQuantities){
          setCloseType("complete")
          setOpenModalCloseOp(true)
          return
        } else if (savingType === "save" && !matchQuantities){
          send_request("PUT", `po/${poId}/` , {references: json_response?.references, close_po: false});
        } else if (savingType === "save&close" && matchQuantities){
          send_request("PUT", `po/${poId}/` , {references: json_response?.references, close_po: true});
        } else if (savingType === "save&close" && !matchQuantities){
          setCloseType("incomplete")
          setOpenModalCloseOp(true)
        }
      };

    
    const callbackModalCloseOpConfirmation = (savingType) => {
      console.log(savingType)
      if (savingType==="save"){
        send_request("PUT", `po/${poId}/` , {references: json_response?.references, close_po: false});
      } else if (savingType ==="save&close"){
        send_request("PUT", `po/${poId}/` , {references: json_response?.references, close_po: true});
      }
    }

      const send_request = async (method, url, allValues) => {
        setIsLoading(true);
        const response = await fetch_backend(
          url,
          method,
          token,
          dispatch,
          allValues
        );

        setIsLoading(false);
        if (response.status === 200) {
            setSuccessModalForm(true);
            setMessageModalForm([
              "Operación realizada con éxito",
            ]);
            setOpenModalForm(true);
          }
        
          return response
    }

      const onCloseModalForm = () => {
        if (successModalForm){
          setOpenModalForm(false);
          history.push(`${url_inventario_inputs}/po`);
        }
        else{
          setOpenModalForm(false);
        }
      };
    
      const inputChange = (value, index) => {
        const references = [...json_response.references]
        references[index][value.name] = value.value
        setJson_response({...json_response, references: references})
      }

    return (
      <>
        <div className="main_container_orders_detail">
        <ModalForm
          open={openModalForm}
          onClose={() => onCloseModalForm()}
          successModal={successModalForm}
          messageModal={messageModalForm}
        />
        {openModalCloseOp &&
        <ModalCloseOp
          open={openModalCloseOp}
          callback={callbackModalCloseOpConfirmation}
          onClose={() => setOpenModalCloseOp(false)}
          params={{closeType: closeType, references: json_response?.references}}
        />
      }
      
          <Loading isLoading={isLoading} />
          <div className="simple_flex_" style={{marginTop: "20px", marginBottom: "15px", maxWidth: "80%", justifyContent: "space-between"}}> 
            <div className="simple_flex_column">
                <div className="simple_flex_">
                    <h4 className="p_bold" style={{color:"#383838"}}>
                        Orden de Compra No.  {poId}
                    </h4>
                </div>
                
            </div>
            <div className="simple_flex_column">
                <button
                style={{
                  padding: "3px 15px",
                  margin: "0",
                }}
                className="button"
                onClick={() => savePoInputsQuantity("save&close")}
              >
                Cerrar O.C
              </button>
                
            </div>
           
          </div>

            <div className="simple_flex" style={{maxWidth: "80%"}}>
          <table style={{width: "100%"}}>
            <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Código</th>
              <th style={{ width: "30%", verticalAlign: "middle"}}>Descripción</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Unidad</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Pedida</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Faltante</th>
              <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Recibida</th>
            </tr>
            {json_response?.references?.map((row, index) => (
              <tr>
                <td>{row.product_code}</td>
                <td>{row.description}</td>
                <td>{row.unit}</td>
                <td>{row.quantity}</td>
                <td>{row.missing_quantity}</td>
                <td style={{padding: "2px 2px"}}>
                  <input
                    type="number"
                    name="received_quantity"
                    className="my_input input_center"
                    disabled={row.missing_quantity === 0}
                    value={json_response?.references[index].received_quantity}
                    onChange={(event) => inputChange(event.target, index)}
                  />
                </td>
                
              </tr>
            ))}
          </table>
          </div>
          <div className="flex_space_between" style={{ marginTop: "15px", maxWidth: "80%" }}>
          <button
                style={{
                  padding: "3px 15px",
                  margin: "0 0 0 auto",
                }}
                className="button"
                onClick={() => savePoInputsQuantity("save")}
              >
                Guardar
          </button>
          </div>
                
          
        </div>
      </>
    );
  }


export default InputsPoDetail