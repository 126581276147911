import "./suppliers.css"
import _ from "lodash";
import React, { useEffect, useState } from 'react'
import {mock_request_supplier_edit} from "./mock_request"
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import { CountryDropdown } from "react-country-region-selector";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import { useParams } from 'react-router-dom';
import {
    Cities,
  } from "../../../App/services/services";

function Suppliers() {
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const [sortAscen, setSortAscen] = useState();
    const [cookies] = useCookies();
    const [dv, setDv] = useState()
    const {editSupplierId} = useParams();
    const [openModalForm, setOpenModalForm] = useState(false);
    const [successModalForm, setSuccessModalForm] = useState(false);
    const [messageModalForm, setMessageModalForm] = useState();
    const [jsonResponse, setJsonResponse] = useState();
    const [contactData, setContactData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const token = cookies.token_purchase;

    useEffect(() => {
        dispatch({
          type: "SET_URL",
          actual_url: path,
        });
        send_init_request()
      }, []);
    
    async function send_init_request() {
        setIsLoading(true);
        var url;
        if (editSupplierId){
            url = `suppliers/${editSupplierId}/`;
            var response = await fetch_backend(url, "GET", token, dispatch);
            // var response = mock_request_supplier_edit
            
            setJsonResponse(response.supplier);
        }else{
            setJsonResponse({
                type_provider:"legal",
                country: "Colombia",
                main_branch: true
            });
        }        
        setIsLoading(false);
    }

      const inputChange = (target) => {
        const jsonCopy = {...jsonResponse}
        let value
        if (target.value === "true"){
            value = true
        } else if (target.value === "false"){
            value = false
        } else{
            value = target.value
        }
        jsonCopy[target.name] = value
        setJsonResponse(jsonCopy)
      }

      const inputContactChange = (target) => {
        const jsonCopy = {...contactData}
        jsonCopy[target.name] = target.value
        setContactData(jsonCopy)
      }

      const validateRequiredFields = (allValues) => {
        const errors = {};
        if (allValues.supplier.type_provider === "legal"){
            console.log("ajam")
            if (!allValues.supplier.business_name) {
                errors.business_name = `Falta diligenciar el campo 'Razón Social'`;
            }
            if (!allValues.supplier.government_id) {
                errors.government_id = `Falta diligenciar el campo 'Nit'`;
            }
        }else {
            if (!allValues.supplier?.name) {
                errors.name = `Falta diligenciar el campo 'Nombres'`;
            }
            if (!allValues.supplier?.last_name) {
                errors.last_name = `Falta diligenciar el campo 'Apellidos'`;
            }
            if (!allValues.supplier?.government_id) {
                errors.government_id = `Falta diligenciar el campo 'Cédula'`;
            }
        }
        if (!allValues.supplier.country) {
            errors.country = `Falta seleccionar el campo 'Pais'`;
        }
        if (!allValues.supplier.city) {
            errors.city = `Falta diligenciar el campo 'Ciudad'`;
        }
        if (!allValues.supplier.address) {
            errors.address = `Falta diligenciar el campo 'Dirección'`;
        }
        if (!allValues.supplier.phone_indicator) {
            errors.phone_indicator = `Falta diligenciar el campo 'Indicativo Teléfono'`;
        }
        if (!allValues.supplier.phone) {
            errors.phone = `Falta diligenciar el campo 'Teléfono'`;
        }
        if (!allValues.supplier.bank_number) {
            errors.bank_number = `Falta diligenciar el campo 'No. Cuenta Bancaria'`;
        }
        if (!allValues.supplier.bank) {
            errors.bank = `Falta diligenciar el campo 'Banco'`;
        }
        if (!allValues.supplier.bank_type_account) {
            errors.bank_type_account = `Falta seleccionar el campo 'Tipo de Cuenta'`;
        }
        if (!allValues.supplier.ciiu) {
            errors.ciiu = `Falta diligenciar el campo 'CIIU'`;
        }
        if (allValues.supplier.auto_retainer === undefined || allValues.supplier.auto_retainer === null) {
            errors.auto_retainer = `Falta seleccionar el campo 'Autorretenedor'`;
        }

        if (allValues.supplier.rut_email === undefined || allValues.supplier.rut_email === null) {
            errors.rut_email = `Falta diligenciar el campo 'Email Rut'`;
        }

        if (contactData !== null){
            if (!allValues.contact.name) {
                errors.name = `Falta diligenciar el campo 'Nombres (Contacto)'`;
            }
            if (!allValues.contact.last_name) {
                errors.last_name = `Falta diligenciar el campo 'Apellidos (Contacto)'`;
            }
            if (!allValues.contact.email) {
                errors.email = `Falta diligenciar el campo 'Email'`;
            }
            if (allValues.contact.phone && !allValues.contact.phone_indicator) {
                errors.phone_indicator_contact = `Falta diligenciar el campo 'Indicativo Teléfono (Contacto)'`;
            }
            if (!allValues.contact.phone && allValues.contact.phone_indicator) {
                errors.phone_contact = `Falta diligenciar el campo 'Teléfono (Contacto)'`;
            }
            if (!allValues.contact.cellphone && !allValues.contact.phone){
                errors.cellphone = `Falta diligenciar el campo 'Teléfono o Celular(Contacto)'`
            }
            if (!allValues.contact.country) {
                errors.country_contact = `Falta seleccionar el campo 'Pais (Contacto)'`;
            }
            if (!allValues.contact.city) {
                errors.city_contact = `Falta diligenciar el campo 'Ciudad (Contacto)'`;
            }
        }
        

        return errors;
      };

      const saveSupplier = async() => {
        jsonResponse.dv = dv
         const body = {
            supplier: jsonResponse,
            contact: contactData
         }
         console.log(body)
         const errors = validateRequiredFields(body)
        if (!_.isEmpty(errors)) {
            setSuccessModalForm(false);
            setMessageModalForm(errors);
            setOpenModalForm(true);
            return;
          }

          let url
          let method

          if(editSupplierId){
            url = `suppliers/${editSupplierId}/`
            method = "PUT"
          } else {
            url = `suppliers/`
            method = "POST"
          }

          const response = await fetch_backend(
            url,
            method,
            token,
            dispatch,
            body
          );
  
          setIsLoading(false);
          if (response.status === 200) {
              setSuccessModalForm(true);
              setMessageModalForm([
                "Proveedor creado con éxito",
              ]);
              setOpenModalForm(true);
            };
      }

      const addContactInfo = (value) => {
        if (value===true){
            setContactData({country: "Colombia"})
        }else{
            setContactData(null)
        }
      }

      const getDV = (event) => {
        var myNit = event.target.value;
        var dv = 0;
        var vpri, x, y, z;
    
        // Se limpia el Nit
        myNit = myNit.replace(/\s/g, ""); // Espacios
        myNit = myNit.replace(/,/g, ""); // Comas
        myNit = myNit.replace(/\./g, ""); // Puntos
        myNit = myNit.replace(/-/g, ""); // Guiones
    
        // Se valida el nit
        if (isNaN(myNit)) {
          console.log("El nit/cédula '" + myNit + "' no es válido(a).");
          return "";
        }
    
        // Procedimiento
        vpri = new Array(16);
        z = myNit.length;
        // console.log(z)
        vpri[1] = 3;
        vpri[2] = 7;
        vpri[3] = 13;
        vpri[4] = 17;
        vpri[5] = 19;
        vpri[6] = 23;
        vpri[7] = 29;
        vpri[8] = 37;
        vpri[9] = 41;
        vpri[10] = 43;
        vpri[11] = 47;
        vpri[12] = 53;
        vpri[13] = 59;
        vpri[14] = 67;
        vpri[15] = 71;
    
        x = 0;
        y = 0;
        for (var i = 0; i < z; i++) {
          y = myNit.substr(i, 1);
          // console.log ( y + "x" + vpri[z-i] + ":" ) ;
    
          x += y * vpri[z - i];
          // console.log ( x ) ;
        }
    
        y = x % 11;
        // console.log ( y ) ;
    
        dv = y > 1 ? 11 - y : y;
        if (z >= 9) {
          setDv(dv);
        }
      };

  return (
    <>
    <ModalForm
        open={openModalForm}
        onClose={() => {
            setOpenModalForm(false);
          if (successModalForm === true){
              history.goBack();
          }
        }}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
    
    <div style={{margin: "15px", width: "100%", maxWidth: "1300px"}}>
        <img
            src={require("../../../App/images/fabrica.svg")}
            alt=""
            className="img__factory__addclient"
            style={{marginBottom: "10px"}}
            />
        <h4>Proveedores</h4>

        <p style={{ marginBottom: "12px", fontWeight: "bold", marginTop: "15px" }}>
        Tipo de Persona
        </p>
      <div className="clienteadd_radiobutton">
        <input
          className="inputRadio__addclient"
          type="radio"
          name="type_provider"
          value="natural"
          checked={jsonResponse?.type_provider === "natural"}
          onClick={(e) => inputChange(e.target)}
        />
        <label for="contactChoice1">Natural</label>
        <input
          className="inputRadio__addclient"
          type="radio"
          name="type_provider"
          value="legal"
          checked={jsonResponse?.type_provider === "legal"}
          onClick={(e) => inputChange(e.target)}
        />
        <label for="contactChoice1">Juridica</label>
      </div>
        <div className="simple_flex_" style={{width: "100%", justifyContent:"space-between", alignItems: "flex-start", marginTop: "20px"}}>
            <div name="col1" style={{flex:0.3}}>
                {jsonResponse?.type_provider === "legal" &&
                <div name="business_name" style={{marginBottom: "10px"}}>
                        <p className="labeltag">Razón Social</p>
                        <input
                                type="text"
                                name="business_name"
                                value={jsonResponse?.business_name}
                                onChange={(e) => inputChange(e.target)}
                            />
                </div>
                }
                {jsonResponse?.type_provider === "natural" && <>
                <div name="name" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Nombres</p>
                    <input
                            type="text"
                            name="name"
                            value={jsonResponse?.name}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                <div name="last_name" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Apellidos</p>
                    <input
                            type="text"
                            name="last_name"
                            value={jsonResponse?.last_name}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                </>}
                <div name="tradename" style={{marginBottom: "10px"}}>
                        <p className="labeltag">Nombre Comercial</p>
                        <input
                                type="text"
                                name="tradename"
                                value={jsonResponse?.tradename}
                                onChange={(e) => inputChange(e.target)}
                            />
                </div>
                {jsonResponse?.type_provider === "legal" ?
                <div name="nit" className="simple_flex_" style={{justifyContent:"space-between", marginBottom: "10px"}} >
                    <div style={{flex: 0.7}}>
                        <p className="labeltag">Nit *</p>
                        <input
                                className="my_input"
                                type="number"
                                name="government_id"
                                value={jsonResponse?.government_id}
                                onChange={(e) => {inputChange(e.target); getDV(e)}}
                            />
                    </div>
                    <div style={{flex: 0.2}}>
                        <p className="labeltag">DV</p>
                        <input
                            className="my_input"
                            type="text"
                            name="dv"
                            value={dv || jsonResponse?.dv}

                        />
                    </div>
                </div>: 
                <div name="cedula" style={{marginBottom: "10px"}} >
                    <p className="labeltag">Cedula</p>
                    <input
                            className="my_input"
                            type="text"
                            name="government_id"
                            value={jsonResponse?.government_id}
                            onChange={(e) => inputChange(e.target)}
                        />
                
            </div>}
                <div name="country" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Pais</p>
                    <CountryDropdown
                    value={jsonResponse?.country}
                    onChange={(value) => inputChange({name:"country", value: value})}
                    classes="countryDropdown"
                    defaultOptionLabel="Seleccione..."
                    />
                </div>
                <div name="city" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Ciudad</p>
                    <input
                            type="text"
                            name="city"
                            list="ciudades"
                            value={jsonResponse?.city}
                            onChange={(e) => inputChange(e.target)}
                        />
                    {jsonResponse?.country === "Colombia" ? <Cities id="ciudades" /> : null}
                </div>
                <div name="address" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Dirección</p>
                    <input
                            type="text"
                            name="address"
                            value={jsonResponse?.address}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                
            </div>
            <div name="col2" style={{flex:0.3}}>
            <div name="telefono" className="simple_flex_" style={{justifyContent:"space-between", marginBottom: "10px"}} >
                    <div style={{flex: 0.2}}>
                        <p className="labeltag">Indic.</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone_indicator"
                                value={jsonResponse?.phone_indicator}
                                onChange={(e) => inputChange(e.target)}
                            />
                    </div>
                    <div style={{flex: 0.7}}>
                        <p className="labeltag">Teléfono</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone"
                                value={jsonResponse?.phone}
                                onChange={(e) => inputChange(e.target)}
                            />
                    </div>
                </div>
                <div name="bank_number" style={{marginBottom: "10px"}}>
                    <p className="labeltag">No. Cuenta Bancaria</p>
                    <input
                            type="number"
                            name="bank_number"
                            value={jsonResponse?.bank_number}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                <div name="bank" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Banco</p>
                    <input
                            type="text"
                            name="bank"
                            value={jsonResponse?.bank}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                <div name="bank_type_account" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Tipo de Cuenta</p>
                    <div className="simple_flex_" style={{height: "27px"}}>
                        <input
                        className="inputRadio__addclient"
                        type="radio"
                        name="bank_type_account"
                        value="savings"
                        checked={jsonResponse?.bank_type_account === "savings"}
                        
                        onClick={(e) => inputChange(e.target)}
                        />
                        <label for="bank_type_account" style={{marginRight: "10px"}}>Ahorros</label>
                        <input
                        className="inputRadio__addclient"
                        type="radio"
                        name="bank_type_account"
                        value="current"
                        checked={jsonResponse?.bank_type_account === "current"}
                        onClick={(e) => inputChange(e.target)}
                        />
                        <label for="bank_type_account">Corriente</label>
                    </div>
                </div>
                
                <div name="bank" style={{marginBottom: "10px"}}>
                    <p className="labeltag">CIIU</p>
                    <input
                            type="number"
                            name="ciiu"
                            value={jsonResponse?.ciiu}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
                <div name="website" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Website</p>
                    <input
                            type="text"
                            name="website"
                            value={jsonResponse?.website}
                            onChange={(e) => inputChange(e.target)}
                        />
                </div>
            </div>
                <div name="col3" style={{flex:0.3}}> <div name="payment_method" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Forma de Pago</p>
                    <select
                    className="simple_select"
                        name="payment_method"
                        value={jsonResponse?.payment_method}
                        onChange={(e) => inputChange(e.target)}
                    >
                        <option value={null}></option>
                        <option value="credito">Crédito</option>
                        <option value="efectivo">Efectivo</option>
                    </select>
                </div>
                <div name="payment_term" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Plazo</p>
                    <input
                        type="text"
                        name="payment_term"
                        value={jsonResponse?.payment_term}
                        onChange={(e) => inputChange(e.target)}
                    />
                </div>
                <div name="delivery_conditions" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Condiciones de Entrega</p>
                    <input
                        type="text"
                        name="delivery_conditions"
                        value={jsonResponse?.delivery_conditions}
                        onChange={(e) => inputChange(e.target)}
                    />
                </div>
                <div name="rut_email" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Email RUT</p>
                    <input
                        type="text"
                        name="rut_email"
                        value={jsonResponse?.rut_email}
                        onChange={(e) => inputChange(e.target)}
                    />
                </div>
                <div name="auto_retainer" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Autorretenedor</p>
                    <div className="simple_flex_" style={{height: "27px"}}>
                        <input
                        className="inputRadio__addclient"
                        type="radio"
                        name="auto_retainer"
                        value={true}
                        checked={jsonResponse?.auto_retainer === true}
                        
                        onClick={(e) => inputChange(e.target)}
                        />
                        <label for="auto_retainer" style={{marginRight: "10px"}}>Si</label>
                        <input
                        className="inputRadio__addclient"
                        type="radio"
                        name="auto_retainer"
                        value={false}
                        checked={jsonResponse?.auto_retainer === false}
                        onClick={(e) => inputChange(e.target)}
                        />
                        <label for="auto_retainer">No</label>
                    </div>
                </div>
                {/* <div name="main_branch" style={{marginBottom: "10px"}}>
                    <div className="simple_flex_" style={{height: "27px"}}>
                            <input
                            className="inputRadio__addclient"
                            type="checkbox"
                            name="main_branch"
                            disabled={editSupplierId? false: true}
                            checked={jsonResponse?.main_branch}
                            onChange={(e) => inputChange({value: e.target.checked, name: e.target.name})}
                            />
                            <label for="main_branch" style={{marginRight: "10px"}}>Establecer como sede principal</label>
                    </div>     
                </div> */}
            </div>
            
        </div>
        {!editSupplierId && <>
        <div>
            <div name="add_contact" style={{marginBottom: "10px"}}>
                <div className="simple_flex_" style={{height: "27px"}}>
                        <input
                        className="inputRadio__addclient"
                        type="checkbox"
                        checked={contactData===null? false: true}
                        onChange={(e) => addContactInfo(e.target.checked)}
                        />
                        <label style={{marginRight: "10px"}}>Agregar Informacion de Contacto</label>
                </div>     
            </div>
        </div>
        {contactData != null &&
        <div className="simple_flex_" style={{width: "100%", justifyContent:"space-between", alignItems: "flex-start"}}>
            <div name="col1" style={{flex:0.3}}>
                <div name="address" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Nombres</p>
                    <input
                            type="text"
                            name="name"
                            value={contactData?.name}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                </div>
                <div name="last_name" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Apellidos</p>
                    <input
                            type="text"
                            name="last_name"
                            value={contactData?.last_name}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                </div>
                <div name="position" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Cargo</p>
                    <input
                            type="text"
                            name="position"
                            value={contactData?.position}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                </div>
                <div name="email" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Email</p>
                    <input
                            type="text"
                            name="email"
                            value={contactData?.email}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                </div>
                <div name="phone" className="simple_flex_" style={{justifyContent:"space-between", marginBottom: "10px"}} >
                    <div style={{flex: 0.15}}>
                        <p className="labeltag">Indic.</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone_indicator"
                                value={contactData?.phone_indicator}
                                onChange={(e) => inputContactChange(e.target)}
                            />
                    </div>
                    <div style={{flex: 0.60}}>
                        <p className="labeltag">Teléfono</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone"
                                value={contactData?.phone}
                                onChange={(e) => inputContactChange(e.target)}
                            />
                    </div>
                    <div style={{flex: 0.2}}>
                        <p className="labeltag">Extensión</p>
                        <input
                                className="my_input"
                                type="number"
                                name="extension"
                                value={contactData?.extension}
                                onChange={(e) => inputContactChange(e.target)}
                            />
                    </div>
                </div>
                <div name="cellphone" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Celular</p>
                    <input
                            type="text"
                            name="cellphone"
                            value={contactData?.cellphone}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                </div>
                
            </div>
            <div name="col2" style={{flex:0.3}}>
                <div name="gender" style={{marginBottom: "10px"}}>
                        <p className="labeltag">Género</p>
                        <select
                                type="text"
                                name="gender"
                                class="countryDropdown"
                                value={contactData?.gender}
                                onChange={(e) => inputContactChange(e.target)}
                            >
                                <option value={null}></option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                                <option value="LGTB">LGTBI</option>
                            </select>
                </div>
                <div name="country" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Pais</p>
                    <CountryDropdown
                    value={contactData?.country}
                    onChange={(value) => inputContactChange({name:"country", value: value})}
                    classes="countryDropdown"
                    defaultOptionLabel="Seleccione..."
                    />
                </div>
                <div name="city" style={{marginBottom: "10px"}}>
                    <p className="labeltag">Ciudad</p>
                    <input
                            type="text"
                            name="city"
                            list="ciudades_contact"
                            value={contactData?.city}
                            onChange={(e) => inputContactChange(e.target)}
                        />
                    {contactData?.country === "Colombia" ? <Cities id="ciudades_contact" /> : null}
                </div>
                
            </div>
            <div name="col3" style={{flex:0.3}}>
                
            </div>
            
        </div>
        }
        </>}
        <button
                style={{
                    padding: "3px 15px",
                    margin: 0
                }}
                className="button"
                onClick={() => saveSupplier()}
            >
                Guardar
        </button>
    </div>
    </>
  )
}

export {Suppliers}