import "./Home.css";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import CreatableSelect from "react-select/creatable";
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  ModalQuestion,
  ModalForm,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import ModalCostCenter from "./Modal_cost_center";
import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import {
  styles_react_select,
  styles_react_select_cost_centers,
} from "../../../App/components/shared/Select";
import { url_orders_home } from "../../constants/constants";
import { mock_reponse } from "./mock_response";

function Detail_order() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { order_data, is_edit, is_new } = useLocation().state;
  const [json_response, setJson_response] = useState();
  const [isLoading, setIsLoading] = useState();
  const [selectedCostCenter, setSelectedCostCenter] = useState();
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [openModalCostCenter, setOpenModalCostCenter] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState("");
  const [confirmationDate, setConfirmationDate] = useState("");
  const [successfulOrderCreation, setSuccessfulOrderCreation] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_orders;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request();
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    if (order_data.id) {
      url = `orders/${order_data.id}/`;
    } else {
      url = `orders/`;
    }
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = mock_reponse;
    if (response.order) {
      fillTable(response.order.items);
    } else {
      addNewTableRow();
    }
    setJson_response(response);
    if (response.status === 200) {
      buildOptionsToShow(response);
      builDefaultSelectedOptions(response);
      if (is_new) {
        setOpenModalCostCenter(true);
      } else {
        const cc = response.cost_centers.filter(
          (cc) => cc.id === response.selected_options.cost_center
        )[0];
        setSelectedCostCenter({ value: cc.id, label: cc.name, type: cc.type });
        if (response.selected_options.quote) {
          const quote = response.quotes.filter(
            (quote) => quote.id === response.selected_options.quote
          )[0];
          setConfirmationDate(quote.confirmation_date);
          setDeliveryTime(quote.deliveryTime);
        }
      }
    }
    setIsLoading(false);
  }

  const fillTable = (items) => {
    const tableRows_ = [];
    for (const item of items) {
      tableRows_.push({
        isNew: item.is_new,
        id: item.id,
        code: {
          label: item.code || "-",
          value: item.code || "-",
          id: item.id || null,
        },
        description: {
          label: item.description,
          value: item.code || item.description,
          id: item.id || null,
        },
        unit: item.unit,
        quantity: item.quantity,
        progress: item.progress,
      });
    }
    setTableRows(tableRows_);
  };

  const buildOptionsToShow = (jsonResponse) => {
    let options = {};
    let codes = [];
    let descriptions = [];
    let costCenters = [];
    let clients = [];
    let quotes = [];
    let containerReferences = [];
    let typesAdm = [];
    let typesSst = [];
    let vehicles = [];

    for (const reference of jsonResponse.references) {
      codes.push({ label: reference.code, value: reference.code, id: reference.id });
      descriptions.push({
        id: reference.id,
        label: reference.description,
        value: reference.code,
      });
    }
    options.codes = codes;
    options.descriptions = descriptions;

    for (const cc of jsonResponse.cost_centers) {
      costCenters.push({ value: cc.id, label: cc.name, type: cc.type });
    }

    options.costCenters = costCenters;

    for (const client of jsonResponse.clients) {
      clients.push({ value: client.id, label: client.name });
    }

    options.clients = clients;
    if (jsonResponse.selected_options?.quote) {
      let quotes;
      quotes = jsonResponse.quotes.filter(
        (quote) => quote.client_id == jsonResponse.selected_options.client
      );

      const quotesToShow = [];
      for (const quote of quotes) {
        quotesToShow.push({
          value: quote.id,
          label: quote.id,
          confirmationDate: quote.confirmation_date,
          deliveryTime: quote.delivery_time,
        });
      }
      options.quotes = quotesToShow;
    }

    for (const contReference of jsonResponse.container_references) {
      containerReferences.push({
        value: contReference.id,
        label: contReference.reference,
        description: contReference.description,
      });
    }
    options.containerReferences = containerReferences;

    for (const typeAdm of jsonResponse.admin_types) {
      typesAdm.push({ value: typeAdm.id, label: typeAdm.name });
    }
    options.typesAdm = typesAdm;

    for (const typeSst of jsonResponse.sst_types) {
      typesSst.push({ value: typeSst.id, label: typeSst.name });
    }
    options.typesSst = typesSst;

    for (const vechicle of jsonResponse.vehicles) {
      vehicles.push({
        value: vechicle.id,
        label: vechicle.plate,
        type: vechicle.type,
      });
    }
    options.vehicles = vehicles;

    setOptionsToShow(options);
  };

  const builDefaultSelectedOptions = (jsonResponse) => {
    var quote = null;
    if (is_edit || !is_new) {
      quote = jsonResponse.selected_options.quote;
    }
    setSelectedOptions({
      cost_center: null,
      client: null,
      quote: quote,
      container_reference: null,
      intern_project: jsonResponse?.selected_options?.intern_project,
      admin_type: null,
      sst_type: null,
      vehicle: null,
      order_name: jsonResponse?.selected_options?.order_name,
    });
  };

  const onCloseModalCostCenter = (cost_center) => {
    setSelectedCostCenter(cost_center);
    setOpenModalCostCenter(false);
    setSelectedOptions({ ...selectedOptions, cost_center: cost_center.value });
  };

  const addNewTableRow = () => {
    let tableRows_ = [...tableRows];
    tableRows_.push({
      isNew: false,
      id: null,
      code: {
        label: "",
        value: "",
      },
      description: {
        label: "",
        value: "",
      },
      unit: null,
      quantity: 0,
    });
    setTableRows(tableRows_);
  };

  const removeTableRow = (index) => {
    let tableRows_ = [...tableRows];
    tableRows_.splice(index, 1);
    setTableRows(tableRows_);
  };

  const changeOrderNameInput = (target) => {
    const options = { ...selectedOptions };
    options.order_name = target.value;
    setSelectedOptions(options);
  };

  const changeInternProject = (target) => {
    const options = { ...selectedOptions };
    options.intern_project = target.value;
    setSelectedOptions(options);
  };

  const changeTools = (target) => {
    const options = { ...selectedOptions };
    options.tools = target.value;
    setSelectedOptions(options);
  };

  const changeInputTable = (index_row, target) => {
    let tableRows_ = [...tableRows];
    let value;
    if (target.type === "number") {
      value = parseInt(target.value);
    } else {
      value = target.value;
    }
    tableRows_[index_row][target.name] = value;
    setTableRows(tableRows_);
  };

  const ChangeReactSelectTable = (selectOption, index, key) => {
    console.log(selectOption);
    let tableRows_ = [...tableRows];
    let rowToChange = tableRows_[index];
    rowToChange[key] = selectOption;
    if (selectOption.__isNew__) {
      if (key === "description" && rowToChange["isNew"] === false) {
        rowToChange["code"] = { value: "-", label: "-", id: null};
      }
      rowToChange["isNew"] = true;
      rowToChange["id"] = null;
    } else {
      rowToChange["isNew"] = false;
      const reference = json_response.references.filter(
        (reference) => reference.code == selectOption.value
      )[0];
      rowToChange["unit"] = reference?.unit;
      if (key == "code") {
        rowToChange["description"] = {
          id: reference.id,
          value: reference.code,
          label: reference.description,
        };
        rowToChange["id"] = reference.id;
      } else {
        rowToChange["code"] = { value: reference.code, label: reference.code, id: reference.id };
        rowToChange["id"] = reference.id;
      }
    }
    setTableRows(tableRows_);
  };

  const ChangeReactSelectCostCenterOptions = (reactSelectOption, key) => {
    const options = { ...selectedOptions };
    if (key == "client") {
      options["quote"] = null;
      let quotes;
      quotes = json_response.quotes.filter(
        (quote) => quote.client_id == reactSelectOption.value
      );

      const quotesToShow = [];
      for (const quote of quotes) {
        quotesToShow.push({
          value: quote.id,
          label: quote.id,
          confirmationDate: quote.confirmation_date,
          deliveryTime: quote.delivery_time,
        });
      }
      setOptionsToShow({ ...optionsToShow, quotes: quotesToShow });
    }

    if (key === "quote") {
      setDeliveryTime(reactSelectOption.deliveryTime);
      setConfirmationDate(reactSelectOption.confirmationDate);
    }

    options[key] = reactSelectOption.value;
    setSelectedOptions(options);
    console.log(options);
  };

  const getOrdersDataFromTable = () => {
    const order_items = [];
    for (const row of tableRows) {
      order_items.push({
        id: row.id,
        code: row.code.value,
        quantity: row.quantity,
        description: row.description?.label,
        unit: row.unit,
        is_new: row.isNew,
      });
    }
    return order_items;
  };

  const validateRequiredFields = (allValues) => {
    const errors = {};
    const ordersErrors = [];
    allValues.order_items.forEach((order, index) => {
      if (!order.code) {
        ordersErrors.push(`Falta seleccionar 'Codigo' en el item ${index + 1}`);
      }
      if (!order.quantity) {
        ordersErrors.push(`Falta 'Cantidad' en el item ${index + 1}`);
      }
      console.log(order);
      if (order.is_new && !order.description) {
        ordersErrors.push(`Falta 'Descripcion' en el item ${index + 1}`);
      }

      if (order.is_new && !order.unit) {
        ordersErrors.push(`Falta 'Unidad' en el item ${index + 1}`);
      }
    });

    if (ordersErrors.length) {
      errors.orders = ordersErrors;
    }
    if (is_new) {
      const selectedOpt = allValues.selected_options;

      if (!selectedOpt.order_name) {
        errors.orer_name = "Falta diligenciar 'Nombre Pedido'";
      }

      if (selectedCostCenter.type == "A" || selectedCostCenter.type == "B") {
        if (!selectedOpt.client) {
          errors.client = "Falta seleccionar 'Cliente'";
        }
        if (!selectedOpt.quote) {
          errors.quote = "Falta seleccionar 'Cotizacion Nro'";
        }
      } else if (selectedCostCenter.type == "C") {
        if (!selectedOpt.container_reference) {
          errors.container_reference = "Falta seleccionar 'Referencia'";
        }
      } else if (selectedCostCenter.type == "E") {
        if (!selectedOpt.admin_type) {
          errors.admin_type = "Falta seleccionar 'Tipo'";
        }
      } else if (selectedCostCenter.type == "F") {
        if (!selectedOpt.sst_type) {
          errors.sst_type = "Falta seleccionar 'Tipo'";
        }
      } else if (selectedCostCenter.type == "G") {
        if (!selectedOpt.vehicle) {
          errors.vehicle = "Falta seleccionar 'Vehículo'";
        }
        } else if (selectedCostCenter.type == "H") {
          if (!selectedOpt.intern_project) {
            errors.vehicle = "Falta diligenciar 'Proyecto Interno'";
          }

          if (selectedOpt.intern_project && selectedOpt.intern_project.length !== 4) {
            errors.vehicle = "'Proyecto Interno' debe tener 4 digitos";
          }

      } else if (selectedCostCenter.type == "I") {
        if (!selectedOpt.tools) {
          errors.vehicle = "Falta diligenciar 'Herramienta'";
        }

        if (selectedOpt.tools && selectedOpt.tools.length !== 4) {
          errors.vehicle = "'Herramienta' debe tener 4 digitos";
        }

    }
    }

    return errors;
  };

  const send_request = async (method, allValues) => {
    setIsLoading(true);
    const baseEndpointUrl = "orders/";
    const url = json_response.order?.id
      ? `${baseEndpointUrl}${json_response.order?.id}/`
      : baseEndpointUrl;

    const response = await fetch_backend(
      url,
      method,
      token,
      dispatch,
      allValues
    );
    setIsLoading(false);

    if (response.status === 200) {
      setSuccessfulOrderCreation(true);
      setSuccessModalForm(true);
      setMessageModalForm([
        "Operación Exitosa",
        `Nro de Pedido: ${response.order?.id}`,
      ]);
      setOpenModalForm(true);
    }
  };

  const onCloseModalForm = () => {
    if (successfulOrderCreation) {
      history.push(url_orders_home);
    } else {
      setOpenModalForm(false);
    }
  };

  const saveOrder = () => {
    const orderItems = getOrdersDataFromTable();
    const allValues = {
      order_items: orderItems,
      selected_options: selectedOptions,
    };

    if (allValues.order_items.length === 0) {
      setSuccessModalForm(false);
      setMessageModalForm("No hay items para guardar");
      setOpenModalForm(true);
      return;
    }
    const errors = validateRequiredFields(allValues);
    const method = is_new ? "POST" : is_edit ? "PUT" : "GET";

    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    console.log(allValues);
    send_request(method, allValues);
  };

  return (
    <>
      <ModalCostCenter
        open={openModalCostCenter}
        onClose={onCloseModalCostCenter}
        costCenters={optionsToShow.costCenters}
      />
      <ModalForm
        open={openModalForm}
        onClose={() => onCloseModalForm()}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <div className="main_container_orders_detail">
        <Loading isLoading={isLoading} />
        <h3>{is_new ? "Nuevo Pedido" : "Detalle Pedido"}</h3>
        {!is_new && (
          <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
            <p className="p_bold" style={{ marginRight: "10px" }}>
              Pedido Nro
            </p>
            <p>{json_response?.order?.id}</p>
          </div>
        )}

        <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
          <p className="p_bold" style={{ width: "130px" }}>
            Centro de Costos
          </p>
          <p>{selectedCostCenter?.label}</p>
        </div>

        <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
          <p className="p_bold" style={{ width: "130px" }}>
            Nombre Pedido
          </p>
          <input
            type="text"
            name="order_name"
            className="my_input"
            style={{ maxWidth: "220px" }}
            disabled={is_new ? false : true}
            onChange={(event) => changeOrderNameInput(event.target)}
            value={selectedOptions?.order_name}
          />
        </div>

        <div className="dynamic_cost_centers">
          {selectedCostCenter?.type == "A" ||
          selectedCostCenter?.type == "B" ? (
            <>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Cliente
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.clients}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(value, "client")
                  }
                  defaultValue={
                    json_response.selected_options?.client
                      ? optionsToShow.clients.filter(
                          (client) =>
                            client.value ==
                            json_response.selected_options.client
                        )[0]
                      : null
                  }
                />
              </div>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Cotizacion N
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.quotes}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(value, "quote")
                  }
                  defaultValue={
                    json_response.selected_options?.quote
                      ? {
                          value: json_response.selected_options.quote,
                          label: json_response.selected_options.quote,
                        }
                      : null
                  }
                  value={
                    selectedOptions?.quote
                      ? optionsToShow.quotes.filter(
                          (quote) => quote.id == selectedOptions.quote
                        )[0]
                      : null
                  }
                />
              </div>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Fecha confirmación:
                </p>
                <p>{confirmationDate}</p>
              </div>
              {selectedCostCenter.type == "A" && (
                <div className="simple_flex_">
                  <p className="p_bold" style={{ marginRight: "7px" }}>
                    Tiempo de Entrega:
                  </p>
                  <p> {deliveryTime}</p>
                </div>
              )}
            </>
          ) : selectedCostCenter?.type == "C" ? (
            <>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Referencia
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.containerReferences}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(
                      value,
                      "container_reference"
                    )
                  }
                  defaultValue={
                    json_response.selected_options?.container_reference
                      ? optionsToShow.containerReferences.filter(
                          (reference) =>
                            reference.value ==
                            json_response.selected_options.container_reference
                        )[0]
                      : null
                  }
                />
              </div>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Producto:
                </p>
                <p></p>
              </div>
            </>
          ) : selectedCostCenter?.type == "E" ? (
            <>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Tipo
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.typesAdm}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(value, "admin_type")
                  }
                  defaultValue={
                    json_response.selected_options?.admin_type
                      ? optionsToShow.typesAdm.filter(
                          (type_) =>
                            type_.value ==
                            json_response.selected_options.admin_type
                        )[0]
                      : null
                  }
                />
              </div>
            </>
          ) : selectedCostCenter?.type == "F" ? (
            <>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Tipo
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.typesSst}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(value, "sst_type")
                  }
                  defaultValue={
                    json_response.selected_options?.sst_type
                      ? optionsToShow.typesSst.filter(
                          (type_) =>
                            type_.value ==
                            json_response.selected_options.sst_type
                        )[0]
                      : null
                  }
                />
              </div>
            </>
          ) : selectedCostCenter?.type == "G" ? (
            <>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Vehículo
                </p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  isDisabled={is_new ? false : true}
                  options={optionsToShow.vehicles}
                  styles={styles_react_select_cost_centers}
                  onChange={(value) =>
                    ChangeReactSelectCostCenterOptions(value, "vehicle")
                  }
                  defaultValue={
                    json_response.selected_options.vehicle
                      ? optionsToShow.vehicles.filter(
                          (vehicle) =>
                            vehicle.value ==
                            json_response.selected_options?.vehicle
                        )[0]
                      : null
                  }
                />
              </div>
              <div className="simple_flex_" style={{ marginRight: "20px" }}>
                <p className="p_bold" style={{ marginRight: "7px" }}>
                  Tipo:
                </p>
                <p></p>
              </div>
            </>
          ) : selectedCostCenter?.type == "H" ? (
            <>
               <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
                <p className="p_bold" >
                  Proyecto Interno
                </p>
                <input
                  type="text"
                  name="intern_project"
                  className="my_input"
                  style={{ maxWidth: "220px" }}
                  disabled={is_new ? false : true}
                  onChange={(event) => changeInternProject(event.target)}
                  value={selectedOptions?.intern_project}
                />
              </div>
            </>
          ) : selectedCostCenter?.type == "I" ? (
            <>
               <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
                <p className="p_bold" >
                  Herramienta
                </p>
                <input
                  type="text"
                  name="tools"
                  className="my_input"
                  style={{ maxWidth: "220px" }}
                  disabled={is_new ? false : true}
                  onChange={(event) => changeTools(event.target)}
                  value={selectedOptions?.tools}
                />
              </div>
            </>
          ) : null}
        </div>

        {!is_edit && !is_new && (
          <div className="simple_flex_" style={{ margin: "20px 0 20px 0" }}>
            <p className="p_bold" style={{ marginRight: "10px" }}>
              Estado General
            </p>
            <p>{json_response?.order?.progress}%</p>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <table>
            <tr>
              <th style={{ width: "2%" }}>No.</th>
              <th style={{ width: "10%" }}>Código</th>
              <th style={{ width: "50%" }}>Descripción</th>
              <th style={{ width: "5%" }}>Unidad</th>
              <th style={{ width: "5%" }}>Cantidad</th>
              {!is_edit && !is_new && <th style={{ width: "15%" }}>Estado</th>}
              <th style={{ width: "2%", border: 0 }}></th>
            </tr>
            {tableRows.map((row, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    isDisabled={
                      row.isNew ? true : !is_edit && !is_new ? true : false
                    }
                    options={optionsToShow.codes}
                    styles={styles_react_select}
                    onChange={(value) =>
                      ChangeReactSelectTable(value, index, "code")
                    }
                    value={
                      tableRows[index].code?.value
                        ? tableRows[index].code
                        : null
                    }
                  />
                </td>
                <td>
                  <CreatableSelect
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isSearchable={true}
                    isDisabled={!is_edit && !is_new ? true : false}
                    options={optionsToShow.descriptions}
                    styles={styles_react_select}
                    onChange={(value) =>
                      ChangeReactSelectTable(value, index, "description")
                    }
                    value={
                      tableRows[index].description?.value
                        ? tableRows[index].description
                        : null
                    }
                  />
                </td>
                <td>
                  {row.isNew ? (
                    <input
                      type="text"
                      name="unit"
                      className="my_input input_center"
                      value={tableRows[index].unit}
                      disabled={!is_edit && !is_new ? true : false}
                      onChange={(event) =>
                        changeInputTable(index, event.target)
                      }
                    />
                  ) : (
                    row.unit
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    name="quantity"
                    className="my_input input_center"
                    value={tableRows[index].quantity}
                    disabled={!is_edit && !is_new ? true : false}
                    onChange={(event) => changeInputTable(index, event.target)}
                  />
                </td>
                {!is_edit && !is_new && <td>{tableRows[index].progress}</td>}
                <td
                  style={{ border: 0, textAlign: "left", paddingLeft: "20px" }}
                  onClick={() => removeTableRow(index)}
                >
                  {(is_edit || is_new) && (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="s"
                      color="red"
                    />
                  )}
                </td>
              </tr>
            ))}
          </table>
          {(is_edit || is_new) && (
            <div className="flex_space_between" style={{ marginTop: "20px" }}>
              <div
                className="simple_square_plus"
                onClick={() => addNewTableRow()}
              >
                <h4 className="col_white">+</h4>
              </div>

              <button
                style={{
                  padding: "3px 15px",
                  margin: "0",
                }}
                className="button"
                onClick={() => saveOrder()}
              >
                Guardar
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Detail_order;





// {
//   "hosting": [ {
//       "target": "prod",
//       "public": "build",
//       "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//     },
//     {
//       "target": "dev",
//       "public": "build",
//       "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//     }
//   ]
// }


// {
//   "hosting": {
//     "public": "build",
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//   }
// }
