import "./inputs_purchase.css";
import { response_get_mock } from "./responses_mock";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import {
  faSearch,
  faInfoCircle,
  faTrash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import { ModalForm } from "../../../App/components/shared/Modals";


function InputsPurchase() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const history = useHistory();
  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [filterComboValue, setFilterComboValue] = useState("");
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState();
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request()
  }, []);

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  async function send_init_request() {
    setIsLoading(true);
    var url;
    url = `orders_buy/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    setJson_response(response);
    setBackupJson_response(response);
    setIsLoading(false);
  }

  const click_order_detail = (orderId) => {
    history.push(`${path}/${orderId}`, {
      orderId: orderId,
    });
  };

  const closeInput = (orderId) => {
    send_request("DELETE", `orders_buy/${orderId}/`)
  }

  const send_request = async (method, url, allValues) => {
    setIsLoading(true);
    const response = await fetch_backend(
      url,
      method,
      token,
      dispatch,
      allValues
    );

    setIsLoading(false);
    if (response.status === 200) {
        setSuccessModalForm(true);
        setMessageModalForm([
          "Entrada cerrada exitosamente.",
        ]);
        setOpenModalForm(true);
      } else{
        setSuccessModalForm(false);
      }
    
      return response
}

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.inputs];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "requested_date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });

    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    console.log(sorted);
    if (!sortAscen) {
      sorted.reverse();
    }

    setJson_response({ ...json_response, inputs: sorted });
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const handleSearch = (value) => {
    let newList = [...backupJson_response.inputs];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.cost_center.toLowerCase().includes(value.toLowerCase()) ||
          row.work_order.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response({ ...json_response, inputs: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  const onFocusSearch = () => {
    setBackupJson_response(json_response);
  };

  const comboFilter = (e) => {
    const value = e.target.value;
    if (filterComboValue === value) {
      setFilterComboValue("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterComboValue(value);
    var newList = [...backupJson_response.inputs];
    newList = newList.filter((row) => row.status == value);
    setJson_response({ ...json_response, inputs: newList });
  };

  const callbackModalQuestion = (value) => {
    const answer = value.value === "true";
    const questionData = value.params
    setOpenDeleteConfirmation(false)
    if (answer){
      closeInput(questionData.oderId)
    }
}

const onCloseModalForm = () => {
  setOpenModalForm(false)
    if (successModalForm) {
      window.location.reload()
    }
}


  return (
    <div className="wrapped__container_top_bar ">
      <Loading isLoading={isLoading} />
      {openDeleteConfirmation &&
      <ModalQuestion
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        question="Está seguro que desea cerrar esta entrada?"
        callback={callbackModalQuestion}
        params={{oderId: indexToDelete}}
      />}
      <ModalForm
        open={openModalForm}
        onClose={() => onCloseModalForm()}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <div className="top-bar">
        <h3>Entradas</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={() => onFocusSearch()}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides_tools">
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="unmanaged"
              className="inputselect unmanaged"
              checked={filterComboValue === "unmanaged"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />
            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              Sin gestionar
            </p>
          </div>

          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="managed"
              className="inputselect managed"
              checked={filterComboValue === "managed"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />

            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              &nbsp;Gestionadas
            </p>
          </div>

          <div className="sub_guide_tools"></div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th style={{ width: "3%" }} />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Id"
              txt_filter="order_id"
              width="10%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Nombre del Pedido"
              txt_filter="order_name"
              width="15%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Orden de Trabajo"
              txt_filter="work_order"
              width="15%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha Solicitud"
              txt_filter="requested_date"
              width="13%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Solicitado Por"
              txt_filter="requested_by"
              width="15%"
            />
            <TableHeader col_name="Detalle" width="8%" />
            <TableHeader col_name="Cerrar" width="8%" />
          </tr>
          {json_response?.inputs?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{
                        backgroundColor: `${
                          row.status === "unmanaged" ? "red" : "green"
                        }`,
                      }}
                    />
                  </div>
                </td>
                <td>{row.order_id}</td>
                <td>{row.order_name}</td>
                <td>{row.work_order}</td>
                <td>{row.requested_date}</td>
                <td>{row.requested_by}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="iconRow"
                    onClick={() => click_order_detail(row.order_id, false)}
                  />
                </td>
                <td>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={row.status === "unmanaged" ? "#f2f2f2" : "green"}
                  className="iconRow"
                  onClick={()=>{
                    if (row.status === "managed"){
                    setIndexToDelete(row.order_id); 
                    setOpenDeleteConfirmation(true)
                  }
                  }}
                />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export { InputsPurchase };
