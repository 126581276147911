const response_get_mock = {
  total_pages: 5,
  page: 2,
  result: {
    products: [
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
      {
        product_code: "10101010",
        description:
          "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        measure: "Unidad",
        stock: 500,
        minimum: 100,
        maximum: 300,
        average_cost: 50000,
      },
    ],
  }
  
};

const init_get_mock = {
  products: [
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
    {
      product_code: "101010",
      description:
        "Lorem ipsum dolor sit amet, consetetour Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
    },
  ],
};


export { response_get_mock, init_get_mock };
