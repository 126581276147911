import "./Home.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import {
  faSearch,
  faInfoCircle,
  faTrash,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import {OrderPdf} from "./OrderPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import JsBarcode from 'jsbarcode';

function Home() {
  const history = useHistory();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState();
  const [cookies] = useCookies();
  const [filterComboValue, setFilterComboValue] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState();
  const [file, setfile] = useState();
  const token = cookies.token_orders;

  async function send_init_request() {
    setIsLoading(true);
    var response = await fetch_backend(
      "orders/?displayables_only=true",
      "GET",
      token,
      dispatch
    );
    setJson_response(response);
    setBackupJson_response(response);
    setIsLoading(false);
  }

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.orders];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "request_date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });

    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    console.log(sorted);
    if (!sortAscen) {
      sorted.reverse();
    }

    setJson_response({ ...json_response, orders: sorted });
  };

  const handleSearch = (value) => {
    let newList = [...backupJson_response.orders];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.order_name.toLowerCase().includes(value.toLowerCase()) ||
          row.work_order.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response({ ...json_response, orders: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  const onFocusSearch = () => {
    setBackupJson_response(json_response);
  };

  const comboFilter = (e) => {
    const value = e.target.value;
    if (filterComboValue === value) {
      setFilterComboValue("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterComboValue(value);
    var newList = [...backupJson_response.orders];
    newList = newList.filter((row) => row.status == value);
    setJson_response({ ...json_response, orders: newList });
  };

  const click_order_detail = (row, is_edit) => {
    history.push(`${path}/${row.id}`, {
      order_data: row,
      is_edit: is_edit,
      is_new: false,
    });
  };

  async function click_order_delete({ value, params }) {
    const answer = value === "true";
    if (answer) {
      var response = await fetch_backend(
        `orders/${params}/`,
        "DELETE",
        token,
        dispatch
      );
      console.log(response);
      setOpenDeleteConfirmation(false);
      if (response.status === 200) {
        history.go(0);
      } else {
        setOpenDeleteConfirmation(false);
      }
    } else {
      setOpenDeleteConfirmation(false);
    }
  }

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request();
  }, []);

  const generateBarcode = (code) => {
    const canvas = document.createElement('canvas')
    JsBarcode(canvas, code, { format: 'CODE128', displayValue: false })
    return canvas.toDataURL('image/png')
  }

  async function onClickOrderPds(row) {
    setIsLoading(true);
    const url = `orders/${row.id}/`;
    const response = await fetch_backend(url, "GET", token, dispatch);
    setIsLoading(false);
   
    if (response.status !== 200) {
      return;
    }

    const doc = <OrderPdf dataOp={{
      type: row.cost_center,
      orderName: row.order_name,
      orderDate: row.request_date,
      orderNumber: row.work_order,
      b64barcode: generateBarcode(row.work_order),
      items: response?.order?.items
    }} />;
    const asPdf = pdf(); 
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    setfile(blob);
    saveAs(
      blob,
      `Pedido OT ${row.work_order}-${row.order_name}.pdf`
    );
  }

  

  return (
    <div className="wrapped__container_top_bar ">
      <Loading isLoading={isLoading} />
      <ModalQuestion
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        question="¿Está seguro que desea eliminar este pedido?"
        callback={click_order_delete}
        params={indexToDelete}
      />

      <div className="top-bar">
        <h3>Pedidos</h3>
      </div>
      <div className="search_div">
        <div className="search_container" style={{ flex: 0.5 }}>
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={() => onFocusSearch()}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides_tools">
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="unmanaged"
              className="inputselect unmanaged"
              checked={filterComboValue === "unmanaged"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />
            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              Sin gestionar
            </p>
          </div>

          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="managed"
              className="inputselect in_process"
              checked={filterComboValue === "managed"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />

            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              &nbsp;Gestionadas
            </p>
          </div>

          <div className="sub_guide_tools">
            <div
              className="square_agregar_tools"
              onClick={() => {
                history.push(`${path}/new-order`, {
                  order_data: {},
                  is_edit: false,
                  is_new: true,
                });
              }}
            >
              <p className="col_white agregar_label_btn">Nuevo Pedido</p>
            </div>
          </div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th style={{ width: "3%" }} />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Id"
              txt_filter="id"
              width="8%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Nombre Pedido"
              txt_filter="order_name"
              width="24%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Orden de Trabajo"
              txt_filter="work_order"
              width="14%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha Solicitud"
              txt_filter="request_date"
              width="13%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Solicitado Por"
              txt_filter="requested_by"
              width="13%"
            />

            <TableHeader col_name="Editar" width="8%" />
            <TableHeader col_name="Detalle" width="8%" />
            <TableHeader col_name="Pdf" width="8%" />
            <TableHeader col_name="Eliminar" width="8%" />
          </tr>
          {json_response?.orders?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{ backgroundColor: `${row.status === "unmanaged"? "red": "blue"}` }}
                    />
                  </div>
                </td>
                <td>{row.id}</td>
                <td>{row.order_name}</td>
                <td>{row.work_order}</td>
                <td>
                  <p>{row.request_date}</p>
                </td>
                <td>
                  <p>{row.requested_by}</p>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color={row.status === "unmanaged" ? "#266e45" : "#e6e6e6"}
                    className="iconRow"
                    onClick={() => {
                      if (row.status === "unmanaged") {
                        click_order_detail(row, true);
                      }
                    }}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color={row.status === "unmanaged" ? "#e6e6e6" : "#383838"}
                    className="iconRow"
                    onClick={() => {
                      if (row.status === "managed"  || row.status === "close") {
                        click_order_detail(row, false);
                      }
                    }}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    color="#383838"
                    className="iconRow"
                    onClick={() => onClickOrderPds(row)}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faTrash}
                    color={row.status === "unmanaged" ? "#383838" : "#e6e6e6"}
                    className="iconRow"
                    onClick={() => {
                      if (row.status === "unmanaged") {
                        setIndexToDelete(row.id);
                        setOpenDeleteConfirmation(true);
                      }
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export default Home;
