import React, { useState, useEffect } from "react";
import { response_related_orders_mock } from "./responses_mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useCookies } from "react-cookie";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import {
  ModalForm,
} from "../../../App/components/shared/Modals";
import _, { set } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

function ModalEditPoQuantities({ open, callback, onClose, params = {} }) {
  const [fail_validation, setFail_validation] = useState(false);
  const [json_response, setJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({})
  const [itemsToAdd, setItemsToAdd] = useState([{product_code: null, quantity: null}])
  const [buttonState, setButtonState] = useState("Guardar");
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [totals, setTotals] = useState({
    totalQuantity: 0,
    totalModifiedQuantity: 0,
  })
  const [internalOpen, setInternalOpen] = useState(open);
  const [messageModalForm, setMessageModalForm] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);

  const [cookies] = useCookies();
  const token = cookies.token_purchase;
  const [{ actual_url }, dispatch] = useDataLayerValue();
  
  useEffect(() => {
    sendInitRequest()
  }, []);

  async function sendInitRequest() {
    setIsLoading(true);
    var url;
    url = `related-orders/${params.reference.product_code}`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = response_related_orders_mock
    setInitDefaultValues(response.related_orders)
    setJson_response(response);
    setIsLoading(false);
  }

  const setInitDefaultValues = (orders) => {
    let totalQuantity = 0
    orders.forEach((item) => {
      item.modified_quantity = item.quantity
      totalQuantity += item.quantity
    })
    setTotals({totalQuantity: totalQuantity, totalModifiedQuantity: 0})
  }



  const inputChange = (target, index, row, onBlur=false) => {
    let {name, value} = target
    if (onBlur){
      if (!value){
        value = 0
      }
    }
    const items = [...json_response.related_orders]
    items[index][name] = value
    setJson_response({...json_response, related_orders: items})

    let totalModifiedQuantity = 0
    for (let i = 0; i < items.length; i++){
      totalModifiedQuantity += (parseInt(items[i].modified_quantity) || 0) - items[i].quantity
    }
      
    const totals_copy = {...totals}
    totals_copy.totalModifiedQuantity = totalModifiedQuantity
    setTotals(totals_copy)
  }

  const save = async () => {
    const errors = validateFields(json_response.related_orders);

    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    const body = {
      po_id: params.poId,
      related_orders: json_response.related_orders,
    }
    const response = await fetch_backend(
      "modify-po-reference/",
      "POST",
      token,
      dispatch,
      body
    );
    setIsLoading(false);
    if (response.status === 200) {
      setInternalOpen(false);
      setSuccessModalForm(true);
      setMessageModalForm([
        "Operación Exitosa",
      ]);
      setOpenModalForm(true)
    }
  }

  const validateFields = (items) => {
    const errors = {};
    const itemsErrors = [];
    items.forEach((item, index) => {
    if (item.modified_quantity > item.quantity){
      itemsErrors.push(`La cantidad modificada no puede ser mayor a la cantidad pedida. Fila: ${index +1}`)
    }
    })
    if (itemsErrors.length > 0){
      errors.items = itemsErrors
    }
      
    return errors;
  };

  const onCloseModalForm = () => {
    if (successModalForm){
      setOpenModalForm(false)
      setInternalOpen(false)
      onClose(true)
    } else {
      setOpenModalForm(false)
      setInternalOpen(true)
    }
  }



  return (
    <>
    <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
    <Dialog
      open={internalOpen}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
      fullWidth={true}
      onClose={()=> onClose(false)}
    >
      
      <div className="modal_return_container" style = {{margin: "0 auto", width: "85%"}}>
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h4>Novedades O.C</h4>
        </DialogTitle>
        <p> Referencia: {params.reference.product_code} - {params.reference.description} - {params.reference.unit}</p>  
      </div>
      <br />

      <div style = {{margin: "0 auto", width: "85%"}}>
        <table style={{width: "100%"}}>
              <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
                <th style={{ width: "5%", verticalAlign: "middle"}}>No.</th>
                <th style={{ width: "15%", verticalAlign: "middle"}}>Orden de Trabajo</th>
                <th style={{ width: "15%", verticalAlign: "middle"}}>Nro. Pedido</th>
                <th style={{ width: "50%", verticalAlign: "middle"}}>Nombre Pedido</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Solicitado Por</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Cantidad Pedida</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. A Pedir</th>
              </tr>
              {json_response?.related_orders?.map((row, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.work_order}</td>
                  <td>{row.order_id}</td>
                  <td>{row.order_name}</td>
                  <td>{row.requested_by}</td>
                  <td>{row.quantity}</td>
                  <td style={{padding: "2px 2px"}}>
                    <input
                      type="number"
                      name="modified_quantity"
                      className="my_input input_center"
                      value={row.modified_quantity}
                      onChange={(event) => inputChange(event.target, index, row)}
                      onBlur={(event) => inputChange(event.target, index, row, true)}
                    />
                  </td>
                  
                </tr>
              ))}
        </table>
        <div style={{width: "100%", textAlign: "right"}}>
          <p style={{marginTop: "10px"}}>Total Cantidad Pedida Inicial: {totals.totalQuantity}</p>
          <p style={{marginTop: "5px"}}>Total Cantidades Modificadas: {totals.totalModifiedQuantity}</p>
          <p style={{marginTop: "5px"}}>Total Cantidad a Pedir: {totals.totalQuantity + totals.totalModifiedQuantity}</p>
        </div>
       
        <div className="simple_flex_" style={{justifyContent: "flex-end", marginTop: "30px"}}>
            <div className="input_tag_addclient">
            <button
            style={{
              padding: "3px 15px",
              margin: "0 0 0 auto",
            }}
            className="button"
            onClick={() => save()}
          >
            Guardar
      </button>
            </div>
        </div>
      </div>
     

      <DialogActions>

      </DialogActions>
    </Dialog>
    </>
  );
}

export default ModalEditPoQuantities;