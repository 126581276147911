const response_get_mock = {
  purchase_orders: [
    {
      id: 1,
      status: "in_process",
      supplier: "Homecenter",
      user: "cesar jo",
      purchase_order_id: 10,
      expected_date: "2022-10-10",
      date: "2022-10-10",
      issues: [
        {
          id: 3,
          product_code: "ca111",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          issue: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          quantity: 5,
        },
        {
          id: 4,
          product_code: "ca111",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          issue: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          quantity: 5,
        },
      ],
    },
    {
      id: 2,
      status: "unmanaged",
      supplier: "Homecenter",
      user: "cesar Tabares",
      purchase_order_id: 8,
      expected_date: "2022-10-08",
      date: "2022-10-10",
      issues: [],
    },
    {
      id: 3,
      status: "managed",
      supplier: "Homecenter",
      user: "cesar jo",
      purchase_order_id: 12,
      expected_date: "2022-10-15",
      date: "2022-10-10",
      issues: [
        {
          id: 5,
          product_code: "nnnn",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          issue: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          quantity: 5,
        },
        {
          id: 6,
          product_code: "xxxx",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          issue: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          quantity: 5,
        },
      ],
    },
  ],
};

const response_mock_po_detail = {
  purchase_order_info: {
    id:5,
    date: "2024-01-01",
    linked_quote_ids: [3, 5, 6],
    supplier_name: "Sodimac",
    government_id: "1111",
    phone: "222222",
    email: "c.tabares15@hotmail.com",
    address: "aaaaaaa",
    auto_retainer: true,
    payment_method: "Credito",
    payment_term: "60 dias",
    delivery_address: "cra 50",
    buyer_name: "Loza",
    buyer_phone: "333333333",
    status: "unmanaged",
    contact: {
      "id": 1,
      "name": "Cesar Tabares",
      "email": "hola@hola.com"
    }
  },
  items: [
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
    {
      id: 30,
      product_code: "1111111",
      description: "aaaaa",
      order_id: 10,
      unit: "und",
      quantity: 13,
      price: 10000
    },
  ],
  supplier_contacts: [
    {
      "id": 1,
      "name": "Cesar Tabares",
      "email": "hola@hola.com"
    },
    {
      "id": 2,
      "name": "Esteban Lozada",
      "email": "loz@hola.com"
    }
  ]
}

const response_related_orders_mock = {
  related_orders: [
    {
      id: 1,
      work_order: "PTY-00123",
      order_name: "El pedido de la obra",
      requested_by: "Cesar Tabares",
      quantity: 10
    },
    {
      id: 4,
      work_order: "PTY-1111",
      order_name: "El pedidoooooooo",
      requested_by: "Cesar Tabares",
      quantity: 20
    }
  ]
}

export { response_get_mock, response_mock_po_detail, response_related_orders_mock };
