import React, { useState, useEffect } from "react";
import { mockReferences } from "./mockRequest";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
import {
  styles_react_select,
} from "../../../App/components/shared/Select";
import { useCookies } from "react-cookie";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import {
  ModalForm,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

function ModalAddInput({ open, callback, onClose, params = "" }) {
  const [fail_validation, setFail_validation] = useState(false);
  const [json_response, setJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({})
  const [itemsToAdd, setItemsToAdd] = useState([{product_code: null, quantity: null}])
  const [buttonState, setButtonState] = useState("Guardar");
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);

  const [cookies] = useCookies();
  const token = cookies.token_purchase;
  const [{ actual_url }, dispatch] = useDataLayerValue();
  
  useEffect(() => {
    sendInitRequest()
  }, []);

  async function sendInitRequest() {
    setIsLoading(true);
    var url;
    url = `stock-inputs/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    // var response = mockReferences
    setJson_response(response);
    buildOptions(response)
    setIsLoading(false);
  }

  const buildOptions = (response) => {
    let options = {};
    let references = []
    
    if (response.references){
    for (const ref of response.references) {
      references.push({ value: ref.product_code, label: ref.product_code, description: ref.description, unit: ref.unit});
    }

    options.references = references
    setOptions(options)
  }
 }
 
 const addNewTableRow = () => {
  let tableRows_ = [...itemsToAdd];
  const errors = validateRequiredFields([tableRows_[tableRows_.length - 1]]);

    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
  tableRows_.push({
    product_code: null,
    quantity: 0,
  });
  setItemsToAdd(tableRows_);
}; 

const removeTableRow = (index) => {
  let tableRows_ = [...itemsToAdd];
  tableRows_.splice(index, 1);
  setItemsToAdd(tableRows_);
} 

const ChangeReactSelectInput = (value, index) => {
  const items = [...itemsToAdd]
  items[index].description = value.description
  items[index].product_code = value.value
  items[index].unit = value.unit
  items[index].quantity = value.quantity
  setItemsToAdd(items)
}

const inputChange = (value, index, key) => {
  const items = [...itemsToAdd]
  items[index][key] = value.value
  setItemsToAdd(items)
}

const save = async () => {
  const errors = validateRequiredFields(itemsToAdd);

    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
  
  let closePo
  if (buttonState === "Guardar y Cerrar O.C"){
    closePo = true
  } else {
    closePo = false
  }
  
  const response = await fetch_backend(
    "purchase-add-input/",
    "POST",
    token,
    dispatch,
    {items: itemsToAdd, closePo: closePo}
  );
  setIsLoading(false);
  if (response.status === 200) {
    setSuccessModalForm(true);
    setMessageModalForm([
      "Operación Exitosa",
    ]);
    setOpenModalForm(true)
  }
}

const validateRequiredFields = (allValues) => {
  const errors = {};
  const ordersErrors = [];
  allValues.forEach((item, index) => {
  if (!item.product_code) {
    errors.client = `Falta seleccionar 'Código'. Fila: ${index +1}`;
  }
  if (!item.quantity) {
    errors.quote = `Falta diligenciar 'Cantidad'. Fila: ${index +1}`;
  }
  })
    
  return errors;
};

const onCloseModalForm = () => {
  if (successModalForm){
    setOpenModalForm(false)
    onClose()
  } else {
    setOpenModalForm(false)
  }
}



  return (
    <Dialog
      onClose={() => {setItemsToAdd([{product_code: null, quantity: null}]); onClose()}}
      open={open}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
        fullWidth={true}
      onEnter={()=>setItemsToAdd([{product_code: null, quantity: null}])}
    >
      <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <div className="modal_return_container">
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>Ingreso sin OC</h3>
        </DialogTitle>
      </div>
      <br />
      <div style = {{margin: "0 auto", width: "85%"}}>

        <table style={{width: "100%"}}>
              <tr style={{backgroundColor: "#78BA94", height: "40px", textAlign:"center"}}>
                <th style={{ width: "15%", verticalAlign: "middle"}}>Código</th>
                <th style={{ width: "50%", verticalAlign: "middle"}}>Descripción</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Unidad</th>
                <th style={{ width: "10%", verticalAlign: "middle"}}>Cant. Ingresar</th>
                <th style={{ width: "1%", border: 0, backgroundColor: "white" }}></th>
              </tr>
              {itemsToAdd.map((row, index) => (
                <tr>
                  <td>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Seleccione..."
                      isSearchable={true}
                      options={options.references}
                      styles={styles_react_select}
                      onChange={(value) =>
                        ChangeReactSelectInput(value, index, "description")
                      }
                      // value={
                      //   tableRows[index].code?.value
                      //     ? tableRows[index].code
                      //     : null
                      // }
                    />
                  </td>
                  <td>{row.description}</td>
                  <td>{row.unit}</td>
                  <td style={{padding: "2px 2px"}}>
                    <input
                      type="number"
                      name="quantity"
                      className="my_input input_center"
                      value={itemsToAdd[index].quantity}
                      // disabled={!is_edit && !is_new ? true : false}
                      onChange={(event) => inputChange(event.target, index, "quantity")}
                    />
                  </td>
                  <td
                    style={{ border: 0, textAlign: "left", paddingLeft: "20px" }}
                    onClick={() => removeTableRow(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="s"
                      color={itemsToAdd.length > 1 ? "red": "grey"}
                    />
                  </td>
                </tr>
              ))}
        </table>
        <div
          className="simple_square_plus"
          onClick={() => addNewTableRow()}
          style={{marginLeft: "auto", marginTop: "10px"}}
        >
          <h4 className="col_white">+</h4>
        </div>
        <div className="simple_flex_" style={{justifyContent: "flex-end", marginTop: "30px"}}>
            <div className="input_tag_addclient">
              <div className="div_button_list">
                <div className="button-text" onClick={() => save()}>
                  <p style={{ fontSize: "18px" }}>{buttonState}</p>
                </div>
                <div className="button-arrow">
                  <select
                    onChange={(event) => setButtonState(event.target.value)}
                  >
                    <option value="Guardar">Guardar</option>
                    <option value="Guardar y Cerrar O.C">Guardar y Cerrar O.C</option>
                  </select>
                </div>
              </div>
            </div>
        </div>
      </div>
     

      <DialogActions>
        {/* <button
          type="button"
          value={true}
          name="aceptar"
          onClick={(e) => click_button(e)}
          style={{ padding: "5px 15px", fontSize: "18px" }}
        >
          Aceptar
        </button>
        <button
          type="button"
          name="cancelar"
          value={false}
          style={{ padding: "5px 15px", fontSize: "18px" }}
          onClick={(e) => click_button(e)}
        >
          Cancelar
        </button> */}
        
      </DialogActions>
    </Dialog>
  );
}

export default ModalAddInput;
