import React, { useState, useEffect } from "react";
import "./QuoteAdd.css";
import ScriptTag from "react-script-tag";
import { useLocation, Link, ReactDOM } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Prompt } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { Quote_pdf, View_pdf } from "./Quote_pdf";
import { saveAs } from "file-saver";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import { Departments, Mpos } from "../../../App/services/services";

import {
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faMinusCircle,
  faPlus,
  faCopy,
  faAlignJustify,
} from "@fortawesome/free-solid-svg-icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Cookies from "universal-cookie";
import { CountryDropdown } from "react-country-region-selector";
import Select from "react-select";
import { Cities } from "../../../App/services/services";
import moment from "moment";
import { url_crm_clients, url_crm_quote } from "../../constants/constants";

function QuoteAdd() {
  const location = useLocation();
  const json_backwindow = location.state?.state.json_response;

  var observaciones_defecto = (global.cancel_rut = false);

  if (json_backwindow?.data_head === undefined) {
    var cliente_ini = "";
    var contact_ini = "";
    var fecha_inicial = moment().format("YYYY-MM-DD");
    var destino_ini = "";
    var email_ini = "";
    var departamento_ini = "";
    var municipio_ini = "";
    var encabezado_ini = `Reciba un cordial saludo, espero se encuentre muy bien. A continuación, detallo la propuesta comercial según su requerimiento.\n
Si desea conocer más acerca de nosotros, haga click en cualquiera de los iconos correspondientes a nuestras redes. \n`;
    var observaciones_ini =
      "Nosotros prestamos un servicio logístico. Cotización valida por 15 días. ALQUILER: La forma de pago es mes anticipado, al inicio se cobra un canon adicional correspondiente al 70% del valor del alquiler (Deposito de Garantías), monto que será devuelto en el momento que se devuelvan los ítems alquilados en las mismas condiciones en las que fueron entregados. VENTA: Se debe hacer un anticipo del 60% para dar inicio a las adecuaciones que sean requeridas, y se debe pagar el monto restante previo a la entrega, de lo contrario el contenedor no podrá ser despachado. TRANSPORTE: El valor estipulado en esta cotización se mantendrá si y solo si se recibe el contenedor dentro del plazo de tiempo establecido y acordado, en caso de haber retrasos en la recepción de éste, y se generen sobre costos, se deberá hacer un ajuste en el cobro del transporte desde $70.000 hasta $180.000 (dependiendo del tipo de transporte) por cada hora adicional. ALMACENAMIENTO: En caso de que el cliente no pueda recibir el producto o proyecto en los tiempos establecidos, se le darán 5 días corridos para recibirlo como gabela. Cumplido este periodo de tiempo se le cobrará el espació en patios correspondiente a una tarifa que va desde los $15.000 hasta los $30.000 por día de almacenamiento, dependiendo del tamaño. CONDICIONES: En caso de retractarse de algún contrato o pago generado, y de solicitar una devolución, se descontará el 5% sobre el pago hecho, por gastos administrativos. Esta cotización NO incluye póliza, en caso de ser requerida se le sumará a la cotización. Sujetos a disponibilidad.";
  } else {
    var cliente_ini = {
      value: json_backwindow?.data_head.id_cliente.pk,
      label: json_backwindow?.data_head.id_cliente.nombre_cliente,
    };

    var contact_ini = {
      value: json_backwindow?.data_head.id_contacto.pk,
      label: json_backwindow?.contacto_nombre,
    };
    var email_ini = json_backwindow?.data_head.id_contacto.email;
    var destino_ini = json_backwindow?.data_head.ciudad;
    var encabezado_ini = json_backwindow?.data_head.encabezado;
    var observaciones_ini = json_backwindow?.data_head.observaciones;
    var fecha_inicial = json_backwindow?.data_head.fecha;
    var departamento_ini = json_backwindow?.data_head.departamento;
    var municipio_ini = json_backwindow?.data_head.municipio;
  }

  if (json_backwindow?.flag_edit === undefined) {
    var flag_edit = false;
  } else {
    var flag_edit = true;
  }

  if (json_backwindow?.data_detail === undefined) {
    var initInputList = [
      {
        no: "1",
        item: "",
        tipo: "",
        producto: "",
        indexProducto: "",
        descripcion: "",
        cant: 0,
        valorUnit: "",
        valorBruto: "",
        dcto: "",
        iva: "",
        valorTotal: "",
      },
    ];
    var initFilteredProducts = [];
  } else {
    var initInputList = json_backwindow?.data_detail;
    var initFilteredProducts = json_backwindow.initFilteredProducts;
  }

  if (json_backwindow?.data_totals === undefined) {
    var total_labels = {
      superBruto: 0,
      superDescuento: 0,
      superSubtotal: 0,
      superIva: 0,
      superTotal: 0,
    };
  } else {
    var total_labels = json_backwindow?.data_totals;
  }

  if (json_backwindow?.initial_row === undefined) {
    var initial_row = 1;
  } else {
    var initial_row = json_backwindow?.initial_row;
  }
  if (location.state?.state.tipo === "Juridica") {
    var flag_edit2 = true;
    if (location.state?.state.razon_social !== "") {
      cliente_ini = {
        value: location.state?.state.pk_cliente,
        label: location.state?.state.razon_social,
      };

      contact_ini = {
        value: location.state?.state.pk_contacto,
        label: location.state?.state.nombre_cliente,
      };
    }
    if (location.state?.state.email_cliente !== "") {
      email_ini = location.state?.state.email_cliente;
      console.log(email_ini);
    }
  } else if (location.state?.state.tipo === "Natural") {
    if (location.state?.state.nombre_cliente !== "") {
      var flag_edit2 = true;
      cliente_ini = {
        value: location.state?.state.pk_cliente,
        label: location.state?.state.nombre_cliente,
      };

      contact_ini = {
        value: location.state?.state.pk_contacto,
        label: location.state?.state.nombre_cliente,
      };

      if (location.state?.state.email_cliente !== "") {
        email_ini = location.state?.state.email_cliente;
        console.log(email_ini);
      }
    }
  } else {
    var flag_edit2 = false;
  }

  //cookies variables
  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");

  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [file, setfile] = useState();

  //dialogs and usestate variables
  const [responseQuoteInfo, setResponseQuoteInfo] = useState();
  const [responseContacts, setResponseContacts] = useState();
  const [responseQuoteNo, setResponseQuoteNo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const [openDialogemail, setOpenDialogEmail] = useState(false);
  const [subject, SubjectsetEmail] = useState("");
  const [copyemail, copysetEmail] = useState("");
  const [nombre_contacto_email, setNombre_contacto_email] = useState("");
  const [bodyemail, bodysetEmail] = useState();

  //related table variables
  const [rowCounter, setRowCounter] = useState(initial_row);
  const [superTotals, setSuperTotals] = useState(total_labels);
  const [inputList, setInputList] = useState(initInputList);
  const [filteredProducts, setFilteredProducts] =
    useState(initFilteredProducts);

  //other variables
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editContacto, setEditContacto] = useState(true);
  const [buttonState, setButtonState] = useState("GenerarPDF");
  const [cliente, setCliente] = useState(cliente_ini);
  const [contacto, setContacto] = useState(contact_ini);
  const [correo, setCorreo] = useState(email_ini);
  const [email, setEmail] = useState(correo);
  const [vendedor, setVendedor] = useState("");
  const [celular_vendedor, setcelVendedor] = useState("");
  const [fecha, setFecha] = useState(fecha_inicial);
  const [pais, setPais] = useState("Colombia");
  const [dpto, setDpto] = useState(departamento_ini);
  const [mpo, setMpo] = useState(municipio_ini);
  const [list_mpos, setList_mpos] = useState();
  const [destino, setDestino] = useState(destino_ini);
  const [encabezado, setEncabezado] = useState(encabezado_ini);
  const [observaciones, setObservaciones] = useState(observaciones_ini);
  const [shouldBlockNavigation, setBlock] = useState(true);
  const [isCopy, setIsCopy] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [openWarningDate, setOpenWarningDate] = useState(false);

  const doSomething = () => {
    var input;
    input = window.confirm("Desea salir sin guardar los cambios?");
    global.cancel_rut = input;

    return input;
  };
  

  useEffect(() => {
    
  }, [])

  

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    setIsLoading(true);
    if (json_backwindow?.data_head !== undefined) {
      getContacts(json_backwindow?.data_head.id_cliente.pk);
    }

    fetch(`${global.url_back}/get_quote_default/${codigo_vendedor}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseQuoteInfo(json_response);
            setIsLoading(false);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setBlock(false);
          setOpenDialog(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setBlock(false);
        setOpenDialog(true);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    get_mpos(dpto);
    setMpo(municipio_ini);
  }, [departamento_ini]);

  const get_mpos = (dpto) => {
    setDpto(dpto);
    setMpo("");
    const filtered_mpos = Mpos(dpto);
    setList_mpos(filtered_mpos);
  };

  const getContacts = (pk) => {
    setIsLoading(true);
    fetch(`${global.url_back}/get_contacts_client/?pk=${pk}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseContacts(json_response);
            setIsLoading(false);
          });
        } else {
          console.log("error UserConfig- fetch contacts");
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
        setIsLoading(false);
      });
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleInput_item_tipo = (event, index) => {
    // console.log(index);
    const { name, value } = event.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index]["indexProducto"] = "-";
    list[index]["producto"] = "";
    list[index]["descripcion"] = "";
    list[index]["valorUnit"] = 0;
    list[index]["cant"] = 0;
    list[index]["dcto"] = "";
    list[index]["iva"] = "";
    list[index]["valorBruto"] = 0;
    list[index]["valorTotal"] = 0;

    setInputList(list);
    getValores(index);
    // console.log(responseQuoteInfo?.products);
    // console.log(responseQuoteInfo?.types);

    if (name === "item") {
      var filter = responseQuoteInfo?.products.filter(function (v, i) {
        return (
          v["tipo_producto"]["nombre"] === list[index]["tipo"] &&
          v["item"] == value
        );
      });
    } else if (name === "tipo") {
      var filter = responseQuoteInfo?.products.filter(function (v, i) {
        return (
          v["tipo_producto"]["nombre"] === value &&
          v["item"] == list[index]["item"]
        );
      });
    }

    const filtered_list = [...filteredProducts];

    filtered_list[index] = filter;
    setFilteredProducts(filtered_list);
    // console.log(filtered_list);
    // console.log(filteredProducts);
  };

  const handleInput_ProductChange = (event, index) => {
    const { value, name } = event.target;

    const list = [...inputList];
    const nombre = filteredProducts[index][value]?.nombre;
    const descripcion = filteredProducts[index][value]?.descripcion;
    const valorUnit = filteredProducts[index][value]?.valor_unit;
    const dcto = filteredProducts[index][value]?.descuento;
    const iva = filteredProducts[index][value]?.iva;
    list[index][name] = value;
    list[index]["producto"] = nombre;
    list[index]["descripcion"] = descripcion;
    list[index]["valorUnit"] = valorUnit;
    list[index]["dcto"] = dcto;
    list[index]["iva"] = iva;

    setInputList(list);
    getValores(index);
  };

  const getValores = (index) => {
    const list = [...inputList];

    const cant = parseFloat(list[index]["cant"]);
    const vlrUnit = parseFloat(list[index]["valorUnit"]);
    var total_br = cant * vlrUnit;
    total_br = Math.round(total_br);
    const dcto = parseFloat(list[index]["dcto"]);
    const iva = parseFloat(list[index]["iva"]);
    var total = total_br * (1 - dcto / 100) * (1 + iva / 100);
    total = Math.round(total);
    list[index]["valorBruto"] = total_br;
    list[index]["valorTotal"] = total;

    let superBruto = 0;
    let superDescuento = 0;
    let superSubtotal = 0;
    let superIva = 0;
    let superTotal = 0;

    list.forEach((element) => {
      let { valorBruto, dcto, iva, valorTotal } = element;
      dcto = dcto / 100;
      iva = iva / 100;

      superBruto += valorBruto;
      superDescuento += dcto * valorBruto;
      superSubtotal += valorBruto * (1 - dcto);
      superIva += iva * (valorBruto * (1 - dcto));
      superTotal += valorBruto * (1 - dcto) * (1 + iva);
    });

    setSuperTotals({
      superBruto: Math.round(superBruto),
      superDescuento: Math.round(superDescuento),
      superSubtotal: Math.round(superSubtotal),
      superIva: Math.round(superIva),
      superTotal: Math.round(superTotal),
    });

    setInputList(list);
  };

  const handle_with_change_vlrbruto = (event, index) => {
    const { name, value } = event.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    getValores(index);
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const validation = () => {
    const list = [...inputList];
    const last_item = list[list.length - 1];
    if (
      last_item?.item === "" ||
      last_item?.tipo === "" ||
      last_item?.producto === "" ||
      last_item?.cant === "" ||
      cliente === "" ||
      contacto === "" ||
      last_item?.cant == 0
    ) {
      return false;
    } else {
      console.log(last_item?.cant);
      return true;
    }
  };

  const validation2 = () => {
    if (email === "" || subject === "" || bodyemail === "") {
      return false;
    } else {
      console.log(email);
      console.log(subject);
      console.log(bodyemail);
      console.log("queee");
      return true;
    }
  };

  const addRow = () => {
    if (validation()) {
      setInputList([
        ...inputList,
        {
          no: rowCounter + 1,
          item: "",
          tipo: "",
          producto: "",
          indexProducto: "",
          descripcion: "",
          cant: 0,
          valorUnit: "",
          valorBruto: "",
          dcto: "",
          iva: "",
          valorTotal: "",
        },
      ]);
      setFilteredProducts([...filteredProducts, []]);
      setRowCounter(rowCounter + 1);
    } else {
      setOpenDialog2(true);
    }
  };

  const deleteRow = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const filtered = [...filteredProducts];
    filtered.splice(index, 1);
    setFilteredProducts(filtered);

    setRowCounter(rowCounter - 1);
    let superBruto = 0;
    let superDescuento = 0;
    let superSubtotal = 0;
    let superIva = 0;
    let superTotal = 0;

    list.forEach((element) => {
      let { valorBruto, dcto, iva, valorTotal } = element;
      dcto = dcto / 100;
      iva = iva / 100;

      superBruto += valorBruto;
      superDescuento += dcto * valorBruto;
      superSubtotal += valorBruto * (1 - dcto);
      superIva += iva * (valorBruto * (1 - dcto));
      superTotal += valorBruto * (1 - dcto) * (1 + iva);
    });

    setSuperTotals({
      superBruto: superBruto,
      superDescuento: superDescuento,
      superSubtotal: superSubtotal,
      superIva: superIva,
      superTotal: superTotal,
    });
  };

  const [responseQuoteCopy, setResponseQuoteCopy] = useState();
  const [openDialog_copy, setOpenDialog_copy] = useState(false);

  const onCloseModaConfirmCopy = () => {
    setOpenConfirmCopy(false);
  };

  const makeCopy = () => {
    setIsSubmitting(true);
    fetch(`${global.url_back}/copy_quote/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nro_cot: json_backwindow?.data_head.nro_cotizacion,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseQuoteCopy(json_response);
            setOpenDialog_copy(true);
            setIsSubmitting(false);
            setBlock(false);
            window.onbeforeunload = undefined;

            setIsCopy(true);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
        setIsSubmitting(false);
      });
  };

  const callbackModalConfirmCopy = ({ value, params }) => {
    if (value === "true") {
      setOpenConfirmCopy(false);
      makeCopy();
    } else {
      setOpenConfirmCopy(false);
    }
  };

  const callbackModalWargningDate = ({ value, params }) => {
    if (value === "true") {
      setOpenWarningDate(false);
      sendQuote();
      setOpenDialogEmail(true);
    } else {
      setOpenWarningDate(false);
    }
  };

  async function callpdf(props_pdf) {
    const doc = <Quote_pdf {...props_pdf} />;
    const asPdf = pdf(); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    setfile(blob);
    saveAs(
      blob,
      `Cotizacion Nro. ${props_pdf.nro_cot} - ${props_pdf.cliente}.pdf`
    );
  }

  const sendQuote = () => {
    //avoid double requests
    if (isSubmitting) {
      return;
    }

    if (validation()) {
      setBlock(false);
      setIsSubmitting(true);
      if (flag_edit2) {
        var method_s = "POST";
      } else {
        var method_s = "PUT";
      }
      fetch(`${global.url_back}/quote/`, {
        method: `${flag_edit ? method_s : "POST"}`,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          cliente: cliente,
          fecha: fecha,
          contacto: contacto,
          pais: pais,
          departamento: dpto,
          municipio: mpo,
          destino: destino,
          correo: correo,
          encabezado: encabezado,
          observaciones: observaciones,
          tabla: inputList,
          codigo_vendedor: codigo_vendedor,
          supersubtotal: superTotals.superSubtotal,
          nro_cot: json_backwindow?.data_head.nro_cotizacion,
          button_state: buttonState,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              console.log(json_response);
              setResponseQuoteNo(json_response.nro_cotizacion);
              setIsSubmitting(false);
              setFetchSuccess(true);
              setOpenDialog(true);
              if (buttonState === "GenerarPDF") {
                setNombre_contacto_email(json_response.nombre_contacto);
                setEmail(correo);

                SubjectsetEmail(
                  `Cotización ${json_response.nro_cotizacion} [Contenedores de Antioquia]`
                );
                bodysetEmail(`Buen día ${
                  json_response.genero == "Femenino" ? "Srta." : "Sr."
                } ${
                  json_response.nombre_contacto
                },\n\nEspero se encuentre muy bien, gracias por contactar a Contenedores de Antioquia S.A.S. Adjunto se encuentra la propuesta comercial según su requerimiento. Cualquier pregunta o inquietud no dude en contactarse con nosotros.\n\n\nEn caso de aceptar la propuesta comercial, por favor responder este correo en copia a info@contenedoresdeantioquia.com con la siguiente información:\n\n1. Orden de Servicio o Compra (Si aplica)\n2. Correo de facturación electrónica.\n3. Dirección exacta del destino del producto.\n4. Nombre y Celular de persona que recibe el producto en sitio.
                \n\n${json_response.nombre_vendedor}\n${
                  json_response.cargo || "Ejecutivo Comercial"
                }\n${json_response.celular}\n${
                  json_response.email
                }\nDirección: Km1 entrada a Girardota, Antioquia. (Colombia)\nwww.contenedoresdeantioquia.com
`);
                setVendedor(json_response.nombre_vendedor);
                setcelVendedor(json_response.celular);

                const props_pdf = {
                  nro_cot: json_response.nro_cotizacion,
                  cliente: json_response.cliente_nombre,
                  contacto: json_response.nombre_contacto,
                  cargo: json_response.cargo,
                  genero: json_response.genero,
                  nit: json_response.nit,
                  fecha: fecha,
                  table_data: inputList,
                  subtotals: superTotals,
                  nombre_vendedor: json_response.nombre_vendedor,
                  celular: json_response.celular,
                  email: json_response.email,
                  observaciones: observaciones,
                  encabezado: encabezado,
                };
                callpdf(props_pdf);
              }
            });
          } else {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog(true);
            console.log("error fetchingform-sendQuote");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
          console.log(error);
        });
    } else {
      setOpenDialog2(true);
    }
  };

  const sendEmail = () => {
    var data = new FormData();

    data.append("file", file);
    data.append("email", email);
    data.append("nombre", nombre_contacto_email);
    data.append("departamento", dpto);
    data.append("municipio", mpo);
    data.append("nombre", nombre_contacto_email);
    data.append("subject", subject);
    data.append("email_message", bodyemail);
    data.append("email_copy", copyemail);
    data.append("name_sale", vendedor);
    data.append("cel_sale", celular_vendedor);
    data.append("codigo_vendedor", codigo_vendedor);

    data.append(
      "quote_name",
      `Cotizacion Nro.${JSON.stringify(responseQuoteNo)}`
    );

    setIsSubmitting(true);

    if (email != "" || subject != "" || bodyemail != "") {
      fetch(`${global.url_back}/send_mail/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: data,
      })
        .then((response) => {
          if (response.status === 200) {
            setOpenDialog4(true);
            setIsSubmitting(false);
            setFetchSuccess(true);
          } else {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog4(true);
            console.log("error sendEmail");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog4(true);
          console.log(error);
        });
    } else {
      setOpenDialog3(true);
      setIsSubmitting(false);
    }
  };

  const flowSaveQuote = () => {
    if (json_backwindow != undefined && !changeDate) {
      setOpenWarningDate(true);
    } else {
      sendQuote();
      setOpenDialogEmail(true);
    }
  };

  return (
    <div className="container__quoteadd">
      <Dialog
        onClose={() => {
          setOpenDialog4(false);

          history.push(url_crm_quote);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog4}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" color="green" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? `Correo enviado con éxito`
              : "Algo salio mal, intenta nuevamente"}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog_copy(false);
          history.push(`${url_crm_quote}/quoteadd`, {
            state: { json_response: responseQuoteCopy },
          });
          window.location.reload();
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog_copy}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faCheckCircle} size="2x" color="green" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copia Correcta. No Cotizacion:{responseQuoteCopy?.copy_no}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog2(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog2}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Faltan campos por diligenciar.</DialogContentText>
          <DialogContentText>
            Recuerda diligenciar al menos los campos "Cliente", "Contacto" y la
            totalidad de los campos en la tabla de detalle (no es permitido
            dejar una cantidad en 0).
          </DialogContentText>
          <DialogContentText></DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog3(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog3}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Faltan campos por diligenciar.</DialogContentText>
          <DialogContentText>
            Recuerda dligenciar todos los campos para enviar el correo.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog(false);
          history.push(url_crm_quote);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <>
          <DialogTitle id="simple-dialog-title">
            {fetchSuccess ? (
              <>
                <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                Cotizacion creada con Exito. Nro Cotizacion:
                {responseQuoteNo}
              </>
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
            )}
          </DialogTitle>

          <DialogContent>
            {fetchSuccess ? (
              buttonState === "GenerarPDF" ? (
                <>
                  <p>Para:</p>

                  <input
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                  <br></br>
                  <br></br>
                  <p>Asunto:</p>

                  <input
                    type="text"
                    onChange={(event) => SubjectsetEmail(event.target.value)}
                    value={subject}
                  />
                  <br></br>
                  <br></br>

                  <p>Con Copia a:</p>

                  <input
                    type="email"
                    onChange={(event) => copysetEmail(event.target.value)}
                  />
                  <br></br>
                  <br></br>

                  <p>Mensaje:</p>
                  <textarea
                    type="text"
                    onChange={(event) => bodysetEmail(event.target.value)}
                    style={{ whiteSpace: "pre-wrap" }}
                    value={bodyemail}
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      className="btn-userconfig"
                      onClick={() => {
                        sendEmail();
                      }}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="fa-spin"
                          style={{ fontSize: "25px" }}
                        />
                      ) : (
                        "Enviar"
                      )}
                    </button>

                    <button
                      className="btn-userconfig"
                      onClick={() => history.push(url_crm_quote)}
                    >
                      Cancelar
                    </button>
                  </div>
                </>
              ) : null
            ) : (
              <p>Algo salio mal, intenta nuevamente</p>
            )}
          </DialogContent>
        </>
      </Dialog>

      <ModalQuestion
        open={openConfirmCopy}
        question="¿Está seguro que desea copiar esta cotización?"
        callback={callbackModalConfirmCopy}
        onClose={onCloseModaConfirmCopy}
      />

      <ModalQuestion
        open={openWarningDate}
        onClose={() => setOpenWarningDate(false)}
        question="¿Está seguro que desea continuar sin actualizar la fecha?"
        callback={callbackModalWargningDate}
      />

      <div className="div-header-quote">
        <div className="header-logo-btns">
          <div className="logo-name">
            <img
              src={require("../../../App/images/clipbardplus_grey.svg")}
              alt=""
            />
            <h4>Cotizaciones</h4>
          </div>
          {flag_edit ? (
            <div className="btn-header">
              <button onClick={() => setOpenConfirmCopy(true)}>
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  <FontAwesomeIcon icon={faCopy} />
                )}
              </button>
            </div>
          ) : (
            <div className="btn-header">
              <Link to={`${url_crm_clients}/clientadd`}>
                <button className="special_hover">
                  <img
                    src={require("../../../App/images/industryplus_white.svg")}
                    alt=""
                  />
                </button>
              </Link>
            </div>
          )}
        </div>
        {json_backwindow?.data_head.ciudad !== undefined ? (
          <div className="header-quoteNo">
            <h3>
              No. Cotizacion:{" "}
              <span style={{ color: "#383838" }}>
                {json_backwindow?.data_head.nro_cotizacion}
              </span>
            </h3>
          </div>
        ) : null}
      </div>

      <form>
        <div className="quoteadd_flex">
          <div className="quoteadd_flex__6">
            <div className="input_tag_addclient">
              <p className="labeltag">Cliente</p>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                defaultValue={cliente}
                isLoading={isLoading}
                isSearchable={true}
                name="color"
                options={responseQuoteInfo?.names}
                styles={colourStyles}
                onChange={(value) => {
                  setCliente(value.value);
                  //setContact states to empty if the client change
                  setEditContacto(false);
                  setContacto("");
                  setCorreo("");
                  getContacts(value.value);
                }}
              />
            </div>
          </div>
          <div className="quoteadd_flex__2">
            <div className="input_tag_addclient">
              <p className="labeltag">Pais</p>

              <CountryDropdown
                value={pais}
                onChange={(val) => setPais(val)}
                style={{ marginBottom: "15px" }}
              />
            </div>
          </div>
          <div className="quoteadd_flex__2">
            <div className="input_tag_addclient">
              <p className="labeltag">Fecha</p>
              <input
                type="date"
                className="input__info full-width"
                value={fecha}
                onChange={(event) => {
                  setChangeDate(true);
                  setFecha(event.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="quoteadd_flex">
          <div className="quoteadd_flex__6">
            <div className="input_tag_addclient">
              <p className="labeltag">Contacto</p>
              {(flag_edit || flag_edit2) && editContacto ? (
                <div onClick={() => setEditContacto(false)}>
                  <input
                    type="text"
                    className="input__info full-width"
                    value={contacto?.label}
                  />
                </div>
              ) : (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isLoading={isLoading}
                  isSearchable={true}
                  name="color"
                  options={responseContacts?.names}
                  styles={colourStyles}
                  onChange={(value) => {
                    setContacto(value.value);
                    setCorreo(value.email);
                  }}
                />
              )}
            </div>
          </div>
          <div className="quoteadd_flex__2">
            <div className="input_tag_addclient">
              <p className="labeltag">Departamento</p>
              <select
                name="departamento"
                value={dpto}
                onChange={(event) => get_mpos(event.target.value)}
              >
                <option value="">-</option>
                <Departments />
              </select>
            </div>
          </div>
          <div className="quoteadd_flex__2">
            <div className="input_tag_addclient">
              <p className="labeltag">Destino Contenedor</p>
              <input
                required
                type="text"
                className="input__info full-width"
                list="ciudades"
                name="ciudad"
                value={destino}
                onChange={(event) => setDestino(event.target.value)}
              />
              {pais === "Colombia" ? (
                <>
                  <datalist id="ciudades">
                          <option>Devolución</option>
                  </datalist>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="quoteadd_flex">
          <div className="quoteadd_flex__6">
            <div className="input_tag_addclient">
              <p className="labeltag">Email</p>
              <input
                type="text"
                className="input__info full-width"
                value={correo}
                onChange={(event) => setCorreo(event.target.value)}
              />
            </div>
          </div>
          <div className="quoteadd_flex__2">
            <div className="input_tag_addclient">
              <p className="labeltag">Ciudad/Municipio</p>
              <select
                name="municipio"
                value={mpo}
                onChange={(event) => setMpo(event.target.value)}
              >
                <option value="">-</option>
                {list_mpos?.map((municipio) => (
                  <option value={municipio}>{municipio}</option>
                ))}
              </select>
            </div>
          </div>
          {json_backwindow?.estado_sgto != "fin" && (
            <div className="flex_custom_select_pdf">
              <div className="input_tag_addclient">
                <div className="div_button_list">
                  <div className="button-text" onClick={() => flowSaveQuote()}>
                    <p style={{ fontSize: "18px" }}>{buttonState}</p>
                  </div>
                  <div className="button-arrow">
                    <select
                      onChange={(event) => setButtonState(event.target.value)}
                    >
                      <option value="GenerarPDF">GenerarPDF</option>
                      <option value="Guardar Borrador">Guardar Borrador</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="input_tag_addclient" style={{ marginBottom: "15px" }}>
          <p className="labeltag">Encabezado Cotizacion</p>
          <textarea
            value={encabezado}
            onChange={(event) => setEncabezado(event.target.value)}
            style={{ resize: "none" }}
          />
        </div>
        <table>
          <tr>
            <th className="thno">No</th>
            <th className="thitem">Item</th>
            <th className="thtipo">Tipo</th>
            <th className="thproducto">Producto</th>
            <th className="thdescripcion">Descripcion</th>
            <th className="thcant">Cant</th>
            <th className="thvlrUnit">Valor Unit</th>
            <th className="thvlrBruto">Valor Bruto</th>
            <th className="thdcto">%Dcto</th>
            <th className="thiva">%Iva</th>
            <th className="thvlrTotal">Valor Total</th>
            <th className="thdelete">
              <FontAwesomeIcon icon={faMinusCircle} size="xs" color="red" />
            </th>
          </tr>

          {inputList.map((row, index) => (
            <>
              <tr className="row_quotetable">
                <td>{row.no}</td>
                <td>
                  <select
                    className="select__table"
                    name="item"
                    value={row.item}
                    onChange={(event) => handleInput_item_tipo(event, index)}
                  >
                    <option value="">-</option>
                    <option value="Venta">Venta</option>
                    <option value="Alquiler">Alquiler</option>
                    <option value="Adaptacion">Adaptacion</option>
                    <option value="Servicio">Servicio</option>
                  </select>
                </td>
                <td>
                  <select
                    className="select__table"
                    name="tipo"
                    value={row.tipo}
                    onChange={(event) => handleInput_item_tipo(event, index)}
                  >
                    <option value="">-</option>

                    {responseQuoteInfo?.types.map((type) => (
                      <option value={type.nombre}>{type.nombre}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    className="select__table"
                    name="indexProducto"
                    value={row.indexProducto}
                    onChange={(event) =>
                      handleInput_ProductChange(event, index)
                    }
                  >
                    <option value="">-</option>

                    {filteredProducts[index]?.map((product, index_filter) => (
                      <option value={index_filter}>{product.nombre}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <textarea
                    type="text"
                    className="input__table"
                    name="descripcion"
                    value={row.descripcion}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="input__table"
                    name="cant"
                    value={row.cant}
                    onChange={(event) =>
                      handle_with_change_vlrbruto(event, index)
                    }
                  />
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      className="input__table"
                      name="valorUnit"
                      value={row.valorUnit}
                      onChange={(event) =>
                        handle_with_change_vlrbruto(event, index)
                      }
                      onBlur={() => setIsEditing(!isEditing)}
                    />
                  ) : (
                    <input
                      type="text"
                      className="input__table"
                      name="valorUnit"
                      value={toCurrency(row.valorUnit)}
                      onFocus={() => setIsEditing(!isEditing)}
                    />
                  )}
                </td>
                <td>
                  <p className="input__table">{toCurrency(row.valorBruto)}</p>
                </td>
                <td>
                  <input
                    type="text"
                    className="input__table"
                    name="dcto"
                    value={row.dcto}
                    onChange={(event) =>
                      handle_with_change_vlrbruto(event, index)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="input__table"
                    name="iva"
                    value={row.iva}
                    onChange={(event) =>
                      handle_with_change_vlrbruto(event, index)
                    }
                  />
                </td>
                <td>
                  <p className="input__table">{toCurrency(row.valorTotal)}</p>
                </td>
                <td>
                  <div onClick={() => deleteRow(index)} className="div_delete">
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="xs"
                      color="red"
                    />
                  </div>
                </td>
              </tr>
            </>
          ))}
        </table>
        <div style={{ width: "100%" }}>
          <button
            type="button"
            id="btn-add"
            onClick={() => addRow()}
            style={{ margin: "20px auto 0 0" }}
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </button>
        </div>

        <div className="subtotals">
          <div className="subtotal">
            <div className="label-subtotal">
              <p className="bold">Total Bruto</p>
            </div>
            <div className="mount-subtotal">
              <p className="bold">{toCurrency(superTotals.superBruto)}</p>
            </div>
          </div>
          <div className="subtotal">
            <div className="label-subtotal">
              <p className="bold">Descuentos</p>
            </div>
            <div className="mount-subtotal">
              <p className="bold">{toCurrency(superTotals.superDescuento)}</p>
            </div>
          </div>
          <div className="subtotal">
            <div className="label-subtotal">
              <p className="bold">Subtotal</p>
            </div>
            <div className="mount-subtotal">
              <p className="bold">{toCurrency(superTotals.superSubtotal)}</p>
            </div>
          </div>
          <div className="subtotal">
            <div className="label-subtotal">
              <p className="bold">IVA</p>
            </div>
            <div className="mount-subtotal">
              <p className="bold">{toCurrency(superTotals.superIva)}</p>
            </div>
          </div>
          <div className="subtotal">
            <div className="label-subtotal">
              <p className="bold">TOTAL</p>
            </div>
            <div className="mount-subtotal">
              <p className="bold">{toCurrency(superTotals.superTotal)}</p>
            </div>
          </div>
        </div>
        <div className="input_tag_addclient" style={{ marginBottom: "15px" }}>
          <p className="labeltag">Observaciones Comerciales</p>
          <textarea
            value={observaciones}
            onChange={(event) => setObservaciones(event.target.value)}
          />
        </div>
        {json_backwindow?.estado_sgto != "fin" && (
          <div className="wrapper-btn">
            <div className="div_button_list" style={{ margin: "0 0 0 auto" }}>
              <div className="button-text" onClick={() => flowSaveQuote()}>
                <p>{buttonState}</p>
              </div>
              <div className="button-arrow">
                <select
                  onChange={(event) => setButtonState(event.target.value)}
                >
                  <option value="GenerarPDF">GenerarPDF</option>
                  <option value="Guardar Borrador">Guardar Borrador</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </form>
      <Prompt
        when={shouldBlockNavigation}
        message={(location, action) => {
          if (action === "PUSH") {
          }
          return location.pathname.startsWith("/app") ? true : doSomething();
        }}
      />
    </div>
  );
}
export default QuoteAdd;
