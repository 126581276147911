import _ from "lodash";
import {mock_request_get_edit_contact, mock_request_get_contact} from "./mock_request"
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import { CountryDropdown } from "react-country-region-selector";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm } from "../../../App/components/shared/Modals";
import { useParams } from "react-router-dom";
import { Cities } from "../../../App/services/services";
import { styles_react_select_branches as styles_react_select } from "../../../App/components/shared/Select";
import Select from "react-select";

function Contacts() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [sortAscen, setSortAscen] = useState();
  const [cookies] = useCookies();

  const { editContactId } = useParams();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [optionsToShow, setOptionsToShow] = useState();
  const [jsonResponse, setJsonResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request();
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    if (editContactId) {
      url = `contacts/${editContactId}/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
      // var response = mock_request_get_edit_contact
      setJsonResponse(response.contact);
    } else {
      url = `suppliers/`;
      var response = await fetch_backend(url, "GET", token, dispatch);
        // var response = mock_request_get_contact
      setJsonResponse({country: "Colombia"});
    }
    buildOptions(response)
    setIsLoading(false);
  }

  const inputChange = (target) => {
    const jsonCopy = { ...jsonResponse };
    jsonCopy[target.name] = target.value;
    setJsonResponse(jsonCopy);
  };

  const buildOptions = (response) => {
    let options = {};
    let suppliers = []
    
    for (const supplier of response.suppliers) {
        suppliers.push({ value: supplier.id, label: supplier.name });
    }

    options.suppliers = suppliers
    setOptionsToShow(options)
} 


  const validateRequiredFields = (allValues) => {
    const errors = {};

    if (!allValues.contact.supplier_id) {
      errors.suppliertId = `Falta seleccionar el campo 'Razón Social'`;
    }
    
    if (!allValues.contact.name) {
      errors.name = `Falta seleccionar el campo 'Nombre'`;
    }
    if (!allValues.contact.last_name) {
      errors.last_name = `Falta seleccionar el campo 'Apellidos'`;
    }
    if (!allValues.contact.email) {
      errors.email = `Falta diligenciar el campo 'Email'`;
    }
    if (allValues.contact.phone && !allValues.contact.phone_indicator) {
      errors.phone_indicator_contact = `Falta diligenciar el campo 'Indicativo Teléfono (Contacto)'`;
  }
  if (!allValues.contact.phone && allValues.contact.phone_indicator) {
      errors.phone_contact = `Falta diligenciar el campo 'Teléfono (Contacto)'`;
  }
  if (!allValues.contact.cellphone && !allValues.contact.phone){
      errors.cellphone = `Falta diligenciar el campo 'Teléfono o Celular(Contacto)'`
  }
  if (!allValues.contact.country) {
    errors.country = `Falta seleccionar el campo 'Pais'`;
  }
  if (!allValues.contact.city) {
    errors.city = `Falta diligenciar el campo 'Ciudad'`;
  }

    return errors;
  };

  const saveContact = async () => {
    const body = {
      contact: jsonResponse,
    };
    console.log(body);
     const errors = validateRequiredFields(body)
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }

    let url;
    let method;

    if (editContactId) {
      url = `contacts/${editContactId}/`;
      method = "PUT";
    } else {
      url = `contacts/`;
      method = "POST";
    }

    const response = await fetch_backend(url, method, token, dispatch, body);

    setIsLoading(false);
    if (response.status === 200) {
      setSuccessModalForm(true);
      setMessageModalForm(["Proveedor creado con éxito"]);
      setOpenModalForm(true);
    }
  };

  const getReactSelectValue = (key, search_by) => {
    var option
    if(optionsToShow){
        option = optionsToShow[key].find(option => option.value == jsonResponse[search_by])
    }
    return option
  }


  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={() => {
          setOpenModalForm(false);
          if (successModalForm === true){
              history.goBack();
          }
        }}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />

      <div style={{ margin: "15px", width: "100%", maxWidth: "1300px" }}>
        <img
          src={require("../../../App/images/users-solid.svg")}
          alt=""
          className="img__factory__addclient"
          style={{ marginBottom: "10px" }}
        />
        <h4>Agregar Contacto</h4>

        
        <div
          className="simple_flex_"
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <div name="col1" style={{ flex: 0.3 }}>
          <div name="supplier" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Razón Social</p>
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione..."
                  isSearchable={true}
                  options={optionsToShow?.suppliers}
                  styles={styles_react_select}
                  onChange={(value) => inputChange({value: value.value, name: "supplier_id"})}
                  value={getReactSelectValue("suppliers", "supplier_id")}
                />
            </div>
          
           
            <div name="nombre" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Nombres</p>
              <input
                type="text"
                name="name"
                value={jsonResponse?.name}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
            <div name="last_name" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Apellidos</p>
              <input
                type="text"
                name="last_name"
                value={jsonResponse?.last_name}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
            <div name="postition" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Cargo</p>
              <input
                type="text"
                name="position"
                value={jsonResponse?.position}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
            <div name="email" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Email</p>
              <input
                type="text"
                name="email"
                value={jsonResponse?.email}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
            <div name="phone" className="simple_flex_" style={{justifyContent:"space-between", marginBottom: "10px"}} >
                    <div style={{flex: 0.15}}>
                        <p className="labeltag">Indic.</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone_indicator"
                                value={jsonResponse?.phone_indicator}
                                onChange={(e) => inputChange(e.target)}
                            />
                    </div>
                    <div style={{flex: 0.60}}>
                        <p className="labeltag">Teléfono</p>
                        <input
                                className="my_input"
                                type="number"
                                name="phone"
                                value={jsonResponse?.phone}
                                onChange={(e) => inputChange(e.target)}
                            />
                    </div>
                    <div style={{flex: 0.2}}>
                        <p className="labeltag">Extensión</p>
                        <input
                                className="my_input"
                                type="number"
                                name="extension"
                                value={jsonResponse?.extension}
                                onChange={(e) => inputChange(e.target)}
                            />
                    </div>
                </div>
            <div name="cellphone" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Celular</p>
              <input
                type="text"
                name="cellphone"
                value={jsonResponse?.cellphone}
                onChange={(e) => inputChange(e.target)}
              />
            </div>
          </div>
          <div name="col2" style={{ flex: 0.3 }}>
            <div name="gender" style={{marginBottom: "10px"}}>
                <p className="labeltag">Género</p>
                <select
                        type="text"
                        name="gender"
                        class="countryDropdown"
                        value={jsonResponse?.gender}
                        onChange={(e) => inputChange(e.target)}
                    >
                        <option value={null}></option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                        <option value="LGTB">LGTBI</option>
                    </select>
                </div>
          <div name="country" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Pais</p>
              <CountryDropdown
                value={jsonResponse?.country}
                onChange={(value) =>
                  inputChange({ name: "country", value: value })
                }
                classes="countryDropdown"
                defaultOptionLabel="Seleccione..."
              />
            </div>
            <div name="city" style={{ marginBottom: "10px" }}>
              <p className="labeltag">Ciudad</p>
              <input
                type="text"
                name="city"
                list="ciudades"
                value={jsonResponse?.city}
                onChange={(e) => inputChange(e.target)}
              />
              {jsonResponse?.country === "Colombia" ? (
                <Cities id="ciudades" />
              ) : null}
            </div>
            
          </div>
          <div name="col3" style={{ flex: 0.3 }}>
            
            
          </div>
        </div>

        <button
          style={{
            padding: "3px 15px",
            margin: 0,
          }}
          className="button"
          onClick={() => saveContact()}
        >
          Guardar
        </button>
      </div>
    </>
  );
}

export { Contacts };
