import _ from "lodash";
import "./orders_manager.css";
import { response_get_mock, response_mock_group_management } from "./responses_mock";
import ModalProducts from "../Stock/Modal_products";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";
import TableHeader from "../../../App/components/shared/TableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../App/components/Loading/Loading";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm, ModalQuestion } from "../../../App/components/shared/Modals";
import {
    styles_react_select,
  } from "../../../App/components/shared/Select";
import {
    faCheckCircle,
faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";

import ModalAddSupplier from "./add_supplier_modal"
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faSolidCircle } from "@fortawesome/free-solid-svg-icons";
import { FormatDotThousands, ParseFormattedNumber } from "../../../App/services/services";

function GroupManagement() {
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const [sortAscen, setSortAscen] = useState(true);
    const [clicked_column, setClicked_column] = useState("");
    const [{ actual_url }, dispatch] = useDataLayerValue();
    const [json_response, setJson_response] = useState();
    const [isLoading, setIsLoading] = useState();
    const [openModalAddSupplier, setOpenModalAddSupplier] = useState(false);
    const [opeDeleteConfirmation, setOpeDeleteConfirmation] = useState(false);
    const [deleteSuplId, setDeleteSuplId] = useState();
    const { groupId, groupName } = useLocation().state;
    const [changePrice, setChangePrice] = useState()
    const [openModalForm, setOpenModalForm] = useState(false);
    const [successModalForm, setSuccessModalForm] = useState(false);
    const [messageModalForm, setMessageModalForm] = useState();
    const [cookies] = useCookies();
    const token = cookies.token_purchase;

    useEffect(() => {
        dispatch({
            type: "SET_URL",
            actual_url: path,
        });
        send_init_request();
        }, []);

    useEffect(() => {
        const pushData = async () =>{
            const payload = {
                group_id: groupId,
                code: changePrice?.row.code,
                supplier_id: changePrice?.supplierId,
                price: changePrice?.newPrice,
            }
            const response = await fetch_backend(
                "order_manager_inner/",
                "POST",
                token,
                dispatch,
                payload
            );
            if (response.status !== 200){
                send_init_request()
            }
        }
        const delayDebounceFn = setTimeout(() => {
            if (changePrice){
                pushData()
            }
        }, 1000)
    
        return () => clearTimeout(delayDebounceFn)
        }, [changePrice])
    

    async function send_init_request() {
        setIsLoading(true);
        var url;
        url = `order_manager/${groupId}/`;
        var response = await fetch_backend(url, "GET", token, dispatch);
        // var response = response_mock_group_management
        setJson_response(response);
        setIsLoading(false);
        }

    function isNumeric(x) {
        let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
        return { isNum: !isNaN(value), value };
        }

    const sortFunction = (sub) => {
        setSortAscen(!sortAscen);
        const listTasks = [...json_response.items];
        // Filter the nulls in an array and the rest in another
        let nulls = listTasks.filter((item) => item[sub] == null);
        let empty_str = listTasks.filter((item) => item[sub] == "");
        let toSort = listTasks.filter((item) => item[sub]);
        // Sort the non-null values
        let sorted = toSort.sort((a, b) => {
            // Check if both values are numeric
            let aa = isNumeric(a[sub]);
            let bb = isNumeric(b[sub]);
    
            // If numerics
            if (aa.isNum && bb.isNum && sub !== "expected_date" && sub !== "date") {
            return aa.value - bb.value;
            }
    
            // If strings
            return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
        });
        sorted = sorted.concat(nulls);
        sorted = sorted.concat(empty_str);
        if (!sortAscen) {
            sorted.reverse();
        }
        setJson_response({ ...json_response, items: sorted });
        };
    
    const click_column = (col_name) => {
    setClicked_column(col_name);
    };

    const pushCheck = async (indexItem, priceDataIndex) => {

        console.log(priceDataIndex)
        
        const payload = {
            group_id: groupId,
            code: json_response?.items[indexItem].code,
            supplier_id: json_response?.items[indexItem].prices[priceDataIndex].supplier_id,
            checked: json_response?.items[indexItem].prices[priceDataIndex].checked,
        }
        const response = await fetch_backend(
            "order_manager_inner/",
            "POST",
            token,
            dispatch,
            payload
        );
        if (response.status !== 200){
            send_init_request()
        }
    }


    const pushTenderCheck = async (indexItem) => {
        
        const payload = {
            group_id: groupId,
            code: json_response?.items[indexItem].code,
            checked_tender: json_response?.items[indexItem].tender.checked,
        }
        const response = await fetch_backend(
            "order_manager_inner/",
            "POST",
            token,
            dispatch,
            payload
        );
        if (response.status !== 200){
            send_init_request()
        }
    }


    const clickTenderPrice = (indexItem) => {
        const jsonCopy = {...json_response}
        if (jsonCopy.items[indexItem].tender?.price){
            let newCheckedValue = !jsonCopy.items[indexItem].tender?.checked
            jsonCopy.items[indexItem].tender.checked = newCheckedValue

            if (newCheckedValue){
                jsonCopy.items[indexItem].prices.forEach((priceData, index) => {
                    priceData.checked = false
                })
            }
        }

        setJson_response(jsonCopy)
        pushTenderCheck(indexItem)

    }

    const clickCheck = (indexItem, priceDataIndex) => {
        const jsonCopy = {...json_response}

        jsonCopy.items[indexItem].prices.forEach((priceData, index) => {
            if (index === priceDataIndex){
                if (priceData.price === "" || priceData.price === null){
                    setMessageModalForm("La referencia debe tener un precio")
                    setSuccessModalForm(false)
                    setOpenModalForm(true)
                    return
                }
                priceData.checked = !priceData.checked
            }else{
                priceData.checked = false
            }
        })

        let newCheckValue = jsonCopy.items[indexItem].prices[priceDataIndex].checked

        if (jsonCopy.items[indexItem].tender && newCheckValue){
            jsonCopy.items[indexItem].tender.checked = false
        }

        setJson_response(jsonCopy)
        pushCheck(indexItem, priceDataIndex)
    }

    const changeSupplierPrice= (indexItem, priceDataIndex, value) => {
        const jsonCopy = {...json_response}
        jsonCopy.items[indexItem].prices[priceDataIndex].price = value
        setJson_response(jsonCopy)
    }

    const addSupplierCol = () => {
        setOpenModalAddSupplier(true)
    }

    const onCloseSuccessModalAddSupplier = (newSupplier) => {
        const copyJson = {...json_response}
        copyJson.suppliers.push(newSupplier)
        setJson_response(copyJson)
        send_init_request()
        setOpenModalAddSupplier(false)
    }

    const removeSuplCol = async (value) => {
        const answer = value.value === "true";
        if (answer){
            setIsLoading(true);
            const response = await fetch_backend(
                `order_manager/`,
                "DELETE",
                token,
                dispatch,
                {group_id: groupId, supplier_id: deleteSuplId}
            );
            if (response.status === 200){
                const copyJson = {...json_response}
                const indexToDelete = copyJson?.suppliers?.findIndex(obj => obj.id === deleteSuplId);
                if (indexToDelete !== -1) {
                    copyJson.suppliers.splice(indexToDelete, 1);
                  }
                setJson_response(copyJson)
            }
        }
        setOpeDeleteConfirmation(false)
        send_init_request()
        setIsLoading(false);
    }

    const approveReferences = async () => {
        setIsLoading(true)
        var referencesForApprove = false
        for (const item of json_response?.items){
            for(const price of item.prices ){
                if (price.checked){
                    referencesForApprove = true
                    break
                }
            }
            if (referencesForApprove){
                break
            }
        }

        if (!referencesForApprove){
            setMessageModalForm("No hay referencias por aprobar")
            setSuccessModalForm(false)
            setOpenModalForm(true)
            setIsLoading(false)
            return
        }

        var response = await fetch_backend("approve_reference/", "POST", token, dispatch, {group_id: groupId});
        setIsLoading(false)

        if (response.status === 200){
            setSuccessModalForm(true);
            setMessageModalForm([
                "Operación Exitosa",
            ]);
            setOpenModalForm(true);
        }
    }

    const downloadQuoteRequirement = async () => {
        setIsLoading(true)
        var response = await fetch_backend("order_manager_file", "POST", token, dispatch, {group_id: groupId});
        setIsLoading(false)
        // response = {status: 200, file_url: "https://prod-turinng.s3.us-west-2.amazonaws.com/Formato+GIRO+2023.xlsx"}
        if (response.status === 200){
            console.log(response)
            // const link = document.createElement("a");
            // link.href = response.file_url;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        }
    }

    const closeModalForm = () => {
        setOpenModalForm(false);
        if (successModalForm){
            window.location.reload()
        }
    }

    const onChangePriceInput = (event, priceData, row, indexItem, priceDataIndex) => {
        
        const allowedChars = /^[0-9.,]+$/;
        var { name, value } = event.target;
        
        if (value === ""){
            setChangePrice({newPrice: "", supplierId: priceData.supplier_id, row: row}); 
            changeSupplierPrice(indexItem, priceDataIndex, "")
        }
    
        if (!value.match(allowedChars) || value === "") {
        return;
        }

        if (value.slice(-1) === ","){
            return
        }
        
        let normalizedInput = value.replace(/\./g, "").replace(/,/g, ".")
        setChangePrice({newPrice: ParseFormattedNumber(event.target.value), supplierId: priceData.supplier_id, row: row}); 
        changeSupplierPrice(indexItem, priceDataIndex, FormatDotThousands(normalizedInput))
    }

  return (
    <div className="main_container_group_management">
      <Loading isLoading={isLoading} />
      {openModalAddSupplier &&
      <ModalAddSupplier 
        open={openModalAddSupplier}
        onClose={()=>setOpenModalAddSupplier(false)}
        onCloseSuccess={onCloseSuccessModalAddSupplier}
        existingSuppliers={json_response?.suppliers}
        groupId={groupId}
      />
    }
    <ModalQuestion
        open={opeDeleteConfirmation}
        onClose={() => setOpeDeleteConfirmation(false)}
        question="¿Está seguro que desea eliminar este proveedor?"
        callback={removeSuplCol}
      />

    <ModalForm
        open={openModalForm}
        onClose={() => closeModalForm()}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          marginTop: "-20px",
          height: "125px",
        }}
      >
        <h3 style={{ paddingTop: "20px" }}>Agrupación: {groupName}</h3>
      </div>

      <div className="task-content" style={{minWidth: "100%"}}>
        <table style={{minWidth: "100%", tableLayout: "fixed"}}>
          <tr className="rowHeaders">
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Código"
              txt_filter="code"
              min_width="100px"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Descripción"
              txt_filter="description"
              min_width="300px"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Cant."
              txt_filter="quantity"
              min_width="70px"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Valor Referencia"
              txt_filter="reference_value"
              min_width="150px"
            />
            <TableHeader
              col_name="Licitación"
              min_width="100px"
            />
            {json_response?.suppliers?.map((supplier, index) => (
            <TableHeader
                col_name={
                    <div className="simple_flex_" style={{justifyContent: "space-evenly"}}>
                        <p className="col_white" style={{marginRight: "10px"}}>{supplier.name}</p>
                        <FontAwesomeIcon
                            icon={faMinusSquare}
                            color={"red"}
                            className="iconRow"
                            onClick={()=>{setDeleteSuplId(supplier.id); setOpeDeleteConfirmation(true)}}
                  />
                    </div>
                }
                min_width="150px"
            />
          ))}
            <TableHeader
                col_name={<div
                    className="simple_square_plus"
                    style={{margin: "auto"}}
                    onClick={() => addSupplierCol()}
                >
                <h4 className="col_white">+</h4>
                </div>}
                min_width="20px"
            />
            
          </tr>
          {json_response?.items?.map((row, indexItem) => (
            <>
              <tr className="rowTasks">
                <td>{row.code}</td>
                <td>{row.description}</td>
                <td>{row.quantity}</td>
                <td>{row.reference_value? FormatDotThousands(row.reference_value): 0}</td>
                <td>{row.tender?.price ?
                <>
                    <div className="simple_flex_" style={{justifyContent:"space-evenly"}} onClick={()=>clickTenderPrice(indexItem)}>
                        <p>{row.tender.price}</p>
                        {row.tender?.checked ? 
                        <FontAwesomeIcon
                                icon={faCheckCircle}
                                color={"green"}
                                className="iconRow"
                        />:<FontAwesomeIcon
                        icon={faCircle}
                        color={"#b1b1b1"}
                        className="iconRow"
                        />
                    }
                    </div>
                </>
                :
                <>
                    <p>-</p>
                </>}

                </td>
                {row?.prices?.map((priceData, priceDataIndex) => (
                    <td>
                        <div className="simple_flex_" style={{justifyContent:"space-evenly"}}>
                        <input
                            type="text"
                            name="quantity"
                            className={`my_input input_center`}
                            disabled={row.tender?.checked ? true: false}
                            style={{maxWidth: "80%"}}
                            value={priceData.price? FormatDotThousands(priceData.price.replace(/\./g, "").replace(/,/g, ".")) : ""}
                            onChange={(event) => onChangePriceInput(event, priceData, row, indexItem, priceDataIndex)}

                            />

                        <div onClick={()=>{
                            if(row.tender?.checked){
                                return
                            }
                            clickCheck(indexItem, priceDataIndex)}
                            }>
                        {priceData.checked ?
                            <FontAwesomeIcon
                            icon={faCheckCircle}
                            color={"green"}
                            className="iconRow"
                        />: <FontAwesomeIcon
                        icon={row.tender?.checked ? faSolidCircle: faCircle}
                        color={"#f2f2f2"}
                        className="iconRow"
                        />}
                        </div>
                        </div>
                    </td>
                ))}
                <td></td>
              </tr>
            </>
          ))}
        </table>
        
      </div>
      <button
        style={{
            padding: "3px 15px",
            margin: "30px 0 0 auto",
        }}
        className="button"
        onClick={() => approveReferences()}
        >
        Aprobar Referencias
      </button>
      <button
        style={{
            padding: "3px 15px",
            margin: "30px 0 0 auto",
        }}
        className="button"
        onClick={() => downloadQuoteRequirement()}
        >
        Generar Solicitud de Cotización
      </button>
    </div>
  )
}

export {GroupManagement}