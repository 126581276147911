import "./suppliers_orders.css";
import { response_get_mock } from "./responses_mock";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useNa } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import {
  faSearch,
  faInfoCircle,
  faTrash,
  faExclamationTriangle,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Loading from "../../../App/components/Loading/Loading";
import TableHeader from "../../../App/components/shared/TableHeader";
import fetch_backend from "../../../App/components/shared/Fetch";
import { ModalForm, ModalQuestion } from "../../../App/components/shared/Modals";

function Suppliers_orders() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const history = useHistory();
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [statusHandler, setStatusHandler] = useState();
  const [sortAscen, setSortAscen] = useState(true);
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [filterComboValue, setFilterComboValue] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState();
  const [modalQuestionData, setModalQuestionData] = useState()
  const token = cookies.token_purchase;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    send_init_request()
    // setJson_response(response_get_mock);
    // setBackupJson_response(response_get_mock);

    fillStatusArray(response_get_mock);
  }, []);

  async function send_init_request() {
    setIsLoading(true);
    var url;
    url = `purchase-orders/`;
    var response = await fetch_backend(url, "GET", token, dispatch);
    setJson_response(response);
    setBackupJson_response(response);
    fillStatusArray(response);
    setIsLoading(false);
  }

  const fillStatusArray = (response) => {
    const issues = [];
    for (let i = 0; i < response?.purchase_orders?.length; i++) {
      issues.push(false);
    }
    const status = {
      issues: issues,
    };

    setStatusHandler(status);
  };

  const resetIssuesOpenArray = (pruchaseOrders) => {
    const issues = [];
    for (let i = 0; i < pruchaseOrders.length; i++) {
      issues.push(false);
    }
    setStatusHandler({...statusHandler, issues: issues})
  }

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.purchase_orders];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);
    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum && sub !== "expected_date" && sub !== "date") {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });
    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    if (!sortAscen) {
      sorted.reverse();
    }
    setJson_response({ ...json_response, purchase_orders: sorted });
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
    fillStatusArray(backupJson_response);
  };

  const handleSearch = (value) => {
    let newList = [...backupJson_response.purchase_orders];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.user.toLowerCase().includes(value.toLowerCase()) ||
          row.supplier.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response({ ...json_response, purchase_orders: newList });
      resetIssuesOpenArray(newList)
    } else {
      setJson_response(backupJson_response);
      resetIssuesOpenArray(backupJson_response.purchase_orders)
    }
  };

  const onFocusSearch = () => {
    setBackupJson_response(backupJson_response);
  };

  const comboFilter = (e) => {
    const value = e.target.value;
    if (filterComboValue === value) {
      setFilterComboValue("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterComboValue(value);
    var newList = [...backupJson_response.purchase_orders];
    newList = newList.filter((row) => row.status == value);
    setJson_response({ ...json_response, purchase_orders: newList });
    resetIssuesOpenArray(newList)
  };

  const issuesClick = (row, index) => {
    console.log(index);
    if (row.issues.length === 0) {
      return;
    }

    const issues = statusHandler.issues;
    issues[index] = !issues[index];
    setStatusHandler({ ...statusHandler, issues: issues });
  };

  const callbackModalQuestion = (value) => {
    const answer = value.value === "true";
    const questionData = value.params
    setOpenDeleteConfirmation(false)
    if (answer){
        if (questionData?.questionType === "issue"){
          removeIssue(questionData?.issueId)
        } else if (questionData?.questionType === "closePO"){
          closePurchaseOrder(questionData?.poId)
        }
    }
    
}

  const removeIssue = async (issueId) => {    
    setIsLoading(true)
    var response = await fetch_backend(`remove-issue/${issueId}`, "DELETE", token, dispatch);
    setIsLoading(false)

    if (response.status === 200){
        setSuccessModalForm(true);
        setMessageModalForm([
            "Operación Exitosa",
        ]);
        setOpenModalForm(true);
    }
}

const closePurchaseOrder = async (poId) => {    
  setIsLoading(true)
  var response = await fetch_backend(`close-purchase-order/${poId}`, "DELETE", token, dispatch);
  setIsLoading(false)

  if (response.status === 200){
      setSuccessModalForm(true);
      setMessageModalForm([
          "Operación Exitosa",
      ]);
      setOpenModalForm(true); 
  }
}

const manageIssue = (issueId) => {
  
}

  return (
    <div className="wrapped__container_top_bar">
      <Loading isLoading={isLoading} />
      <ModalQuestion
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        question={modalQuestionData?.question}
        callback={modalQuestionData?.callback}
        params={modalQuestionData}
      />

    <ModalForm
        open={openModalForm}
        onClose={() => {
          setOpenModalForm(false);
          if (successModalForm) {
            window.location.reload();
        }}}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <div className="top-bar">
        <h3>Ordenes de Compra</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={() => onFocusSearch()}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides_tools">
          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="unmanaged"
              className="inputselect unmanaged"
              checked={filterComboValue === "unmanaged"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />
            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              Borrador
            </p>
          </div>

          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="in_process"
              className="inputselect in_process"
              checked={filterComboValue === "in_process"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />

            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              &nbsp;En Proceso
            </p>
          </div>

          <div className="sub_guide_tools">
            <input
              type="Radio"
              name="filtro_alquilados"
              value="managed"
              className="inputselect purchase_managed"
              checked={filterComboValue === "managed"}
              onClick={(e) => {
                comboFilter(e);
              }}
            />

            <p
              style={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "108px",
              }}
            >
              &nbsp;Gestionado
            </p>
          </div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th style={{ width: "3%" }} />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Proveedor"
              txt_filter="supplier"
              width="10%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Gestor"
              txt_filter="user"
              width="15%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Orden de Compra"
              txt_filter="purchase_order_id"
              width="15%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha Est. Llegada"
              txt_filter="expected_date"
              width="13%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha O.C"
              txt_filter="date"
              width="15%"
            />
            <TableHeader col_name="Novedades" width="8%" />
            <TableHeader col_name="Detalle" width="8%" />
            <TableHeader col_name="Cerrar" width="8%" />
          </tr>
          {json_response?.purchase_orders?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{
                        backgroundColor: `${
                          row.status === "unmanaged"
                            ? "red"
                            : row.status === "managed"
                            ? "green"
                            : "blue"
                        }`,
                      }}
                    />
                  </div>
                </td>
                <td>{row.supplier}</td>
                <td>{row.user}</td>
                <td>{row.purchase_order_id}</td>
                <td>{row.expected_date}</td>
                <td>{row.date}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color={row.issues.length === 0 ? "#e6e6e6" : "#cd8202"}
                    className="iconRow"
                    onClick={() => issuesClick(row, index)}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="iconRow"
                    onClick={() => {
                      history.push(`${path}/${row.id}`, {
                          poId: row.id,
                        });
                    }}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="iconRow"
                    color={row.status !== "managed" ? "#e6e6e6" : "green"}
                    onClick={() => {
                      if (row.status === "managed"){ 
                        setModalQuestionData({
                          question: "Está seguro que desea cerrar esta O.C ?",
                          questionType: "closePO",
                          callback: callbackModalQuestion,
                          poId: row.id,
                        })
                        setOpenDeleteConfirmation(true)
                    }
                    }}
                  />
                </td>
              </tr>
              {statusHandler?.issues[index] === true ? (
                <tr className="rowTasks" style={{ backgroundColor: "#E9E9E9" }}>
                  <td colSpan="9">
                    <div>
                    <table className="inside_po_issues_table">
                        <tr >
                          <th style={{ width: "10%", border: "none" }}>Codigo</th>
                          <th style={{ width: "20%" }}>Descripción</th>
                          <th style={{ width: "20%" }}>Novedad</th>
                          <th style={{ width: "10%" }}>Cant. Novedad</th>
                          <th style={{ width: "5%" }}></th>
                          <th style={{ width: "5%" }}></th>
                        </tr>
                        {row?.issues?.map((issue) => (
                          <>
                            <tr>
                              <td>{issue.product_code}</td>
                              <td style={{ textAlign: "left" }}>
                                {issue.description}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {issue.issue}
                              </td>
                              <td>{issue.quantity}</td>
                              <td>
                                <div
                                  className="button_appereance"
                                  style={{
                                    padding: "2px 2px",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => manageIssue(issue.id)}
                                >
                                  Gestionar
                                </div>
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  className="iconRow"
                                  color="red"
                                  onClick={() => {
                                    setModalQuestionData({
                                      question: "Está seguro que desea eliminar esta novedad ?",
                                      questionType: "issue",
                                      callback: callbackModalQuestion,
                                      issueId: issue.id
                                    })
                                    setOpenDeleteConfirmation(true)
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </table>
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export default Suppliers_orders;
