import React, { useState, useEffect } from "react";
import { mockReferences } from "./mockRequest";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
import {
  styles_react_select,
} from "../../../App/components/shared/Select";
import { useCookies } from "react-cookie";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import {
  ModalForm,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

function ModalCloseOp({ open, callback, onClose, params = "" }) {
  const [cookies] = useCookies();
  const token = cookies.token_purchase;
  const [{ actual_url }, dispatch] = useDataLayerValue();

const extractDifferences = () => {
  const differences = []
  for (const ref of params?.references){
    const diff = ref.missing_quantity - ref.received_quantity
    if (diff > 0){
      differences.push({
        product_code: ref.product_code,
        description: ref.description,
        differentece: diff
      })
    }
  }
  return differences

}

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
    >
      
      <div style={{padding: "20px"}}>
          <h4 style={{textAlign: "center"}}>Cerrar O.C</h4>
      <br />
      {params.closeType === "complete" &&
      <div>
        <p style={{textAlign: "center"}}>Se han ingresado el 100% de las cantidades de la OC.</p>
        <p style={{textAlign: "center"}}>Desea cerrar la ordén de compra?</p>
      </div>
      }

      {params.closeType === "incomplete" &&
      <div className="simple_flex_" style={{justifyContent: "center"}}>
        <div>
        <p style={{textAlign: "center"}}>Aún faltan los siguientes productos por ingresar:</p>
        <table style={{width: "90%", margin: "25px 0"}}>
          <tr style={{height: "40px"}}>
            <th style={{border: "none", width: "70%"}}>Descripción</th>
            <th style={{border: "none", width: "30%"}}>Cantidad</th>
          </tr>
        {extractDifferences().map((ref, index) => (
          <>
          <tr>
            <td style={{border: "none", padding: "0 15px"}}>{ref.description}</td>
            <td style={{border: "none"}}>{ref.differentece}</td>
          </tr>
          </>
        ))}
        </table>
        <p style={{textAlign: "center"}}>Está seguro que desea cerrar la OC?</p>
        </div>
    </div>
      }
  </div>
      <DialogActions>
        <button
          type="button"
          className="button_delete"
          value="save"
          name="aceptar"
          onClick={(e) => {onClose();callback("save")}}
          style={{ padding: "5px 15px", fontSize: "16px" }}
        >
          Guardar sin Cerrar
        </button>
        <button
          type="button"
          name="cancelar"
          value="save&close"
          style={{ padding: "5px 15px", fontSize: "16px" }}
          onClick={(e) => {onClose();callback("save&close")}}
        >
          Guardar y Cerrar
        </button>
        
      </DialogActions>
    </Dialog>
  );
}

export default ModalCloseOp;
